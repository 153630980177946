import { InfiniteOptions } from "@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions";
import { SearchInputProps } from "@cfra-nextgen-frontend/shared/src/components/SearchInput/types";
import { CompanyTypeSearch } from "@cfra-nextgen-frontend/shared/src/components/TypeSearch/CompanyTypeSearch";
import { getOptionsBlankContainer } from "@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer";
import { AutoSuggestCompany, Hit, SearchResult } from "@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch";
import { GetOptionsBaseProps } from "@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch";
import { fontFamilies } from "@cfra-nextgen-frontend/shared/src/utils";
import { TickerMatcherData } from "@cfra-nextgen-frontend/shared/src/utils/company";
import { Box, styled, Typography } from "@mui/material";
import { getSuggesterInfiniteRequestParamsConfig, getSuggesterInfiniteOptionsProps } from "features/home/components/InfiniteOptions/suggester";

export type CompanySearchCellProps = {
    onClickOutsideCallback?: () => void;
    SearchInputComponent: React.FC<SearchInputProps> | undefined;
    searchInputComponentProps?: SearchInputProps;
    clearInputOnSelection?: boolean | undefined;
    onResultOptionClick?: any;
    onDefaultOptionClick?: any;
    rowIndex?: string;
    showDefaultResultOptions?: any;
    data?: any;
    hideClearButton?: boolean;
}

const StyledTypography = styled(Typography)(() => ({
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13.5px',
    fontWeight: 400,
    lineHeight: '33px',
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 0,
    marginBottom: 0
}));

export const CompanySearchCell = (props: CompanySearchCellProps) => {

    return (
        <Box>
            <CompanyTypeSearch
                clearInputOnSelection={props.clearInputOnSelection}
                showSearchInput={true}
                SearchInputComponent={props?.SearchInputComponent}
                searchInputComponentProps={props?.searchInputComponentProps}
                onClickOutsideCallback={() => props.onClickOutsideCallback?.()}
                getResultOptions={({ inputValue, resetOptionsRef, onLinkClickCallback }: GetOptionsBaseProps) => (
                    <InfiniteOptions<SearchResult<AutoSuggestCompany>, Hit<AutoSuggestCompany>>
                        OptionsContainer={getOptionsBlankContainer()} 
                        scrollThresholdPx={Math.round((48 * 25) / 2)} // take 50% of fetching size, as each row height is 48px and 25 records being fetched
                        ref={resetOptionsRef}
                        infiniteRequestParams={[
                            {
                                q: inputValue,
                                path: 'company/suggester',
                                config: {
                                    enabled: Boolean(inputValue),
                                },
                                size: 25
                            },
                            {
                                queryKeyFirstElement: 'companySuggesterQuery',
                                ...getSuggesterInfiniteRequestParamsConfig<AutoSuggestCompany>(25),
                            },
                        ]}
                        outerSetOptionsCondition={Boolean(inputValue)}
                        RowComponent={({ rowData }) => (
                            <Box display={'flex'} width={'100%'} sx={{
                                paddingX: '5px',
                                cursor: 'pointer',
                                ':hover': {
                                    backgroundColor: '#F3F5FC'
                                }
                            }} onClick={() => {
                                onLinkClickCallback?.();
                                props.onResultOptionClick?.(props.rowIndex, rowData);
                            } }>
                                <StyledTypography>
                                    {`${rowData._source['company_security.company.company_name']} ${rowData._source['company_security.security_trading.ticker_symbol']} ${rowData._source['company_security.security_trading.exchange_lid.exchange_code']
                                        ? '(' + rowData._source['company_security.security_trading.exchange_lid.exchange_code'] + ')' : ''}`}
                                </StyledTypography>
                            </Box>
                        )}
                        rowsKeyPrefix={inputValue}
                        noResultsFoundBoxSxProps={{ paddingLeft: '8px' }}
                        {...getSuggesterInfiniteOptionsProps<AutoSuggestCompany>()}                    />)
                }
                getDefaultResultOptions={props.showDefaultResultOptions ? ({ onLinkClickCallback }) => (
                    (props.data as TickerMatcherData).result?.map((res) => (
                        <Box key={res.data.key} display={'flex'} width={'100%'} sx={{
                            paddingX: '5px',
                            cursor: 'pointer',
                            ':hover': {
                                backgroundColor: '#F3F5FC'
                            }
                        }} onClick={() => {
                            onLinkClickCallback?.();
                            props.onDefaultOptionClick?.(props.rowIndex, res);
                        }}>
                            <StyledTypography>{res.data.displayValue}</StyledTypography>
                        </Box>
                    ))
                ): undefined}
            />

        </Box>
    )
}