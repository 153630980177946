import { MaterialIcons } from "@cfra-nextgen-frontend/shared";
import { CFRAChips, chipItem } from "@cfra-nextgen-frontend/shared/src/components/CFRAChip/CFRAChipStack";
import { ScreenerChipTheme } from "@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle";
import { AutoSuggestCompany, Hit } from "@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch";
import { ThemeProvider } from "@mui/material";

export const CompanyChipList = (props: { chipItems: Hit<AutoSuggestCompany>[]; onChipDeleteClick: (chipItems: Hit<AutoSuggestCompany>[], key: string) => () => void }) => {

    const { chipItems, onChipDeleteClick } = props;

    if (chipItems?.length === 0) {
        return null;
    }

    const chipList: Array<chipItem> = chipItems?.map((chip) => {
        const { _source: { "company_security.security_trading.cfra_security_trading_id": securityTradingId, 
                            "company_security.company.cfra_company_id": companyId,
                            "company_security.security_trading.ticker_symbol": ticker
        } } = chip || {};

        let chipLabel = `${ticker}`;

        return {
            chipKey: securityTradingId || companyId,
            chipLabel,
            chipProps: {
                title: chipLabel,
                deleteIcon: <MaterialIcons.Clear style={{ fontSize: '13px', color: '#0B2958' }} />,
                onDelete: onChipDeleteClick(chipItems, securityTradingId || companyId),
                onClick: () => { },
            }
        };
    });

    return (
        <ThemeProvider key='ChipsTheme' theme={ScreenerChipTheme}>
            <CFRAChips key='allAddSymbolChips' chipItems={chipList} />
        </ThemeProvider>
    );
};