import SortIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/sort.svg';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/GridThemeV2.scss';
import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import {
    TypographyStyle15,
    TypographyStyle2,
    TypographyStyle3,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, createTheme, SxProps, ThemeProvider } from '@mui/material';

type SearchResultsHeaderProps = {
    title: string;
    subTitle?: string;
    containerStyles?: SxProps;
    slot1Icon?: boolean;
    slot1?: JSX.Element;
    slot1Divider?:boolean;
    slot2?: JSX.Element;
    slot2Divider?:boolean;
    slot3?: JSX.Element;
};

const Divider = <DividerStyle1 sx={{ height: '21px', marginLeft: '12px', marginRight: '7px' }} />;

export function CardHeaderVariant1(props: SearchResultsHeaderProps) {
    const { title, subTitle, containerStyles, slot1Icon, slot1, slot1Divider=true, slot2, slot2Divider=true, slot3 } = props;

    return (
        <ThemeProvider theme={createTheme()}>
            <Box
                key='searchResultsHeader'
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '46px',
                    boxSizing: 'content-box',
                    alignItems: 'center',
                    paddingBottom: '8px',
                    justifyContent: 'space-between',
                    ...containerStyles,
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap='8px'>
                    <TypographyStyle2>{title}</TypographyStyle2>
                    {subTitle && (
                        <>
                            <DividerStyle1 />
                            <TypographyStyle3>{subTitle}</TypographyStyle3>
                        </>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {slot1Icon && (
                        <img
                            src={SortIcon}
                            alt='Sort Icon'
                            style={{ width: '16px', height: '16px', marginRight: '13px' }}
                        />
                    )}
                    {slot1 && (
                        <>
                            <Box sx={{ margin: '2px 0px 4px 6px' }}>{slot1}</Box>
                            {slot1Divider && Divider}
                        </>
                    )}
                    {slot2 && (
                        <>
                            <TypographyStyle15 sx={{ letterSpacing: 'normal' }}>Sort:</TypographyStyle15>
                            <Box sx={{ marginTop: '5px', marginLeft: '5px' }}>{slot2}</Box>
                            {slot2Divider && Divider}
                        </>
                    )}
                    {slot3}
                </Box>
            </Box>
        </ThemeProvider>
    );
}
