import { Fallback } from '@cfra-nextgen-frontend/shared';
import { ErrorBoundary } from '@cfra-nextgen-frontend/shared/src/components/Fallbacks/ErrorBoundary';
import { ProjectSpecificResourcesContextProvider } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { getAwsAmplifyLocalStorageKey } from '@cfra-nextgen-frontend/shared/src/utils/auth';
import ScrollToTop, { ScrollingContextProvider } from '@cfra-nextgen-frontend/shared/src/utils/scrolling';
import { Hub } from 'aws-amplify';
import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { GetAuthenticatedRoutesJsxProps, RouteType, SetUpAwsAmplifyRememberMeListener } from './types';

export function getNotAuthenticatedRoutes(LoginElement: JSX.Element): Array<RouteType<React.FC>> {
    return [
        { path: '/login', element: LoginElement },
        {
            path: '*',
            element: <Navigate to='/login' />,
        },
    ];
}

export function getNotAuthenticatedRoutesJsx(routes: Array<RouteType>) {
    return (
        <Route
            element={
                <ErrorBoundary FallbackComponent={Fallback.ErrorFallback}>
                    <Outlet />
                </ErrorBoundary>
            }
            children={routes.map((route) => {
                const { path, element, Wrapper, wrapperProps } = route;
                return (
                    <Route
                        key={path}
                        path={path}
                        element={Wrapper ? <Wrapper {...wrapperProps}>{element}</Wrapper> : element}
                    />
                );
            })}
        />
    );
}

export function getAuthenticatedRoutesJsx({
    authenticatedRoutesWrapperJsx,
    projectSpecificResources,
    routes,
}: GetAuthenticatedRoutesJsxProps) {
    return (
        <Route
            element={
                <ErrorBoundary FallbackComponent={Fallback.ErrorFallback}>
                    <ScrollingContextProvider>
                        <ScrollToTop />
                        <ProjectSpecificResourcesContextProvider
                            projectSpecificResources={projectSpecificResources || {}}>
                            {authenticatedRoutesWrapperJsx}
                        </ProjectSpecificResourcesContextProvider>
                    </ScrollingContextProvider>
                </ErrorBoundary>
            }
            children={routes.map((route) => {
                const { path, element, Wrapper, wrapperProps } = route;
                return (
                    <Route
                        key={path}
                        path={path}
                        element={Wrapper ? <Wrapper {...wrapperProps}>{element}</Wrapper> : element}
                    />
                );
            })}
        />
    );
}

export const setUpAwsAmplifyRememberMeListener: SetUpAwsAmplifyRememberMeListener = (rememberMe) => {
    const awsAmplifyListener = (data: any) => {
        switch (data?.payload?.event) {
            case 'signIn':
                if (rememberMe.current) {
                    return;
                }

                const refreshTokenKey = getAwsAmplifyLocalStorageKey({
                    username: data?.payload?.data?.username,
                    key: 'refreshToken',
                });

                localStorage.removeItem(refreshTokenKey);
                break;
            case 'signOut':
                rememberMe.current = false;
                break;
        }
    };

    Hub.listen('auth', awsAmplifyListener);
};
