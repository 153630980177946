import { ManageUserWatchlist } from 'components/watchlists/ManageUserWatchlist';
import { ShowSplaceScreen } from 'components/watchlists/ShowSplaceScreen';

export function WatchlistHome() {
    return (
        <ShowSplaceScreen
            onFalseComponent={
                <ManageUserWatchlist 
                    containerStyles={{
                        paddingBottom: '0px',
                    }}
                    title='Watchlists'
                    watchlistCompaniesProps={{
                        view: 'watchlist',
                        addWatchListStarColumn: true,
                        getNumberOfResultsText: (numberOfResults) => `${numberOfResults || 'No'} Companies`,
                    }}
                    showExport={true}
                    showUpdateWatchlist={true}
                />
            }
        />
    );
}
