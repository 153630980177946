import ChainIcon from '@cfra-nextgen-frontend/shared/src/assets/images/ChainIcon.svg';
import { StyledLink, StyledTitle } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { Box } from '@mui/material';
import { To } from 'react-router-dom';

type TitleWithLinkProps = {
    linkProps: {
        linkText: string;
        onClick?: () => void;
        state?: any;
        to: To;
    };
    title: string;
};

export function TitleWithLink({ linkProps, title }: TitleWithLinkProps) {
    const { linkText, ...restLinkProps } = linkProps;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '45px',
            }}>
            <StyledTitle sx={{ paddingLeft: '20px' }}>{title}</StyledTitle>
            {linkText && (
                <Box sx={{ paddingRight: '40px' }}>
                    <img
                        src={ChainIcon}
                        alt='Chain Icon'
                        style={{ width: '20px', height: '10px', marginRight: '9px' }}
                    />
                    <StyledLink {...restLinkProps}>{linkText}</StyledLink>
                </Box>
            )}
        </Box>
    );
}
