import { TypographyStyle1 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, SxProps } from '@mui/material';

export type SectionHeaderRowProps = {
    text: string;
    sx?: SxProps;
    afterTextSlot?: React.ReactNode;
};

export function SectionHeaderRow({ text, sx, afterTextSlot }: SectionHeaderRowProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: '45px',
                minHeight: '45px',
                ...sx,
            }}>
            <TypographyStyle1>{text}</TypographyStyle1>{afterTextSlot}
        </Box>
    );
}
