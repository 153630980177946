import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { useContext, useEffect, useState } from 'react';
import { SaveModal } from './SaveModal';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { CreateSavedItemResponseTypes, SavedItemListResponseTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { createSavedItemConfig, getSavedItemsByTypeConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { SaveModalFormProps } from './SaveModalForm';

type DuplicateSavedItemModalProps = {
    id: number;
    name: string;
    savedItemType: UserSavedItemsLookupID;
    modalTitle?: string;
    confirmBtnText?: string;
    cancelBtnText?: string;
    saveType?: SaveModalFormProps['saveType'];
    onCancel: () => void;
    onConfirm: () => void;
};

const defaultModalTitle = 'Copy Saved Item';
const defaultConfirmBtnText = 'Confirm';
const defaultCancelBtnText = 'Cancel';

export function DuplicateSavedItemModal(props: DuplicateSavedItemModalProps) {
    const {
        id,
        name,
        savedItemType,
        modalTitle = defaultModalTitle,
        confirmBtnText = defaultConfirmBtnText,
        cancelBtnText = defaultCancelBtnText,
        saveType = 'Save screen',
        onCancel = () => {},
        onConfirm = (data?: object) => {},
    } = props;

    const [updatedName, setUpdatedName] = useState(undefined);
    const [showExitConfirm, setShowExitConfirm] = useState(false);

    const analyticsCardName = `${modalTitle} close confirmation popup`;

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getSavedItemsQuery = sendSingleRequest<SavedItemListResponseTypes>({ types: [savedItemType], includeValues: true, doFreshRequest: true }, getSavedItemsByTypeConfig);

    const savedItemList = getSavedItemsQuery.data?.data || [];
    const savedItemNameList = savedItemList.map((item: { name: any }) => item?.name);

    const saveWatchList = sendSingleRequest<CreateSavedItemResponseTypes>(
        {
            noErrorOnNoKeyValuePairs: true,
            requestBody: {
                name: updatedName,
                saved_item_lid: savedItemType,
                value: savedItemList?.find(p => p.id == id)?.value
            },
            config: {
                enabled: updatedName && savedItemList?.findIndex(p => p.id == id) > -1 ? true : false
            },
        },
        createSavedItemConfig,
    );

    useEffect(() => {
        if (saveWatchList.isSuccess) {
            onConfirm(saveWatchList.data?.data);
        }
    }, [
        saveWatchList.data?.data,
        saveWatchList.isSuccess
    ]);

    function handleOnModalClose() {
        setShowExitConfirm(true);
    }

    return (
        <>
            <SaveModal
                title={modalTitle}
                value={"Copy " + name}
                openModal={typeof id === 'number'}
                callbackOnClose={handleOnModalClose}
                saveType={saveType}
                onPositiveAction={(data: any) => setUpdatedName(data)}
                existingNames={savedItemNameList}
                positiveBtnText='Save'
                forceEnablePositiveBtn={true}
            />
            <ConfirmationModal
                openModal={showExitConfirm}
                modalText='Exit without saving?'
                cancelCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${cancelBtnText}`,
                        cardName: modalTitle,
                    });
                    setShowExitConfirm(false);
                }}
                cancelButtonText={cancelBtnText}
                confirmCallback={() => {
                    globalThis.analytics?.registerAction?.({
                        action: `click on ${confirmBtnText}`,
                        cardName: analyticsCardName,
                    });
                    setShowExitConfirm(false);
                    onCancel();
                }}
                confirmButtonText={confirmBtnText}
            />
        </>
    );
}
