import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { styled, Button } from '@mui/material';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { useSnackbar } from 'notistack';
import { View, Text } from '@aws-amplify/ui-react';
import { Button as AWSButton } from '@aws-amplify/ui-react';
import { InstitutionalLogo } from '@cfra-nextgen-frontend/cfra-institutional/src/features/auth/components/InstitutionalLogo';


const StyledInput = styled('input')(({ theme }) => ({
    height: '60px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    color: '#555',
    fontSize: '17px',
    fontFamily: fontFamilies.GraphikRegular,
    minWidth: '300px',
    boxSizing: 'border-box',
    margin: '0 !important',
    width: '100%',
    outline: 'none',
    padding: '8px 16px',
    '&::placeholder': {
        color: '#555',
    },
    '&:focus': {
        borderColor: '#ddd',
        boxShadow: '0 0 4px 1px #1fb0f8 inset',
    },
}));

const StyledButton = styled(Button)(() => ({
    alignItems: 'center',
    backgroundColor: '#002b5a',
    borderRadius: '10px',
    boxSizing: 'border-box',
    color: '#fff',
    display: 'flex',
    fontFamily: fontFamilies.GraphikBlack,
    fontSize: '15px',
    height: '49px',
    justifyContent: 'center',
    marginTop: '8px',
    maxWidth: '400px',
    textTransform: 'uppercase',
    width: '100%',
    '&:hover': {
        backgroundColor: '#001327',
    },
    '&.Mui-disabled': {
        backgroundColor: '#eeeeee',
    }
}));

export const PasswordResetForm = ({ onCancel }: { onCancel: () => void }) => {
    const [existingPassword, setExistingPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypeNewPassword, setRetypeNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<any>(null);
    const [errors, setErrors] = useState({
        existingPassword: '',
        newPassword: '',
        retypeNewPassword: ''
    });

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                setUser(currentUser);
            } catch (err) {
                enqueueSnackbar('Unable to fetch user', { variant: 'error' });
            }
        };

        fetchUser();
    }, [enqueueSnackbar]);

    const validatePassword = () => {
        let errorMessages = { existingPassword: '', newPassword: '', retypeNewPassword: '' };

        if (existingPassword.trim() === '') {
            errorMessages.existingPassword = 'Existing password is required.';
        }
        if (newPassword.length < 8 || !/[a-z]/.test(newPassword) || !/[A-Z]/.test(newPassword) || !/[0-9]/.test(newPassword) || !/[^a-zA-Z0-9]/.test(newPassword)) {
            errorMessages.newPassword = 'Password must include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.';
        }

        if (newPassword !== retypeNewPassword) {
            errorMessages.retypeNewPassword = 'Passwords do not match.';
        }

        setErrors(errorMessages);
        return errorMessages.newPassword === '' && errorMessages.retypeNewPassword === '';
    };

    const handlePasswordReset = async () => {
        const isValid = validatePassword();
        if (!isValid) return;

        setLoading(true);
        try {
            const userAuth = await Auth.signIn(user.username, existingPassword);
            if (!userAuth) {
                setErrors(prev => ({ ...prev, existingPassword: 'Existing password is incorrect.' }));
                enqueueSnackbar('Existing password is incorrect.', { variant: 'error' });
                setLoading(false);
                return;
            }
            if (existingPassword === newPassword){
                setErrors(prev => ({ ...prev, newPassword: 'New password cannot be the same as your current password.' }));
                return
            }
            await Auth.changePassword(user, existingPassword, newPassword);
            enqueueSnackbar('Password reset successful!', { variant: 'success' });
            onCancel()
        } catch (err) {
            setErrors(prev => ({ ...prev, existingPassword: 'Existing password is incorrect.' }));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '14px', width: '400px' }}>
            <View textAlign='center' style={{ display: 'flex', flexDirection: 'column' }}>
                <InstitutionalLogo />
                <h3 style={{ color: '#002b5a', fontFamily: fontFamilies.GraphikMedium, fontSize: '18px', fontWeight: 500, margin: '0', marginBottom: '1rem' }}>
                    Reset Your Password
                </h3>
                <Text style={{ fontFamily: 'GraphikRegular', fontSize: '15px', lineHeight: '1.7em', color: '#555' }}>
                    Must be at least 8 characters
                </Text>
            </View>

            <StyledInput
                type='password'
                placeholder='Existing Password'
                value={existingPassword}
                onChange={(e) => setExistingPassword(e.target.value)}
            />
            {errors.existingPassword && <Text style={{ color: 'red' }}>{errors.existingPassword}</Text>}

            <StyledInput
                type='password'
                placeholder='New Password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
            />
            {errors.newPassword && <Text style={{ color: 'red' }}>{errors.newPassword}</Text>}

            <StyledInput
                type='password'
                placeholder='Re-Type New Password'
                value={retypeNewPassword}
                onChange={(e) => setRetypeNewPassword(e.target.value)}
            />
            {errors.retypeNewPassword && <Text style={{ color: 'red' }}>{errors.retypeNewPassword}</Text>}

            <StyledButton
                onClick={handlePasswordReset}
                disabled={loading}
            >
                {loading ? 'Processing...' : 'Submit'}
            </StyledButton>

            <AWSButton fontWeight='normal' onClick={onCancel} size='small' variation='link' className="cancel-link">
                Cancel
            </AWSButton>
        </div>
    );
};
