import { ReactComponent as ThreeDotsIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/ThreeDotsIconV3.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { Grid } from '@mui/material';
import React from 'react';
import { PillVariant1 } from './PillVariant1';

export type PillsRowVariant1Item = {
    content: string;
    onClick: () => void;
};

export function PillsRowVariant1({
    items,
    threshold = items.length,
}: {
    items: Array<PillsRowVariant1Item>;
    threshold?: number;
}) {
    const pillIcon =
        items.length > threshold ? (
            <PillVariant1
                tooltipContent={
                    <Grid container gap={'10px'} rowGap={'5px'}>
                        {React.Children.toArray(
                            items.map((pillProps, index) => {
                                return <PillVariant1 key={index} {...pillProps} />;
                            }),
                        )}
                    </Grid>
                }
                key={'view-more-pill'}
                content={
                    <CustomSvgIcon
                        component={ThreeDotsIcon}
                        viewBox='0 0 18 4'
                        sx={{
                            width: '18px',
                            height: '4px',
                            fill: '#007AB9',
                        }}
                    />
                }
                containerSx={{
                    backgroundColor: 'transparent',
                    
                }}
                buttonSx={{
                    padding: '12px',
                    minWidth: 'unset',
                    width: '42px'
                }}
            />
        ) : null;

    return (
        <>
            {React.Children.toArray(
                items.slice(0, threshold).map((pillProps, index) => {
                    return <PillVariant1 key={index} {...pillProps} />;
                }),
            )}
            {pillIcon}
        </>
    );
}
