import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';

export type MenuItemProps = {
    itemName: string;
    callback?: (event: React.MouseEvent<HTMLElement>) => void;
};

type ETFMenuProps = {
    children: React.ReactNode;
    menuItems: Array<MenuItemProps>;
};

export function ETFMenu({ children, menuItems }: ETFMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    let theme = createTheme();

    theme = createTheme(theme, {
        components: {
            MuiMenu: {
                styleOverrides: {
                    root: {
                        zIndex: 3000,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                        marginTop: 5.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            zIndex: 0,
                        },
                        ul: {
                            padding: '0',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontFamily: fontFamilies.GraphikRegular,
                        fontSize: '14px',
                        lineHeight: 1,
                        color: '#57626a',
                        padding: '10px 21px 12px 21px',
                        '&:hover': {
                            background: '#FFF',
                            color: '#007BB8',
                            borderLeft: 'solid #007BBB 4px',
                            paddingLeft: '20px',
                        },
                        ':first-of-type': {
                            borderBottom: 'solid #ccc 1px',
                            paddingBottom: '16px',
                            paddingTop: '21px',
                            color: '#007BB8'
                        },
                        ':nth-of-type(2)': {
                            paddingTop: '23px'
                        }
                    },
                },
            },
        },
    });

    return (
        <>
            {React.Children.toArray(children).map((child, index) => {
                if (React.isValidElement(child)) {
                    const childWithOnclick = child as React.ReactElement<
                        JSX.Element['type'],
                        JSX.Element['props'] & { onClick: (event: React.MouseEvent<HTMLElement>) => void }
                    >;
                    return React.cloneElement(childWithOnclick, { onClick: handleClick });
                }
                return null;
            })}
            <ThemeProvider theme={theme}>
                <Menu
                    anchorEl={anchorEl}
                    id='account-menu'
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    transitionDuration={{ enter: 0, exit: 75 }}
                    PaperProps={{
                        elevation: 0,
                        style: {
                            borderRadius: '10px',
                            marginTop: '11px',
                        },
                    }}>
                    {menuItems.map(({ itemName, callback }, index) => {
                        return (
                            <MenuItem onClick={callback} key={index} className={'data-cy-etf-menu-option'}>
                                {itemName}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </ThemeProvider>
        </>
    );
}
