export type IconConfiguration = {
    iconColor: string;
    iconHoverColor: string;
};

type IconConfigurationStates = {
    normal: IconConfiguration;
    error: IconConfiguration;
};

export const iconConfigurationVariant1: IconConfigurationStates = {
    normal: {
        iconColor: '#002B5A',
        iconHoverColor: '#007AB9',
    },
    error: {
        iconColor: 'rgb(220, 0, 0)',
        iconHoverColor: 'rgb(220, 0, 0)',
    },
};
