import { Navigate, Route, Routes } from 'react-router-dom';
import { BespokeEdgeHome } from '../components/BespokeEdgeHome';
import { BespokeAdmin } from '../components/BespokeAdmin';

export function BespokeRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='./edge' />} />
            <Route path='/edge' element={<BespokeEdgeHome />} />
            <Route path='/admin' element={<BespokeAdmin />} />
        </Routes>
    );
}
