import { Footer } from '@cfra-nextgen-frontend/shared/src/components/Footer';
import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';
import { HomePage } from 'features/home/components/HomePage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { horizontalPaddingInSu, maxPageWidthInPx } from 'utils/lookAndFeel';

export function HomePageRoutes() {
    return (
        <Routes>

            <Route path='/*' element={<Navigate to='.' />} />
            <Route
                element={
                    <>
                    <PageWithComponentInHeader
                        pageContainerSx={{
                            maxWidth: '100%',
                            paddingLeft: horizontalPaddingInSu,
                            paddingRight: horizontalPaddingInSu,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        tabContainerSx={{
                            paddingTop: '12px',
                            paddingBottom: horizontalPaddingInSu,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: maxPageWidthInPx,
                            boxSizing: 'content-box',
                            backgroundColor: 'unset',
                            width: '100%',
                        }}
                    />
                        <Footer />                    
                    </>
                }>
                <Route path='/' element={<HomePage />} />
            </Route>
        </Routes>
    );
}
