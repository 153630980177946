import { ResearchHubHome } from 'features/researchHub/components/ResearchHubHome';
import { Navigate, Route, Routes } from 'react-router-dom';

export function ResearchHubRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route path='/' element={<ResearchHubHome />} />
        </Routes>
    );
}
