import { Layout } from '@cfra-nextgen-frontend/shared';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { AgGridSelectedRowsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/GridThemeV2.scss';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/HideVerticalScroll.scss';
import {
    agGridGetAllRowsCount,
    agGridGetRenderedRowsCount,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SendSingleInfiniteRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import {
    ResultsKeys,
    ScreenerDataWithGenericResultsKey,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    CellRendererValueProcessorGetter,
    extractFromScreenerData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { watchListColumnWidth } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import { useInViewScrollDown } from '@cfra-nextgen-frontend/shared/src/hooks/useInViewScrollDown';
import { useMakeIndependent } from '@cfra-nextgen-frontend/shared/src/hooks/useMakeIndependent';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { ModelUpdatedEvent, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Dispatch, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { debounce } from 'lodash';

type AgGridCardInfiniteCSMProps<T> = {
    searchByParams: Parameters<SendSingleInfiniteRequest>[0];
    infiniteRequestParamsConfig: Parameters<SendSingleInfiniteRequest>[1];
    size: number;
    scrollThresholdPx: number;
    setCalculateInView: (calculateInView: ReturnType<typeof useInViewScrollDown>['calculateInView']) => void;
    setResults: (pages?: Array<T>) => void;
    updateSearchByParams: Dispatch<SearchByParams>;
    outerGetCellRendererValueProcessor?: CellRendererValueProcessorGetter;
};

export const AgGridCardInfiniteCSM = forwardRef(function <T extends ScreenerDataWithGenericResultsKey<ResultsKeys>>(
    {
        searchByParams,
        infiniteRequestParamsConfig,
        size,
        scrollThresholdPx,
        setCalculateInView,
        setResults,
        updateSearchByParams,
        outerGetCellRendererValueProcessor,
    }: AgGridCardInfiniteCSMProps<T>,
    ref: React.Ref<AgGridReact | null>,
) {
    const { sendSingleInfiniteRequest } = useContext(ProjectSpecificResourcesContext);

    const gridRef = useRef<AgGridReact>(null);

    useImperativeHandle(ref, () => gridRef.current);

    if (!sendSingleInfiniteRequest) {
        throw new Error('sendSingleInfiniteRequest is not provided.');
    }

    const researchScreenerQuery = sendSingleInfiniteRequest<T>(searchByParams, infiniteRequestParamsConfig);

    const { minWidths, customFlexibleColumns, columnDefs }: ReturnType<typeof extractFromScreenerData> = useMemo(() => {
        if (!researchScreenerQuery.data || !researchScreenerQuery.data.pages || !researchScreenerQuery.data.pages[0]) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        const extractFromScreenerDataResult = extractFromScreenerData({
            screenerData: researchScreenerQuery.data.pages[0],
            cardName: 'Research Hub Search Results',
            outerGetCellRendererValueProcessor,
        });

        return {
            ...extractFromScreenerDataResult,
            columnDefs: extractFromScreenerDataResult.columnDefs.map((columnDef) => ({
                ...columnDef,
                // disable sorting for all columns, the actual sorting should be handled on the backend side
                comparator: () => 0,
            })),
        };
    }, [researchScreenerQuery.data, outerGetCellRendererValueProcessor]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const { independentValue: researchScreenerQueryFetchNextPage } = useMakeIndependent({
        valueGetter: () => () => {
            researchScreenerQuery.fetchNextPage();
        },
        defaultValue: () => {},
    });

    const fetchNextPage = useCallback(
        (fetchNextPageOnIsEqual: boolean) => {
            if (!gridRef?.current?.api || !researchScreenerQuery?.data?.pages) {
                return;
            }

            const numberOfPagesLoadedIntoGrid = agGridGetAllRowsCount(gridRef.current) / size;

            if (!Number.isInteger(numberOfPagesLoadedIntoGrid)) {
                // if number of pages is not an integer, it means that the last page with (not a full page) is already loaded, so no need to fetch more rows.
                return;
            }

            if (numberOfPagesLoadedIntoGrid > researchScreenerQuery.data.pages.length) {
                throw new Error(
                    `Unexpected behavior. The number of pages loaded into the grid (${numberOfPagesLoadedIntoGrid}) is greater than the number of pages fetched (${researchScreenerQuery.data?.pages.length}).`,
                );
            }

            if (numberOfPagesLoadedIntoGrid === researchScreenerQuery.data.pages.length) {
                if (fetchNextPageOnIsEqual) {
                    researchScreenerQueryFetchNextPage.current();
                }

                return;
            }

            const rowsCount = agGridGetAllRowsCount(gridRef.current);
            const nextRows = researchScreenerQuery.data?.pages?.[numberOfPagesLoadedIntoGrid]?.results.research;

            // load next page into the grid
            gridRef.current.api.applyTransaction({
                add: nextRows,
            });

            // avoid issue with grid have blank white space and no more rows are rendered
            if (rowsCount + nextRows.length > 480) {
                gridRef.current.api.ensureIndexVisible(rowsCount + nextRows.length - 1, 'bottom');
            }
        },
        [researchScreenerQuery?.data?.pages, size, researchScreenerQueryFetchNextPage],
    );

    const { independentValue: fetchNextPageRef } = useMakeIndependent({
        valueGetter: () => () => {
            if (
                researchScreenerQuery.isFetchingNextPage ||
                researchScreenerQuery.isLoading ||
                !researchScreenerQuery?.data?.pages ||
                !gridRef?.current?.api ||
                researchScreenerQuery?.data?.pages?.length < 1 // skip the first page, as it goes into the grid via the rowsData prop
            ) {
                return;
            }

            fetchNextPage(true);
        },
        defaultValue: () => {},
    });

    const tableElementRef = useRef<HTMLDivElement>(null);
    const thresholdInPxRef = useRef<number>(scrollThresholdPx);

    const { calculateInView, unblockAndCalculateInView } = useInViewScrollDown({
        elementRef: tableElementRef,
        callbackRef: fetchNextPageRef,
        thresholdInPxRef,
    });

    // pass calculateInView to the outer component
    useEffect(() => {
        setCalculateInView(calculateInView);
    }, [setCalculateInView, calculateInView]);

    const getUnblockHandler = useCallback(
        (callback: (length: number) => void) => {
            return (event?: any) => {
                // skip the unblock call if first page is not fetched yet
                if (
                    researchScreenerQuery.isLoading ||
                    researchScreenerQuery.isFetchingNextPage ||
                    !researchScreenerQuery.hasNextPage ||
                    !researchScreenerQuery.data?.pages ||
                    researchScreenerQuery.data.pages.length === 0 ||
                    !gridRef.current ||
                    !gridRef.current.api
                ) {
                    return;
                }

                const numberOfDisplayedRows = agGridGetRenderedRowsCount(gridRef.current);
                const numberOfRowsToDisplay = agGridGetAllRowsCount(gridRef.current);

                if (numberOfRowsToDisplay > numberOfDisplayedRows) {
                    return;
                }

                return callback(researchScreenerQuery.data.pages.length);
            };
        },
        [
            researchScreenerQuery.isLoading,
            researchScreenerQuery.isFetchingNextPage,
            researchScreenerQuery.hasNextPage,
            researchScreenerQuery.data?.pages,
        ],
    );

    const { independentValue: onFirstDataRenderedRef } = useMakeIndependent({
        valueGetter: () =>
            getUnblockHandler((length) => {
                if (length !== 1) {
                    return;
                }

                calculateInView();
            }), // apply only for the first page
        defaultValue: () => () => {},
    });

    const { independentValue: onModelUpdatedRef } = useMakeIndependent({
        valueGetter: () =>
            debounce((event: ModelUpdatedEvent) => {
                getUnblockHandler((length) => {
                    if (length <= 1) {
                        // apply for all pages except the first one
                        return;
                    }

                    unblockAndCalculateInView();
                })(event);

                // handle ag grid columns sorting icon (only icon, the sorting logic supposed to be handled on backend side)
                const columnApi = event?.columnApi;
                if (!columnApi) {
                    return;
                }

                const columnsState = columnApi.getColumnState();

                // handle apply sorting from the API request to the ag grid
                if (searchByParams.orderBy && searchByParams.sortDirection) {
                    const sortColumn = columnsState?.find((column) => column.colId === searchByParams.orderBy);

                    if (!sortColumn) {
                        return; // if for some reason the api request contains sorting by column not present in the grid, don't apply sorting in the grid
                    }

                    if (sortColumn.sort === searchByParams.sortDirection) {
                        return; // if grid column already sorted by the same direction, don't apply sorting in the grid
                    }

                    // apply sorting for this specific one column (and reset sorting for other column is there is any)
                    columnApi.applyColumnState({
                        state: columnsState.map((column) => {
                            return {
                                ...column,
                                sort: column.colId === searchByParams.orderBy ? searchByParams.sortDirection : null,
                            };
                        }),
                        applyOrder: true,
                    });
                    return;
                }

                const sortColumn = columnsState?.find((column) => column.colId === searchByParams.orderBy);

                if (!sortColumn) {
                    return; // if no sorting options in the API request, and no sorting in the ag grid columns, don't remove sorting from the grid
                }

                // handle reset sorting from API request to ag grid
                columnApi.applyColumnState({
                    state: columnsState.map((column) => {
                        return {
                            ...column,
                            sort: null,
                        };
                    }),
                    applyOrder: true,
                });
            }, 200),
        defaultValue: debounce(() => {}, 200),
    });

    const { independentValue: onSortChangedRef } = useMakeIndependent<(event: SortChangedEvent) => void>({
        valueGetter: () => (event: SortChangedEvent) => {
            // handle only sorting by user click on header
            if (!event.columnApi || event.source !== 'uiColumnSorted') {
                return;
            }

            const columnsState = event.columnApi.getColumnState();
            const sortColumn = columnsState?.find((column) => column.sort);

            if (sortColumn) {
                // set sorting options for API
                updateSearchByParams({
                    orderBy: sortColumn.colId,
                    sortDirection: sortColumn.sort as 'asc' | 'desc',
                });
            } else {
                // reset sorting options for API
                updateSearchByParams({
                    orderBy: undefined,
                    sortDirection: undefined,
                });
            }
        },
        defaultValue: () => {},
    });

    // handle new pages from api started from the second page (skip first page)
    useEffect(() => {
        if (
            !gridRef?.current?.api ||
            // don't apply transaction for the first page, as it goes into the grid via the rowsData prop
            !researchScreenerQuery?.data?.pages ||
            researchScreenerQuery.data.pages.length < 2 ||
            researchScreenerQuery.isLoading ||
            researchScreenerQuery.isFetchingNextPage
        ) {
            return;
        }

        // don't apply transaction if the next page is already applied
        fetchNextPage(false);
    }, [
        researchScreenerQuery?.data?.pages,
        researchScreenerQuery.isFetchingNextPage,
        researchScreenerQuery.isLoading,
        fetchNextPage,
    ]);

    // used to set results count, the implementation of the setResultsCount should take care of
    // avoiding unnecessary re-renders
    useEffect(() => {
        if (researchScreenerQuery.data?.pages) {
            setResults(researchScreenerQuery.data.pages);
            return;
        }
        setResults(undefined);
    }, [researchScreenerQuery, setResults]);

    // keep if calculate on each render
    // when no gridRef is available, allow columns auto size
    // when gridRef is available, allow columns auto size only on the first page
    const enableAutoSizeAllColumns: boolean = gridRef?.current
        ? (function () {
              const numberOfPagesLoadedIntoGrid = agGridGetAllRowsCount(gridRef.current) / size;
              return numberOfPagesLoadedIntoGrid > 0 && numberOfPagesLoadedIntoGrid <= 1;
          })()
        : true;

    return (
        <Grid
            key='agGridCardInfiniteCsmTableElementRef'
            ref={tableElementRef}
            container
            gap={4.4}
            justifyContent='center'>
            <ThemeProvider theme={createTheme()}>
                <Box sx={{ width: '100%' }}>
                    <AgGridSelectedRowsContextProvider isSSRMEnabled={false}>
                        <AgGirdCard
                            labelProps={{ width: '100%' }}
                            showDefaultExportButton={false}
                            ref={gridRef}
                            columnDefs={columnDefs}
                            rowMultiSelectWithClick={true}
                            rowSelection='multiple'
                            suppressRowClickSelection={true}
                            customFlexibleColumns={customFlexibleColumns}
                            getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                            labelPanelContainerStyles={{ paddingTop: '36px' }}
                            rowsData={researchScreenerQuery.data?.pages[0]?.results.research}
                            maxNumberOfRowsToDisplay={99999}
                            gridTheme={['grid-theme-v2', 'hide-vertical-scroll']}
                            unlimitedCalculatedHeight
                            // auto size columns only on the first page
                            enableAutoSizeAllColumns={enableAutoSizeAllColumns}
                            onModelUpdatedRef={onModelUpdatedRef}
                            onFirstDataRenderedRef={onFirstDataRenderedRef}
                            onSortChangedRef={onSortChangedRef}
                            fullHeightGrid
                            defaultMaxWidth={9999} // disable max width for columns
                        />
                    </AgGridSelectedRowsContextProvider>
                    {researchScreenerQuery?.isFetchingNextPage && (
                        <Box sx={{ width: '100%' }}>
                            <Layout.Skeleton height='10px' />
                        </Box>
                    )}
                </Box>
            </ThemeProvider>
        </Grid>
    );
}) as <T extends ScreenerDataWithGenericResultsKey<ResultsKeys>>(
    props: AgGridCardInfiniteCSMProps<T> & { ref?: React.Ref<AgGridReact | null> },
) => JSX.Element;
