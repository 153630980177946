import { Authenticator, Button, useAuthenticator, View } from '@aws-amplify/ui-react';
import { ETFPageContainer } from 'components/layout';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BackgroundImage from '../../../assets/images/login_background_image.png';
import { InstitutionalLogo } from './InstitutionalLogo';
import './Login.scss';

export function Login({ rememberMe }: { rememberMe?: React.MutableRefObject<boolean | undefined> }) {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/';

    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);

    const components = useMemo(() => ({
        Header() {
            return (
                <InstitutionalLogo description='Login to access our full suite of forensic research including Accounting Lens, Legal Edge and Bespoke Edge.
'/>
            );
        },
        SignIn: {
            Footer() {
                const { toResetPassword } = useAuthenticator();
                return (
                    <View style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '400px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label className="checkbox-label">
                                <input
                                    type='checkbox'
                                    onClick={(e) => {
                                        if (!rememberMe) {
                                            return;
                                        }
                                        rememberMe.current = (e.target as HTMLInputElement)?.checked;
                                    }}
                                    className="checkbox-input"
                                />
                                <span className={`checkbox-custom ${rememberMe?.current ? 'checked' : ''}`} />
                                <span style={{ marginLeft: '0.5rem' }}>Remember Me</span>
                            </label>
                        </div>
                        <Button
                            fontWeight='normal'
                            onClick={toResetPassword}
                            size='small'
                            variation='link'
                            className="forgot-password-link"
                        >
                            Forgot Password?
                        </Button>
                    </View>
                );
            },
        },
    }), [rememberMe]);

    const formFields = {
        signIn: {
            username: {
                placeholder: 'Email',
                isRequired: true
            },
            password: {
                placeholder: 'Password',
                isRequired: true
            },
        },
    };

    return (
        <ETFPageContainer 
            containerStyles={{ 
                maxWidth: 'unset', 
                backgroundColor: '#fff',
                minHeight: '500px'
            }}
        >
            <div style={{ display: 'flex', minHeight: '100vh', height: '100%', overflow: 'hidden' }}>
                <div style={{ flex: '0 0 50%', backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                <div style={{ flex: '0 0 50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Authenticator formFields={formFields} components={components} hideSignUp={true} />
                </div>
            </div>
        </ETFPageContainer>
    );
}
