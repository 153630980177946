import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';

interface BiggestConcernToggleProps {
    onRegionChange: (regions: string[]) => void; 
}

export function BiggestConcernToggle({ onRegionChange }: BiggestConcernToggleProps) {
    const [selectedOption, setSelectedOption] = useState(0); 

    const options = ['All', 'North America', 'Europe'];
    
    const regionMapping: { [key: string]: string[] } = {
        All: ['2', '3'],
        'North America': ['2'],
        Europe: ['3'],
    };
    
    const handleToggle = (index: number) => {
        setSelectedOption(index);
        const selectedRegion = options[index];
        onRegionChange(regionMapping[selectedRegion]);
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ background: '#E4E8F4', borderRadius: '30px' }}>
            {options.map((option, index) => (
                <Button
                    key={index}
                    variant={selectedOption === index ? 'contained' : 'outlined'}
                    onClick={() => handleToggle(index)}
                    sx={{
                        padding: '10px 20px',
                        borderRadius: '20px',
                        background: selectedOption === index ? '#ffffff' : '#E4E8F4',
                        color: selectedOption === index ? '#007AB9' : '#666666',
                        fontFamily: fontFamilies.GraphikSemibold,
                        '&:hover': {
                            background: '#ffffff',
                            border: 'none',
                            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                        },
                        margin: 0,
                        border: 'none',
                        textTransform: 'none',
                    }}
                >
                    <span style={{ fontSize: '13.5px' }}>{option}</span>
                </Button>
            ))}
        </Box>
    );
}
