import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { iconConfigurationVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Icon/iconConfigurations';
import { TypographyStyle20 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { ButtonBase, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
export type ItemVariant8Props = {
    text: string;
    onClick: () => void;
    iconStyles?: SxProps;
    buttonStyles?: SxProps;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

// show icon with text on the right side (for ex. used to display the downloadable resources inside the information popup on the scores page in Institutional)
export function ItemVariant8({
    text,
    onClick,
    iconStyles = {
        boxSizing: 'content-box',
        width: '18px',
        height: '24px',
        paddingLeft: '3px',
    },
    buttonStyles = {
        width: '22px',
        height: '24px',
    },
    icon = PdfIcon,
}: ItemVariant8Props) {
    return (
        <Box sx={{ display: 'flex', gap: '8px' }}>
            <ButtonBase
                onClick={onClick}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '5px',
                    ...buttonStyles,
                    '&:hover': {
                        backgroundColor: '#F3F5FC',
                        '& svg': {
                            fill: iconConfigurationVariant1?.normal.iconHoverColor,
                            color: iconConfigurationVariant1?.normal.iconHoverColor,
                        },
                    },
                }}>
                <CustomSvgIcon
                    component={icon}
                    viewBox='0 0 18 18'
                    sx={
                        {
                            color: iconConfigurationVariant1?.normal.iconColor,
                            ...iconStyles,
                        } as SxProps
                    }
                />
            </ButtonBase>
            <TypographyStyle20
                sx={{
                    display: 'flex',
                    alignItems: 'end',
                    lineHeight: '24px',
                }}>
                {text}
            </TypographyStyle20>
        </Box>
    );
}
