import { styled, MenuItem } from '@mui/material';
import communication from '@cfra-nextgen-frontend/shared/src/assets/icons/communication.svg';
import consumer from '@cfra-nextgen-frontend/shared/src/assets/icons/consumer.svg';
import staples from '@cfra-nextgen-frontend/shared/src/assets/icons/staples.png';
import energy from '@cfra-nextgen-frontend/shared/src/assets/icons/energy.png';
import financial from '@cfra-nextgen-frontend/shared/src/assets/icons/financial.png';
import healthcare from '@cfra-nextgen-frontend/shared/src/assets/icons/healthcare.png';
import industrials from '@cfra-nextgen-frontend/shared/src/assets/icons/industrials.svg';
import it from '@cfra-nextgen-frontend/shared/src/assets/icons/it.svg';
import materials from '@cfra-nextgen-frontend/shared/src/assets/icons/materials.svg';
import realEstate from '@cfra-nextgen-frontend/shared/src/assets/icons/real-estate.svg';
import utilities from '@cfra-nextgen-frontend/shared/src/assets/icons/utilities.svg';
import other from '@cfra-nextgen-frontend/shared/src/assets/icons/other.svg';
import { StyledAvatar } from "@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar";

const sectorImageMap: { [key: string]: string | any } = {
    'Communication Services': communication,
    'Consumer Discretionary': consumer,
    'Consumer Staples': staples,
    Energy: energy,
    Financials: financial,
    'Health Care': healthcare,
    Industrials: industrials,
    'Information Technology': it,
    Materials: materials,
    Other: other,
    'Real Estate': realEstate,
    Utilities: utilities,
};

type PillProps = {
    label?: string;
    count?: number;
    itemKey?: number | string;
    field: any;
    onChipClick: () => void;
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'GraphikRegular',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    width: '100%',
    '&:hover': {
        backgroundColor: '#E0E0E0',
    },
}));

export function PillMore(props: PillProps) {
    return (
        <StyledMenuItem onClick={props.onChipClick}>
            {props.label && (
                <StyledAvatar alt={props.label} src={sectorImageMap[props.label]} sx={{ marginRight: '10px' }} />
            )}
            <span
                style={{
                    display: 'block',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                    color: '#222222',
                    lineHeight: '2.1em',
                }}>
                {props.label}
            </span>
            <span style={{ color: '#007BB8', marginLeft: '6px', lineHeight: '1.8em', fontFamily: 'GraphikMedium' }}>
                {props.count}
            </span>
        </StyledMenuItem>
    );
}
