import { Route, Routes } from 'react-router-dom';
import CompanyProfilePage from '../index';
import { horizontalPaddingInSu, maxPageWidthInPx } from 'utils/lookAndFeel';
import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';

export function CompanyProfileRoutes() {
    return (
        <Routes>
            <Route
                element={
                    <PageWithComponentInHeader
                        pageContainerSx={{
                            maxWidth: '100%',
                            paddingLeft: horizontalPaddingInSu,
                            paddingRight: horizontalPaddingInSu,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                        tabContainerSx={{
                            paddingTop: horizontalPaddingInSu,
                            paddingBottom: horizontalPaddingInSu,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: maxPageWidthInPx,
                            boxSizing: 'content-box',
                            backgroundColor: 'unset',
                            width: '100%',
                        }}
                    />
                }>
                <Route path='/company/:company_id' element={<CompanyProfilePage />} />
                <Route path='/security/:security_id' element={<CompanyProfilePage />} />
            </Route>
        </Routes>
    );
}
