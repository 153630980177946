import { Grid, Stack } from '@cfra-nextgen-frontend/shared/src/components/layout';
import Box from '@mui/material/Box';
import { Typography } from '@cfra-nextgen-frontend/shared';
import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';
import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { PageTheme } from 'components/themes/theme';

const getFormattedValue = (value: number) => (value === null || value === undefined ? 'NA' : value.toString());

export type CFRAScoresProps = {
    title: string;
    color: string;
    value: any;
    date: string;
    textTitle: string;
    ticker: string;
    handleClick: () => void;
};
export function CFRAScores(stackContent: CFRAScoresProps) {
    const scoreDetail = `Scores indicate decile rank relative to index or region. A decile score of 1 indicates lower ${stackContent.textTitle}, while a 10 indicates higher ${stackContent.textTitle}.`;
    return (
        <Grid container>
            <Grid item xs={12} sx={{ paddingBottom: '15px' }}>
                <Typography variant='cardSubCaption' sx={{ lineHeight: '28px', letterSpacing: '0.1%' }}>
                    {'CFRA ' + stackContent.title + (!stackContent.title.endsWith('Score') ? ' Score' : '')}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Box
                    sx={{
                        border: '2px solid ' + stackContent.color,
                        width: '114px',
                        height: '114px',
                        borderRadius: 2,
                    }}>
                    <Stack sx={{ width: '100%', textAlign: 'center', padding: '5px 0px' }} key={stackContent.title}>
                        <Typography variant='scoreText'>
                            <div style={{ textTransform: 'uppercase' }}>cfra</div>
                            <div style={{ textTransform: 'uppercase' }}>{stackContent.title}</div>
                        </Typography>
                        <Typography
                            variant='scoreValue'
                            sx={{
                                color: stackContent.color,
                                fontWeight: 600,
                            }}>
                            {getFormattedValue(stackContent.value)}
                        </Typography>
                        <Typography variant='scoreDate' sx={{ textTransform: 'uppercase' }}>
                            {stackContent.date}
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={9}>
                <Stack sx={{ textAlign: 'left' }}>
                    <Typography variant='cardDescriptionTitle' sx={{ paddingBottom: '5px', fontWeight: 500 }}>
                        Score Detail
                    </Typography>
                    <Typography variant='cardDescriptionText' sx={{ fontWeight: 400 }}>
                        {scoreDetail}
                    </Typography>
                    <Box>
                        <ETFLinkButton
                            onClick={stackContent.handleClick}
                            text='Diagnostic Report:'
                            endIcon={
                                <CustomSvgIcon
                                    component={PdfIcon}
                                    viewBox='0 0 18 18'
                                    sx={{
                                        width: '18px',
                                        fill: '#002B5A',
                                        '&:hover': {
                                            fill: '#007AB9',
                                        },
                                    }}
                                />
                            }
                            sx={{
                                ...PageTheme.typography.cardLink,
                            }}
                        />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: '15px' }}>
                <Typography variant='cardDescriptionTitle' sx={{ fontWeight: 500 }}>
                    {`Previous Accrual Deciles for ${stackContent.ticker}`}
                </Typography>
            </Grid>
        </Grid>
    );
}
