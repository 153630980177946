import { Navigate, Route, Routes } from 'react-router-dom';
import { HomePageRoutes } from './HomePage';

export function HomeRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route path='/' element={<HomePageRoutes />} />
        </Routes>
    );
}
