import {
    SvgIcon
} from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';


interface CustomSvgIconProps extends SvgIconProps {}

export const CustomSvgIcon: React.FC<CustomSvgIconProps> = (props) => {
    return <SvgIcon {...props} />;
};
