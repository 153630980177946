import { useContext, useEffect, useState } from "react";
import { ProjectSpecificResourcesContext } from "../components/ProjectSpecificResourcesContext/Context";
import { ApiNames, RequestTypes } from "../utils";
import { UserEntitlementsResponse, UserEntitlementsResponseData } from "../types/userEntitlements";

export function useUserEntitlements() {
    const [userEntitlements, setUserEntitlements] = useState<UserEntitlementsResponseData>();
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getUserEntitlementsQuery = sendSingleRequest<UserEntitlementsResponse>({ noErrorOnNoKeyValuePairs: true }, {
        path: 'entitlements',
        apiName: ApiNames.UserManagement,
        queryKeyFirstElement: 'getUserEntitlements',
        requestType: RequestTypes.GET,
    });

    useEffect(() => {
        if (getUserEntitlementsQuery.isSuccess && getUserEntitlementsQuery?.data?.data) {
            setUserEntitlements((getUserEntitlementsQuery.data.data));
        }
    }, [getUserEntitlementsQuery])

    return { userEntitlements }
}