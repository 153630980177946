import { useContext, useEffect, useState } from "react";
import { ProjectSpecificResourcesContext } from "../components/ProjectSpecificResourcesContext/Context";
import { CreateSavedItemResponseTypes, SavedItemListResponseTypes } from "../components/Screener/types/savedScreens";
import { HomepagePreferences, PreferenceType, UserPreferences, WatchlistPreferences } from "../types/userPreferences";
import { ApplicationType, UserSavedItemsLookupID } from "../utils";
import { createSavedItemConfig, getSavedItemsByTypeConfig, updateSavedItemByIdConfig } from "../utils/userSavedItem";

type UsePreferenceType = {
    applicationType: ApplicationType;
    getPreferencesConfig?: { cacheTime?: number }
}

export type SetUserPreferences = (t: PreferenceType, v: any) => void

export function useUserPreferences({ applicationType, getPreferencesConfig}: UsePreferenceType) {
    const [userPreferences, setUserPreferences] = useState<UserPreferences>();
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [updateServerPreferences, setUpdateServerPreferences] = useState(false);
    const savedItemLid = applicationType === ApplicationType.InstitutionalPortal ? UserSavedItemsLookupID.InstitutionalUserPreferences : -1;

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getPreferencesQuery = sendSingleRequest<SavedItemListResponseTypes>({
        types: [savedItemLid], includeValues: true, config: { cacheTime: getPreferencesConfig?.cacheTime || 3000 }
    }, getSavedItemsByTypeConfig);
    const existingUserPrefernceData = (getPreferencesQuery.data?.data?.length && getPreferencesQuery.data?.data?.length > 0) ? getPreferencesQuery.data?.data[0] : undefined;
    const createUserPreference = sendSingleRequest<CreateSavedItemResponseTypes>(
        {
            noErrorOnNoKeyValuePairs: true,
            requestBody: {
                name: `UserPreferences`,
                saved_item_lid: savedItemLid,
                value: { preferences: {} }
            },
            config: {
                enabled: existingUserPrefernceData === undefined && getPreferencesQuery?.isSuccess
            }
        },
        createSavedItemConfig,
    );

    useEffect(() => {
        if (createUserPreference.isSuccess) {
            setUserPreferences((createUserPreference?.data?.data?.value as UserPreferences));
        }
    }, [
        createUserPreference.data?.data,
        createUserPreference.isSuccess
    ]);

    const updateUserPreferenceQuery = sendSingleRequest<any>({ savedItems: existingUserPrefernceData?.id, config: {
        enabled: updateServerPreferences === true && existingUserPrefernceData !== undefined,
        cacheTime: 3000
    }, requestBody: { value: userPreferences } }, updateSavedItemByIdConfig);

    useEffect(() => {
        setUpdateServerPreferences(false);
    }, [
        updateUserPreferenceQuery.data?.data,
        updateUserPreferenceQuery.isSuccess,
        updateUserPreferenceQuery.isError
    ])

    useEffect(() => {
        if (getPreferencesQuery.isSuccess && getPreferencesQuery?.data?.data?.length > 0 && !updateUserPreferenceQuery.isLoading) {
            setUserPreferences((getPreferencesQuery.data.data[0].value as UserPreferences));
        }
    }, [getPreferencesQuery, updateUserPreferenceQuery])

    const preferenceUpdated = () => {
        setUpdateServerPreferences(true)
    }

    const setPreferences = (preferenceType: PreferenceType, value: any | undefined) => {
        let updatedPreferences: UserPreferences = Object.assign({}, userPreferences);
        let homepageObj: HomepagePreferences = userPreferences?.preferences?.homepage || {}

        switch (preferenceType) {
            case PreferenceType.WatchlistLastVisited:
                let watchlistObj: WatchlistPreferences = userPreferences?.preferences?.watchlist || {}
                watchlistObj.last_viewed_id = value ? Number(value) : value;
                updatedPreferences = {preferences: {...userPreferences?.preferences, watchlist: watchlistObj}}
                setUserPreferences(updatedPreferences);
                break;

            case PreferenceType.HomepageCompanyReportsResearchTypeIds:
                if (Array.isArray(value)) {
                    homepageObj['company_reports_research_type_ids'] = value.map(v => v ? Number(v) : v);
                    updatedPreferences = { preferences: { ...userPreferences?.preferences, homepage: homepageObj } }
                    setUserPreferences(updatedPreferences);
                }
                break;

            case PreferenceType.HomepageIndustryResearchGicsSector:
                if (Array.isArray(value)) {
                    homepageObj['industry_research_gics_sector'] = value.map(v => v ? String(v) : v);
                    updatedPreferences = { preferences: { ...userPreferences?.preferences, homepage: homepageObj } }
                    setUserPreferences(updatedPreferences);
                }
                break;

            case PreferenceType.HomepageLastViewedWatchlist:
                homepageObj['last_viewed_watchlist_id'] = value ? Number(value) : value;
                homepageObj['industry_research_gics_sector'] = []
                homepageObj['company_reports_research_type_ids'] = []
                updatedPreferences = { preferences: { ...userPreferences?.preferences, homepage: homepageObj } }
                setUserPreferences(updatedPreferences);
                break;

            default:
                break;
        }

        preferenceUpdated();
    }

    return { userPreferences, setPreferences }
}