import { ResearchComponent } from 'components/ResearchComponent';
import { getFiltersJsxVariant1 } from 'components/ResearchComponent/components/getFiltersJsx';

const getFiltersJsx = getFiltersJsxVariant1({
    pillsRowComponentProps: {
        filterMetadataKey: 'insights.research_report.composite_gics_sector_code',
    },
    virtualizeAutocompleteComponentsProps: [
        {
            defaultInputLabel: 'Sub-Industry',
            filterMetadataKey: 'insights.research_report.composite_gics_sub_industry_code',
        },
        {
            defaultInputLabel: 'Document Type',
            filterMetadataKey: 'insights.research_report.research_type_name',
        },
        {
            defaultInputLabel: 'Region',
            filterMetadataKey: 'insights.research_report.composite_region_country_lid',
        },
        {
            defaultInputLabel: 'Author',
            filterMetadataKey: 'insights.research_report_author.analyst_id',
        },
    ],
    dateRangePickerComponentProps: {
        filterMetadataKey: 'insights.research_report.publish_timestamp',
    },
});

export function BespokeEdgeHome() {
    return (
        <ResearchComponent
            filtersSectionProps={{
                getFiltersJsx,
            }}
            queriesKeyFirstElementPostfix='bespoke'
            screenerSearchByParams={{
                view: 'research_hub',
            }}
        />
    );
}
