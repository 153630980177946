import {
    DataItem,
    ScreenerDataWithGenericResultsKey,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { getSumOfAllValues } from '@cfra-nextgen-frontend/shared/src/utils/arrays';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export function getScreenerInfiniteOptionsProps<T extends string>(resultsKey: T) {
    return {
        getRowsData: function (data: ScreenerDataWithGenericResultsKey<T>): Array<DataItem> {
            return data?.results?.[resultsKey];
        },
        scrollThresholdInPx: 315,
    };
}

export function getScreenerInfiniteRequestParamsConfig<T extends string>(
    size: number,
    resultsKey: T,
    maxFetch?: number,
) {
    return {
        requestType: RequestTypes.POST,
        apiName: ApiNames.Research,
        getNextPageParam: (lastPage: unknown, allPages: Array<unknown>) => {
            const lastPageLength = (lastPage as ScreenerDataWithGenericResultsKey<T>)?.results?.[resultsKey].length;

            if (!lastPageLength || lastPageLength < size) {
                return undefined;
            }

            if (maxFetch) {
                let current = allPages.flatMap(
                    (page) => (page as ScreenerDataWithGenericResultsKey<T>).results[resultsKey],
                ).length;

                if (current >= maxFetch) {
                    return undefined;
                }
            }

            return getSumOfAllValues(
                allPages.map((page) => (page as ScreenerDataWithGenericResultsKey<T>).results[resultsKey].length),
            );
        },
    };
}
