import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { ScoresInfoBanner } from 'features/scores/components/ScoresInfoBanner';
import { horizontalPaddingInSu } from 'utils/lookAndFeel';
import { ManageUserWatchlist } from 'components/watchlists/ManageUserWatchlist';
import { ShowSplaceScreen } from 'components/watchlists/ShowSplaceScreen';

export function ScoresHome() {
    return (
        <Grid container spacing={horizontalPaddingInSu}>
            <Grid item xs={12}>
                <ScoresInfoBanner />
            </Grid>
            <Grid item sm={12}>
                <ShowSplaceScreen
                    onFalseComponent={
                        <ManageUserWatchlist
                            title='Filter'
                            watchlistCompaniesProps={{
                                view: 'scores',
                                getNumberOfResultsText: (numberOfResults) => `Total Results: ${numberOfResults || 'No records found'}`,
                            }}
                            showExport={true}
                            exportProps={{
                                fileName: 'CFRA Scores',
                                sheetName: 'Data Details',
                                metadata: {
                                    cardName: 'CFRA Scores'
                                }
                            }}
                        />
                    }
                />
            </Grid>
        </Grid>
    );
}
