import { Avatar, styled } from "@mui/material";

export const StyledAvatar = styled(Avatar)({
    width: 16,
    height: 16,
    borderRadius: '0',
    objectFit: 'contain',
    img: {
        objectFit: 'contain',
    },
});