import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import { TypographyStyle7 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box } from '@mui/material';
import { SectionHeaderRow } from './SectionHeader';

type TitleWithSubtitleProps = {
    title: string;
    subTitle: string;
};

export function TitleDividerSubtitle({ title, subTitle }: TitleWithSubtitleProps) {
    return (
        <SectionHeaderRow
            text={title}
            sx={{ paddingLeft: '35px' }}
            afterTextSlot={
                <>
                    <DividerStyle1 sx={{ margin: '0px 8px', height: '14px' }} />
                    <Box
                        sx={{
                            height: '18px',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <TypographyStyle7>{subTitle}</TypographyStyle7>
                    </Box>
                </>
            }
        />
    );
}
