import { styled, Chip, Avatar } from '@mui/material';
import communication from '@cfra-nextgen-frontend/shared/src/assets/icons/communication.svg';
import consumer from '@cfra-nextgen-frontend/shared/src/assets/icons/consumer.svg';
import staples from '@cfra-nextgen-frontend/shared/src/assets/icons/staples.png';
import energy from '@cfra-nextgen-frontend/shared/src/assets/icons/energy.png';
import financial from '@cfra-nextgen-frontend/shared/src/assets/icons/financial.png';
import healthcare from '@cfra-nextgen-frontend/shared/src/assets/icons/healthcare.png';
import industrials from '@cfra-nextgen-frontend/shared/src/assets/icons/industrials.svg';
import it from '@cfra-nextgen-frontend/shared/src/assets/icons/it.svg';
import materials from '@cfra-nextgen-frontend/shared/src/assets/icons/materials.svg';
import realEstate from '@cfra-nextgen-frontend/shared/src/assets/icons/real-estate.svg';
import utilities from '@cfra-nextgen-frontend/shared/src/assets/icons/utilities.svg';
import other from '@cfra-nextgen-frontend/shared/src/assets/icons/other.svg';
import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';

const sectorImageMap: { [key: string]: string | any } = {
    'Communication Services': communication,
    'Consumer Discretionary': consumer,
    'Consumer Staples': staples,
    Energy: energy,
    Financials: financial,
    'Health Care': healthcare,
    Industrials: industrials,
    'Information Technology': it,
    Materials: materials,
    Other: other,
    'Real Estate': realEstate,
    Utilities: utilities,
};

type PillProps = {
    label?: string;
    count?: number;
    itemKey?: number | string;
    field: any;
    onChipClick: () => void;
    useSecondaryStyle?: boolean;
};

const SectorChip = styled(Chip)(() => ({
    backgroundColor: '#F3F5FC',
    color: '#5A5A5A',
    fontFamily: 'GraphikMedium',
    fontSize: '13px',
    whiteSpace: 'normal',
    padding: '10px 11px',
    height: '48px',
    borderRadius: '30px',
    gap: '7px',
    '& .MuiChip-avatar': {
        width: 16,
        height: 16,
    },
    '& .MuiChip-avatar img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
    '& .MuiChip-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: '10px 0',
    },
    '& .sector-count': {
        color: '#007BB8',
        marginLeft: '11px',
    },
}));

export function Pill(props: PillProps) {
    return (
        <SectorChip
            sx={
                props.useSecondaryStyle
                    ? {
                          backgroundColor: '#ffffff',
                          border: '1px solid #007BB8',
                          '&:hover': {
                              bgcolor: 'rgba(0, 0, 0, 0.12) !important',
                          },
                      }
                    : {}
            }
            label={
                <>
                    <span
                        style={{
                            display: 'block',
                            maxWidth: '104px',
                            overflowWrap: 'break-word',
                            wordBreak: 'break-word',
                            whiteSpace: 'normal',
                            lineHeight: '1',
                        }}>
                        {props.label}
                    </span>
                    <span className='sector-count' style={{ marginLeft: '8px' }}>
                        {props.count}
                    </span>
                </>
            }
            avatar={props.label ? <StyledAvatar alt={props.label} src={sectorImageMap[props.label]} /> : undefined}
            onClick={props.onChipClick}
            clickable
        />
    );
}
