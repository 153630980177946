import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { CompanyParams, AggregateItem } from '../types/company';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';

const queryFields: Record<string, Array<string>> = {
    getCompanyDetails: [
        'company_security.company.cfra_company_id',
        'company_security.security_trading.cfra_security_trading_id',
    ],
    getHasConcernsPdf: [
        'insights.research_report_security__company.research_report_security.company.cfra_company_id',
        'insights.research_report_security__trading.research_report_security.security_trading.cfra_security_trading_id',
    ],
    getCompanyHeadlines: [
        'insights.research_report_security__company.research_report_security.company.cfra_company_id',
        'insights.research_report_security__trading.research_report_security.security_trading.cfra_security_trading_id',
    ],
};

export const availableReports = [
    { key: ResearchTypeId.LegalReport, value: 'Legal Report' },
    { key: ResearchTypeId.LegalAlert, value: 'Legal Alert' },
    { key: ResearchTypeId.FrsCompanyReport, value: 'Company Report' },
    { key: ResearchTypeId.FrsNotebooks, value: 'Notebooks' },
    { key: ResearchTypeId.FrsEarningsAlert, value: 'Earnings Alert' },
    { key: ResearchTypeId.LegalPipeline, value: 'Legal Pipeline' },
    { key: ResearchTypeId.LegalPipelineSpecialInterestList, value: 'Legal Pipeline - Special Interest List' },
];

const pdf_url_body = {
    view: 'research_hub',
    includeData: true,
    includeMetadata: false,
    size: 1,
    securityType: 'research',
};

function getRequestBody(fnName: string, queryParams: CompanyParams) {
    const fields = queryFields[fnName];

    return {
        filters: {
            values: {
                ...(queryParams.company_id !== undefined
                    ? { [fields[0]]: { values: [queryParams.company_id] as Array<any> } }
                    : {}),
                ...(queryParams.security_id !== undefined
                    ? { [fields[1]]: { values: [queryParams.security_id] as Array<any> } }
                    : {}),
            },
        },
    };
}

export function getQueryConfig(fnName: string, index?: string) {
    return {
        path: (index || 'research') + '/screener',
        apiName: ApiNames.Research,
        queryKeyFirstElement: fnName,
        requestType: RequestTypes.POST,
    };
}

export function getCompanyDetails(queryParams: CompanyParams) {
    return {
        requestBody: getRequestBody('getCompanyDetails', queryParams),
        view: 'companyprofile',
        includeData: true,
        size: 1,
    };
}

export function getScoreHistory(enableQuery: boolean, value: any, viewName: string) {
    return {
        requestBody: {
            filters: {
                values: value,
            },
        },
        view: viewName,
        size: 4,
        includeData: true,
        includeMetadata: true,
        config: { enabled: enableQuery },
    };
}

export function getHasConcernsPdf(queryParams: CompanyParams, isBiggestConcern: boolean = false) {
    let requestBody = getRequestBody('getHasConcernsPdf', queryParams);
    requestBody.filters.values['insights.research_report.research_type_id'] = {
        values: [ResearchTypeId.FrsCompanyReport],
    };

    return {
        requestBody: requestBody,
        ...pdf_url_body,
        config: { enabled: queryParams.enableQuery && isBiggestConcern },
    };
}

export function getScoresPdf(queryParams: CompanyParams) {
    let requestBody = getRequestBody('getHasConcernsPdf', queryParams);
    requestBody.filters.values['insights.research_report.research_type_id'] = {
        values: [ResearchTypeId.FrsDiagnosticReport],
    };

    return {
        requestBody: requestBody,
        ...pdf_url_body,
        config: { enabled: queryParams.enableQuery },
    };
}

export function getIRAPPdf(enableQuery: boolean, gics_code: string) {
    return {
        requestBody: {
            filters: {
                values: {
                    'insights.research_report.research_type_id': { values: [ResearchTypeId.FrsIRAPS] },
                    'research_report_industry.gics_code': { values: [gics_code] },
                },
            },
        },
        ...pdf_url_body,
        config: { enabled: enableQuery },
    };
}

export function getResearchAggregates(data: any): Array<AggregateItem> {
    return (
        data && data.data ? data.data['insights.research_report.research_type_name'].items : []
    ) as Array<AggregateItem>;
}

function getGICSCode(gics_code: string) {
    return Array(gics_code.length)
        .fill(1)
        .map((_, index) => index)
        .filter((item) => item % 2 === 0 && item > 0)
        .map((item) => gics_code.slice(0, item) + (item === gics_code.length - 2 ? '*' : ''));
}

export function getAvailableResearchTypesForCompany(queryParams: CompanyParams, gics_code: string) {
    let commonBody = getRequestBody('getCompanyHeadlines', queryParams);
    const queryEnabled = queryParams.enableQuery;

    return {
        requestBody: {
            filters: {
                values: {
                    $or: [
                        {
                            ...commonBody.filters.values,
                            'insights.research_report.research_type_id': {
                                values: availableReports.map((report) => report.key as number),
                            },
                        },
                        //Industry Reports
                        {
                            'insights.research_report.research_type_id': {
                                values: [ResearchTypeId.FrsIndustryReport],
                            },
                            'research_report_industry.gics_code': {
                                values: queryEnabled ? getGICSCode(gics_code) : [-1],
                            },
                        },
                    ],
                },
            },
        },
        path: 'research',
        securityType: 'research',
        includeData: true,
        includeMetadata: false,
        filters: 'insights.research_report.research_type_name',
        config: { enabled: queryEnabled },
    };
}

export function getCompanyHeadlines(queryParams: CompanyParams, selectedReports: Array<string>, gics_code: string) {
    const queryEnabled = queryParams.enableQuery;

    let queryReports = availableReports
        .filter((report) => (selectedReports.length > 0 ? selectedReports.includes(report.value) : report))
        .map((report) => report.key as number);

    if (queryReports.length === 0) queryReports.push(-1);
    let commonBody = getRequestBody('getCompanyHeadlines', queryParams);

    return {
        requestBody: {
            filters: {
                values: {
                    $or: [
                        {
                            ...commonBody.filters.values,
                            'insights.research_report.research_type_id': {
                                values: queryReports,
                            },
                        },
                        //Industry Reports
                        {
                            'insights.research_report.research_type_id': {
                                values:
                                    selectedReports.some((report) => report === 'Industry Report') ||
                                    selectedReports.length === 0
                                        ? [ResearchTypeId.FrsIndustryReport]
                                        : [-1],
                            },
                            'research_report_industry.gics_code': {
                                values: queryEnabled ? getGICSCode(gics_code) : [-1],
                            },
                        },
                    ],
                },
            },
        },
        path: 'research/screener',
        view: 'research_hub',
        orderBy: 'research_report.publish_timestamp',
        size: 10,
        sortDirection: 'desc',
        securityType: 'research',
        includeData: true,
        includeMetadata: false,
        config: {
            enabled: queryEnabled,
        },
    } as SearchByParams;
}

export function getIndustryResearch(queryEnabled: boolean, gics_code: string) {
    return {
        requestBody: {
            filters: {
                values: {
                    'insights.research_report.research_type_id': {
                        values: [ResearchTypeId.FrsIndustryReport],
                    },
                    'research_report_industry.gics_code': { values: queryEnabled ? getGICSCode(gics_code) : [-1] },
                },
            },
        },
        path: 'research/screener',
        view: 'industry_headlines',
        orderBy: 'research_report.publish_timestamp',
        sortDirection: 'desc',
        securityType: 'research',
        includeData: true,
        includeMetadata: true,
        config: {
            enabled: queryEnabled,
        },
    } as SearchByParams;
}
