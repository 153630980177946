import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    TypographyStyle1,
    TypographyStyle2,
    TypographyStyle7,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { useLinkGetter } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { ApiNames, RequestTypes, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';
import { fontWeights } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import DailyPacketBanner from 'assets/images/daily_packet_banner.svg';
import { getRequestParamsPropsVariant1, researchLinkGetterParams } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { useCallback, useContext, useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { getCursorVariant1 } from '@cfra-nextgen-frontend/shared/src/utils/cursor';

const dailyPacket = {
    title: 'Daily Packet',
    subTitle: 'Stay Informed with Our Daily Market Insights',
    description:
        'Our research service featuring forensic, financial, and qualitative research, analysis, and opinions on critical market catalysts, including our Biggest Concerns List, Company and Industry Reports, Quantitative Assessments, and Educational Reports.',
    actionBtnText: 'View Daily Packet',
};

const dailyPacketReqParams = {
    path: 'research/screener',
    size: 1,
    view: 'default',
    viewMode: 'standard',
    securityType: 'research',
    requestBody: {
        filters: {
            values: { 'insights.research_report.research_type_id': { values: [ResearchTypeId.FrsDailySnapshot] } },
        },
    },
};

const dailyPacketReqConfig = {
    path: 'research/screener',
    apiName: ApiNames.Research,
    requestType: RequestTypes.POST,
    queryKeyFirstElement: 'dailyPacketScreenerQuery',
};

export function DailyPacketCard() {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { setRequestParamsProps, isLoading, isError } = useLinkGetter(...researchLinkGetterParams);

    const cursor = useMemo(() => getCursorVariant1(isLoading, isError), [isLoading, isError]);

    const dailyPacketScreenerQuery = sendSingleRequest?.(
        dailyPacketReqParams,
        dailyPacketReqConfig,
    ) as UseQueryResult<ScreenerResearchData>;

    const handleLinkClick = useCallback(() => {
        const entityId =  dailyPacketScreenerQuery?.data?.results?.research?.[0]?.id;
        
        setRequestParamsProps(getRequestParamsPropsVariant1(entityId));
    }, [dailyPacketScreenerQuery?.data, setRequestParamsProps]);

    return (
        <ThemeProvider theme={createTheme()}>
            <Box borderRadius='8px' bgcolor='#fff' id='daily-packet-container'>
                <Box component='img' src={DailyPacketBanner} width='100%' height='auto' />
                <Box p='22px' pt='14px'>
                    <TypographyStyle2 fontWeight={fontWeights.Medium} pb='6px'>
                        {dailyPacket.title}
                    </TypographyStyle2>
                    <TypographyStyle1 sx={{ fontSize: '14px', lineHeight: '24px' }}>
                        {dailyPacket.subTitle}
                    </TypographyStyle1>
                    <TypographyStyle7 fontWeight={fontWeights.Regular} lineHeight='22px' p='13px' pl='0'>
                        {dailyPacket.description}
                    </TypographyStyle7>
                    <RoundedTextButton
                        sx={{
                            fontSize: '13px',
                            borderRadius: '10px',
                            padding: '6px 25px',
                            '&:hover': { cursor: cursor },
                        }}
                        theme={roundedTextButtonThemeV3}
                        buttonText={dailyPacket.actionBtnText}
                        isLoading={dailyPacketScreenerQuery?.isLoading}
                        onClickCallback={handleLinkClick}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
}
