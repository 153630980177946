import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { useState, useContext, useRef } from 'react';
import { styled, SxProps } from '@mui/material/styles';
import { ApplicationType, fontFamilies, UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { ETFCard, ItemHeader } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { ETFMenu, ETFDownloadButton, ETFLinkButton } from '@cfra-nextgen-frontend/shared';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { RenameSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/RenameSavedItemModal';
import { DeleteSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/DeleteSavedItemModal';
import { DuplicateSavedItemModal } from '@cfra-nextgen-frontend/shared/src/components/SavedItem/DuplicateSavedItemModal';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { PreferenceType } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { WatchlistSelect, WatchlistRef } from './WatchlistSelect';
import { CreateWatchlist } from './CreateWatchlist';
import { WatchlistCompanies, WatchlistCompaniesProps } from './WatchlistCompanies';
import rename from '@cfra-nextgen-frontend/shared/src/assets/icons/rename.svg';
import remove from '@cfra-nextgen-frontend/shared/src/assets/icons/remove.svg';
import copy from '@cfra-nextgen-frontend/shared/src/assets/icons/copy.svg';
import exportIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/export.svg';
import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';
import { AgGridReact } from 'ag-grid-react';
import { exportAgGrid } from 'components/excelExport/export';
import { UpdateWatchlistBySearch } from './UpdateWatchlistBySearch';
import { AgGridExportProps } from '@cfra-nextgen-frontend/shared/src/components/excelExport/export';
import { Box, ThemeProvider } from '@mui/material';
import { Tooltip } from '@cfra-nextgen-frontend/shared/src/components/dataDisplay';
import { tooltipTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';

const Title = styled(ItemHeader)(({ theme }) => ({
    paddingBottom: '10px',
    color: '#002B5A',
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '18px',
    fontHeight: 500,
    lineHeight: '28px',
    boxShadow: 'none',
}));

type ManageUserWatchlistProps = {
    title: string;
    watchlistCompaniesProps?: WatchlistCompaniesProps;
    containerStyles?: SxProps;
    showExport?: boolean;
    showUpdateWatchlist?: boolean;
    exportProps?: AgGridExportProps;
};

export function ManageUserWatchlist({ title, watchlistCompaniesProps, containerStyles, showExport = false, showUpdateWatchlist = false, exportProps }: ManageUserWatchlistProps) {
    const [selectedWatchlist, setSelectedWatchlist] = useState<SavedItemTypes>();
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [renameWatchlist, setRenameWatchlist] = useState<SavedItemTypes>();
    const [deleteWatchlist, setDeleteWatchlist] = useState<SavedItemTypes>();
    const [duplicateWatchlist, setDuplicateWatchlist] = useState<SavedItemTypes>();
    const { setPreferences } = useUserPreferences({ applicationType: ApplicationType.InstitutionalPortal });
    const watchlistRef = useRef<WatchlistRef>(null);
    const gridRef = useRef<AgGridReact>(null);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const menuItems = [
        {
            itemName: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StyledAvatar src={rename} style={actionMenuItemStyles.menuItemIcon} />
                    <span style={actionMenuItemStyles.menuItemText}>Rename</span>
                </div>
            ),
            callback: () => {
                setRenameWatchlist(selectedWatchlist);
            },
        },
        {
            itemName: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StyledAvatar src={remove} style={actionMenuItemStyles.menuItemIcon} />
                    <span style={actionMenuItemStyles.menuItemText}>Delete</span>
                </div>
            ),
            callback: () => {
                setDeleteWatchlist(selectedWatchlist);
            },
        },
        {
            itemName: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StyledAvatar src={copy} style={actionMenuItemStyles.menuItemIcon} />
                    <span style={actionMenuItemStyles.menuItemText}>Copy</span>
                </div>
            ),
            callback: () => {
                setDuplicateWatchlist(selectedWatchlist);
            },
        },
    ];

    async function refetchAllWatchlist(id?: number) {
        await watchlistRef?.current?.refetchAllWatchlist(id);
        setDeleteWatchlist(undefined);
        setRenameWatchlist(undefined);
        setDuplicateWatchlist(undefined);
    }

    function handleDeleteCancelClick() {
        setDeleteWatchlist(undefined);
    }

    function handleDeleteConfirmClick() {
        setPreferences(PreferenceType.WatchlistLastVisited, undefined);
        refetchAllWatchlist(deleteWatchlist?.id);
    }

    const agGridExportProps: AgGridExportProps = {...{
        fileName: `CFRA ${selectedWatchlist?.name || 'Institutional Watchlist'}`,
        sheetName: 'Data Details',
        metadata: {
            cardName: selectedWatchlist?.name || 'Institutional Watchlist'
        },
        autoSizeColumns: true,
    }, ...exportProps};

    const overlayExportCallback = () => exportAgGrid(gridRef, agGridExportProps);

    return (
        <ETFCard 
            containerStyles={containerStyles}
        >
            <Grid item sm={12} display={'flex'}>
                <Title>{title}</Title>
                {showExport && selectedWatchlist && <Grid display={'flex'} width={'100%'} justifyContent={'flex-end'} alignItems={'center'}>
                    <ETFLinkButton
                        onClick={overlayExportCallback}
                        content={
                            <ThemeProvider theme={tooltipTheme}>
                            <Tooltip title={'Export Watchlist'}>
                                <Box display={'flex'}>
                                    <StyledAvatar src={exportIcon} />
                                    <span style={{
                                        fontSize: '13.5px',
                                        lineHeight: 1.5,
                                        marginLeft: '10px',
                                        color: '#007AB9'
                                    }}>Export</span>
                                </Box>
                            </Tooltip>
                            </ThemeProvider>
                        }
                    />
                </Grid>}
            </Grid>
            <Grid item sm={12} display={'flex'} flexDirection={'row'}>
                <CreateWatchlist onWatchlistCreated={(createdItem) => refetchAllWatchlist(createdItem.id)} />
                <div style={{ marginLeft: 10 }}>
                    <WatchlistSelect
                        setSelectedWatchlist={setSelectedWatchlist}
                        selectedWatchlist={selectedWatchlist}
                        ref={watchlistRef}
                    />
                </div>

                {selectedWatchlist && (<>
                    <WatchlistActionMenu menuItems={menuItems}/>
                    <Grid item display={'flex'} width={'100%'} justifyContent={'flex-end'}>
                        {showUpdateWatchlist && <UpdateWatchlistBySearch selectedWatchlist={selectedWatchlist} onWatchlistUpdated={(createdItem) => refetchAllWatchlist(createdItem.id)} />}
                    </Grid></>
                )}
                {typeof renameWatchlist?.id === 'number' && (
                    <RenameSavedItemModal
                        id={renameWatchlist.id}
                        name={renameWatchlist.name}
                        savedItemType={UserSavedItemsLookupID.InstitutionalWatchlist}
                        modalTitle='Rename Watchlist'
                        saveType='Save WatchList'
                        onCancel={() => setRenameWatchlist(undefined)}
                        onConfirm={() => refetchAllWatchlist(renameWatchlist.id)}
                    />
                )}
                {typeof deleteWatchlist?.id === 'number' && (
                    <DeleteSavedItemModal
                        id={deleteWatchlist.id}
                        name={deleteWatchlist.name}
                        onCancel={handleDeleteCancelClick}
                        onConfirm={handleDeleteConfirmClick}
                    />
                )}
                {typeof duplicateWatchlist?.id === 'number' && (
                    <DuplicateSavedItemModal
                        id={duplicateWatchlist.id}
                        name={duplicateWatchlist.name}
                        savedItemType={UserSavedItemsLookupID.InstitutionalWatchlist}
                        modalTitle='Copy Watchlist'
                        saveType='Save WatchList'
                        onCancel={() => setDuplicateWatchlist(undefined)}
                        onConfirm={(data?: any) => refetchAllWatchlist(data?.id)}
                    />
                )}
            </Grid>
            <Grid item marginTop={2} sm={12}>
                <WatchlistCompanies
                    gridRef={gridRef} 
                    selectedWatchlist={selectedWatchlist} 
                    {...watchlistCompaniesProps}
                />
            </Grid>
        </ETFCard>
    );
}

export const actionMenuItemStyles: Record<string, React.CSSProperties> = {
    menuItemIcon: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    menuItemText: {
        display: 'block',
        whiteSpace: 'normal',
        color: '#3C3C3C',
        lineHeight: '32px',
    },
};


export function WatchlistActionMenu(props: { menuItems: any }) {
    return (
        <div style={{ marginLeft: 20, paddingTop: 10 }}>
            <ETFMenu.ETFMenu
                menuItemStyles={{
                    fontSize: '13px',
                    fontWeight: 400,
                    fontFamily: 'GraphikRegular',
                    padding: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 0,
                    border: 0,
                    width: '100%',
                    '&:hover': {
                        border: 0,
                        backgroundColor: '#E0E0E0',
                    },
                }}
                menuItems={props.menuItems}
                paperProps={{
                    width: '124px',
                }}
                onHoverItemStyles={{
                    backgroundColor: '#E0E0E0',
                }}
                addBorderBetweenItems={false}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                <ETFDownloadButton tooltipText='Watchlist Actions' />
            </ETFMenu.ETFMenu>
        </div>
    );
}