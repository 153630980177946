import { Grid, Stack } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { Button, Typography, ETFCard } from '@cfra-nextgen-frontend/shared';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ComponentProps } from '../types/company';
import { AddToWatchlistComponent } from '@cfra-nextgen-frontend/shared/src/components/Watchlist/AddToWatchlist';
import { WatchListButtonV12Theme, WatchListIconOutline, WatchListIconSelected } from 'components/themes/theme';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { useState } from 'react';

const getStacks = (company_overview: any) => [
    { title: 'Concern \n Level', color: '#F66C61', value: company_overview.frs_company_report?.overall_concern_level },
    { title: 'CFRA \n Earnings Score', color: '#002B5A', value: company_overview.frs_earnings_score_drivers?.decile },
    { title: 'CFRA \n Cash Flow', color: '#609EA0', value: company_overview.frs_cash_flow_score_drivers?.decile },
];

const getFormattedValue = (value: number) => (value === null || value === undefined ? 'NA' : value.toString());

const getStackComponent = (stack: any, index: number) => {
    return (
        <Grid item sx={{ width: '10%' }} key={stack.title}>
            <Stack sx={{ width: '100%' }} key={stack.title}>
                <Typography variant='scoreKeyStyle'>{stack.title}</Typography>
                <Typography
                    variant='scoreValueStyle'
                    paddingTop={'5px'}
                    sx={{
                        color: stack.color,
                        ...(index < 2 ? { borderRight: '1px solid #E0DBDB', marginRight: '20px' } : {}),
                    }}>
                    {getFormattedValue(stack.value)}
                </Typography>
            </Stack>
        </Grid>
    );
};

const getCompanyTickerExchange = (company: any) =>
    company.security_trading.ticker_symbol + ' - ' + company.security_trading.exchange_lid.exchange_code;

export function CompanyOverview({ company, handleLinkClick, reportID }: ComponentProps) {
    const [isWatchListSelected, setWatchListSelected] = useState(false);

    if (company.isLoading) return <ETFCard.ETFCard isLoading={true} />;

    if (company?.data?.results?.company === undefined || company?.data?.results?.company.length === 0)
        return <ETFCard.ETFEmptyCard cardLabel='Company'></ETFCard.ETFEmptyCard>;

    const company_overview = company.data?.results.company[0] as any;
    const company_security = company_overview.company_security;

    const hasConcerns: boolean = company_overview.frs_biggest_concerns_list?.is_biggest_concern;
    const stacks = getStacks(company_overview);

    const iconTheme = isWatchListSelected ? WatchListIconOutline : WatchListIconSelected;
    return (
        <ETFCard.ETFCard>
            <Grid container>
                <Grid item sx={{ width: '70%' }}>
                    <Stack spacing={0.4}>
                        <Box>
                            <AddToWatchlistComponent
                                watchListData={[
                                    {
                                        cfra_company_id: company_security.company.cfra_company_id,
                                        cfra_security_trading_id:
                                            company_security.security_trading.cfra_security_trading_id,
                                        composite_name: company_security.company.company_name,
                                        id:
                                            company_security.company.cfra_company_id ||
                                            company_security.security_trading.cfra_security_trading_id,
                                    },
                                ]}
                                savedItemType={UserSavedItemsLookupID.InstitutionalWatchlist}
                                buttonTheme={WatchListButtonV12Theme}
                                buttonText='Watchlist'
                                customIconTheme={iconTheme}
                                tooltipText='Add company to a watchlist'
                                isWatchListSelected={isWatchListSelected}
                                setWatchListSelected={setWatchListSelected}
                            />
                        </Box>
                        <Box>
                            <Typography variant='cardCaption'>
                                {getCompanyTickerExchange(company_overview['company_security'] as any)}
                            </Typography>
                            <Typography variant='captionSep'>{'  |  '}</Typography>
                            <Typography variant='cardCaption'>
                                {company_overview['company_security'].company.company_name}
                            </Typography>
                        </Box>

                        {hasConcerns && (
                            <Box>
                                <Button
                                    variant='concerns'
                                    onClick={() => {
                                        if (handleLinkClick) {
                                            handleLinkClick(reportID || '');
                                        }
                                    }}>
                                    biggest Concern
                                </Button>
                            </Box>
                        )}
                    </Stack>
                </Grid>
                {stacks.map((stack, index) => getStackComponent(stack, index))}
            </Grid>
        </ETFCard.ETFCard>
    );
}
