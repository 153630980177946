import { SharedTopNavigation } from '@cfra-nextgen-frontend/shared/src/components/TopNavigation/SharedTopNavigation';
import { getOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer';
import { ShowHideStrategies } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { UserEntitlementsResponseData } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';
import { determineGetCompanySuggesterOptions } from 'features/topNavigation/companySuggester';
import { determineGetResearchSuggesterOptions } from 'features/topNavigation/researchSuggester';
import { topNavItems } from 'features/topNavigation/topNavItems';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { PageNames, PageNamesToRoutePaths } from 'utils/enums';
import { maxPageWidthInPx } from 'utils/lookAndFeel';

const companySuggesterSize = 25;
const researchSuggesterSize = 10;

export function TopNavigation({ userEntitlements }: { userEntitlements?: UserEntitlementsResponseData }) {
    const memoisedTopNavItems = useMemo(() => {
        const topNavItemCopy = cloneDeep(topNavItems)
        Object.keys(topNavItemCopy).forEach((key) => {
            const topNavItem = topNavItemCopy[key];
            if (topNavItem.shouldHideItem?.(userEntitlements)) {
                delete topNavItemCopy[key]; // Enitre Object (top level) to delete
            } else {
                // Check sub links
                const newlinks = topNavItem.popup?.links.filter((link) => {
                    return !link.shouldHidelink?.(userEntitlements)
                })
                if (topNavItemCopy[key].popup && newlinks && newlinks.length > 0) {
                    topNavItemCopy[key].popup = {
                        title: topNavItemCopy[key].popup?.title || '',
                        description: topNavItemCopy[key].popup?.description || '',
                        links_title: topNavItemCopy[key].popup?.links_title || '',
                        links: newlinks
                    }
                }
            }
        })
        return topNavItemCopy;
    }, [userEntitlements]);
    
    
    return (
        <SharedTopNavigation
            maxWidth={maxPageWidthInPx}
            topNavItems={memoisedTopNavItems}
            typeSearchProps={{
                showHideStrategy: ShowHideStrategies.UseShowSearchInput,
                topLinkParams: {
                    text: `Go to ${PageNames.ResearchHub}`,
                    pathname: PageNamesToRoutePaths[PageNames.ResearchHub],
                },
                getLeftOptions: determineGetCompanySuggesterOptions({
                    size: companySuggesterSize,
                    getOptionsContainer,
                }),
                getRightOptions: determineGetResearchSuggesterOptions({
                    size: researchSuggesterSize,
                    getOptionsContainer
                }),
                titles: {
                    left: 'Companies',
                    right: 'Research',
                },
            }}
        />
    );
}
