import { useEffect, useState } from 'react';

type UseCheckOverflowInputProps = {
    onOverflowCallback: () => void;
    onNoOverflowCallback?: () => void;
};

type UseCheckOverflowOutputProps<Cont extends HTMLElement, Elem extends HTMLElement> = {
    setContainer: (node: Cont | null) => void;
    setElement: (node: Elem | null) => void;
};

export function useCheckOverflow<Cont extends HTMLElement, Elem extends HTMLElement>({
    onOverflowCallback,
    onNoOverflowCallback,
}: UseCheckOverflowInputProps): UseCheckOverflowOutputProps<Cont, Elem> {
    const [container, setContainer] = useState<Cont | null>(null);
    const [element, setElement] = useState<Elem | null>(null);

    useEffect(() => {
        if (!container || !element) {
            return;
        }

        const containerRect = container.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();

        const isOverflowed =
            elementRect.right > containerRect.right ||
            elementRect.left < containerRect.left ||
            elementRect.bottom > containerRect.bottom ||
            elementRect.top < containerRect.top;

        if (isOverflowed) {
            onOverflowCallback();
            return;
        }

        onNoOverflowCallback?.();
    }, [container, element, onOverflowCallback, onNoOverflowCallback]);

    return { setContainer, setElement };
}
