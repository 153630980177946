import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    SectionHeaderRow,
    SectionHeaderRowProps,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/SectionHeader';
import { Box, SxProps } from '@mui/material';
import { forwardRef } from 'react';

export type TitleWithScrollableAreaProps = {
    children: React.ReactNode;
    containerSx?: SxProps;
    scrollableAreaContainerSx?: SxProps;
    scrollableAreaContainerWrapperSx?: SxProps;
    titleProps?: SectionHeaderRowProps;
    titleNode?: React.ReactNode;
    setOuterContainerRef: (node: HTMLDivElement) => void;
};

export const TitleWithScrollableArea = forwardRef<HTMLDivElement, TitleWithScrollableAreaProps>(
    (
        {
            children,
            containerSx,
            scrollableAreaContainerSx,
            scrollableAreaContainerWrapperSx,
            titleProps,
            titleNode,
            setOuterContainerRef,
        },
        ref,
    ) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    ...containerSx,
                }}>
                {titleProps && <SectionHeaderRow {...titleProps} />}
                {titleNode}
                <Box
                    ref={setOuterContainerRef}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100% - 45px)',
                        ...scrollableAreaContainerWrapperSx,
                    }}>
                    {/* Scrollable Area */}
                    <Box
                        ref={ref}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            maxHeight: '100%',
                            overflowY: 'auto',
                            alignItems: 'flex-start',
                            ...scrollbarThemeV3,
                            ...scrollableAreaContainerSx,
                        }}>
                        {children}
                    </Box>
                </Box>
            </Box>
        );
    },
);
