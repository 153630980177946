import { fontFamilies, fontWeights } from "@cfra-nextgen-frontend/shared/src/utils";
import { createTheme } from "@mui/material";

const defaultButtonTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '87px',
                    height: '48px',
                    fontFamily: fontFamilies.GraphikRegular,
                    borderRadius: '8px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    fontSize: '13.5px',
                    lineHeight: '36px',
                    fontWeight: fontWeights.Medium,
                    textTransform: 'none',
                    '&:disabled': {
                        border: 'none',
                        backgroundColor: '#9E9E9E',
                        color: '#FFF',
                    }
                }
            }
        }
    }
})

export const nextButtonTheme = createTheme(defaultButtonTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',
                    backgroundColor: '#002B5A',
                    borderColor: '#002B5A',
                    '&:hover': {
                        backgroundColor: '#007AB9',
                        borderColor: '#007AB9',
                    }
                }
            }
        }
    }
});


export const cancelButtonTheme = createTheme(defaultButtonTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#002B5A',
                    backgroundColor: '#FFFFFF',
                    borderColor: '#CCCCCC',
                    '&:hover': {
                        backgroundColor: '#007AB9',
                        borderColor: '#3078B5',
                    }
                }
            }
        }
    }
});


export const tabTheme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    height: '46px',
                    padding: '10px 10px 12px 10px',
                    borderColor: '#F3F5FC',
                    backgroundColor: '#F3F5FC',
                    borderWidth: '1px 1px 0 1px',
                    borderStyle: 'solid',
                    color: '#5A5A5A',
                    borderRadius: '10px 10px 0 0',
                    fontFamily: fontFamilies.GraphikMedium,
                    fontSize: '13.5px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    textAlign: 'left',
                    marginRight: 0,
                    '&.Mui-selected': {
                        borderColor: '#CCCCCC',
                        backgroundColor: '#FFFFFF',
                        color: '#5A5A5A',
                    },
                    '&:last-child': {
                        color: '#F66C61 !important',
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid #CCCCCC'
                },
                indicator: {
                    height: '1px',
                    backgroundColor: '#FFF'
                },
                root: {
                    marginBottom: '10px'
                }
            }
        }
    }
})