import watchlist_home from '@cfra-nextgen-frontend/cfra-institutional/src/assets/images/watchlist_home.png';
import { Layout, Typography } from '@cfra-nextgen-frontend/shared';
import { Grid, Stack } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SavedItemListResponseTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { fontFamilies, fontWeights, UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { getSavedItemsByTypeConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import Fade from '@mui/material/Fade';
import { ThemeProvider } from '@mui/material/styles';
import { homePageTheme } from 'components/themes/theme';
import { CreateWatchlist } from 'components/watchlists/CreateWatchlist';
import { useContext, useEffect, useState } from 'react';

type ShowSkeletonProps = {
    onFalseComponent: React.ReactNode;
};

export function ShowSplaceScreen({ onFalseComponent }: ShowSkeletonProps) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getAllWatchlistQry = sendSingleRequest<SavedItemListResponseTypes>(
        { types: [UserSavedItemsLookupID.InstitutionalWatchlist] },
        getSavedItemsByTypeConfig,
    );

    const [showSplaceScreen, setShowSplashScreen] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (
            getAllWatchlistQry &&
            !getAllWatchlistQry.isRefetching &&
            !getAllWatchlistQry.isError &&
            getAllWatchlistQry?.data?.data
        ) {
            if (getAllWatchlistQry?.data?.data?.length === 0) {
                setShowSplashScreen(true);
            } else {
                setShowSplashScreen(false);
            }
        }
    }, [getAllWatchlistQry]);

    return (
        <ThemeProvider theme={homePageTheme}>
            <Grid container>
                <Grid item sm={12}>
                    {showSplaceScreen === true && (
                        <Fade in={showSplaceScreen}>
                            <Grid item style={styles.splashContainer}>
                                <Grid
                                    item
                                    sm={10}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    height={'100%'}
                                    flexDirection={'column'}
                                    paddingLeft={20}>
                                    <Stack>
                                        <Typography variant='h2' textAlign={'left'}>
                                            Start Tracking
                                        </Typography>
                                        <Typography variant='h2' textAlign={'left'}>
                                            What Matters to You
                                        </Typography>
                                        <Typography style={styles.subtitle} textAlign={'left'} marginTop={2}>
                                            Create your first <b>watchlist</b> to keep an eye on market trends,
                                        </Typography>
                                        <Typography style={styles.subtitle} textAlign={'left'}>
                                            monitor your favorite companies, and stay informed.
                                        </Typography>
                                    </Stack>
                                    <Grid display={'flex'} flexDirection={'row'} marginTop={3}>
                                        <div style={{ margin: '0px 18px 4px 0' }}>
                                            <CreateWatchlist />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fade>
                    )}
                    {showSplaceScreen === false && (
                        <Grid item sm={12}>
                            {onFalseComponent}
                        </Grid>
                    )}
                    {showSplaceScreen === undefined && <Layout.Skeleton height='10px' />}
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

const styles = {
    splashContainer: {
        transition: 'ease 0.5s',
        backgroundImage: `url(${watchlist_home})`,
        height: 'calc(100vh - 190px)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '20px',
    },
    subtitle: {
        fontSize: '22px',
        fontWeight: fontWeights.Medium,
        fontFamily: fontFamilies.GraphikRegular,
        color: '#5D849B',
    },
};
