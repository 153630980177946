import { InfiniteOptionsRef } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import { SearchInputVariant1 } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant1';
import { SearchInputProps } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/types';
import {
    TitleWithScrollableAreaProps
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TitleWithScrollableArea';
import { useClickableAreas } from '@cfra-nextgen-frontend/shared/src/hooks/useClickableAreas';
import { Box, Popper, SxProps } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TitleWithLink } from './TitleWithLink';

export type GetOptionsBaseProps = {
    inputValue: string;
    resetOptionsRef?: React.MutableRefObject<InfiniteOptionsRef | null>;
    onLinkClickCallback?: () => void;
    setThirdAreaRef?: (el: HTMLDivElement | null) => void;
}

export type GetOptionsComponentProps = GetOptionsBaseProps & {
    titleWithScrollableAreaProps: Omit<TitleWithScrollableAreaProps, 'children'>;
    outerContainerRef?: React.RefObject<HTMLDivElement>;
};

export type GetDefaultOptionsComponentProps = {
    onLinkClickCallback?: () => void;
};

export enum ShowHideStrategies {
    /*
        useShowSearchInput:
        - show/hide search input and search results container based on showSearchInput prop
        - automatically fill search input on reopen
        - automatically focus search input on open
        - use onClickOutsideCallback to hide search input and search results container via changing showSearchInput prop outside of the component
    */
    UseShowSearchInput = 'useShowSearchInput',
    /*
        useShowResultsContainer:
        - show/hide search results container based on showResultsContainer state variable
        - use setShowResultsContainer to hide search results container
        - show results container on click or on input change
        - hide search results container on click outside
        - close search results container on link click
        - hide results container on press enter
    */
    UseShowResultsContainer = 'useShowResultsContainer',
}

export type TypeSearchProps = {
    showSearchInput: boolean;
    showHideStrategy: ShowHideStrategies;
    onClickOutsideCallback?: () => void;
    topLinkParams?: {
        text: string;
        pathname: string;
    };
    getLeftOptions: (props: GetOptionsComponentProps) => React.ReactNode;
    getRightOptions: (props: GetOptionsComponentProps) => React.ReactNode;
    SearchInputComponent?: React.FC<SearchInputProps>;
    searchInputComponentProps?: SearchInputProps;
    offset?: [number, number];
    contextBoxStyles?: SxProps;
    outerOnInputKeyDownCallback?: React.KeyboardEventHandler<HTMLInputElement>;
    titles?: {
        left?: string;
        right?: string;
    };
    titleNodes?: {
        left?: React.ReactNode;
        right?: React.ReactNode;
    };
};

export function TypeSearch({
    showSearchInput,
    showHideStrategy,
    onClickOutsideCallback,
    topLinkParams,
    getLeftOptions,
    getRightOptions,
    SearchInputComponent = SearchInputVariant1,
    searchInputComponentProps,
    offset = [-24, 26],
    contextBoxStyles,
    outerOnInputKeyDownCallback,
    titles,
    titleNodes,
}: TypeSearchProps) {
    const [inputValue, setInputValue] = useState<string>('');
    const [inputNode, setInputNode] = useState<HTMLInputElement | null>(null);
    const [showResultsContainer, setShowResultsContainer] = useState<boolean>(true);

    const { clickableAreasRefs } = useClickableAreas<HTMLInputElement | HTMLDivElement>({
        clickOutsideCallback: () => {
            switch (showHideStrategy) {
                case ShowHideStrategies.UseShowSearchInput:
                    if (!showSearchInput) {
                        return;
                    }
                    onClickOutsideCallback?.();
                    break;
                case ShowHideStrategies.UseShowResultsContainer:
                    setShowResultsContainer((currentValue) => {
                        if (!currentValue) {
                            return currentValue;
                        }
                        return false;
                    });
                    break;
            }
        },
        numberOfAreas: 3,
    });

    const setFirstAreaRef = useCallback(
        (el: HTMLDivElement | null) => {
            clickableAreasRefs.current[0] = el;
        },
        [clickableAreasRefs],
    );

    const setSecondAreaRef = useCallback(
        (el: HTMLDivElement | null) => {
            clickableAreasRefs.current[1] = el;
        },
        [clickableAreasRefs],
    );
    const setThirdAreaRef = useCallback(
        (el: HTMLDivElement | null) => {
            clickableAreasRefs.current[2] = el;
        },
        [clickableAreasRefs],
    );

    useEffect(() => {
        if (!showSearchInput || !inputNode || showHideStrategy !== ShowHideStrategies.UseShowSearchInput) {
            return;
        }

        // automatically fill search input on reopen when ShowHideStrategies.UseShowSearchInput strategy
        inputNode.value = inputValue;

        // automatically focus search input on open
        inputNode.focus();
    }, [showSearchInput, inputValue, inputNode, showHideStrategy]);

    const leftOptionsOuterContainerRef = useRef<HTMLDivElement | null>(null);
    const rightOptionsOuterContainerRef = useRef<HTMLDivElement | null>(null);

    const setLeftOptionsOuterContainerRef = useCallback(
        (node?: HTMLDivElement | null) => {
            if (node === undefined) {
                return;
            }

            leftOptionsOuterContainerRef.current = node;
        },
        [leftOptionsOuterContainerRef],
    );

    const setRightOptionsOuterContainerRef = useCallback(
        (node?: HTMLDivElement | null) => {
            if (node === undefined) {
                return;
            }

            rightOptionsOuterContainerRef.current = node;
        },
        [rightOptionsOuterContainerRef],
    );

    const debouncedSetInputValueHandler = useMemo(
        () =>
            debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setInputValue(event.target.value);
            }, 200),
        [],
    );

    const leftOptions = useMemo(() => {
        return getLeftOptions?.({
            outerContainerRef: leftOptionsOuterContainerRef,
            inputValue,
            onLinkClickCallback: () => {
                onClickOutsideCallback?.();
                if (showHideStrategy === ShowHideStrategies.UseShowResultsContainer) {
                    setShowResultsContainer(false);
                }
            },
            titleWithScrollableAreaProps: {
                containerSx: {
                    width: '335px',
                    maxWidth: '335px',
                },
                titleNode: titleNodes?.left,
                titleProps: titles?.left
                    ? {
                          text: titles.left,
                          sx: { paddingLeft: '20px' },
                      }
                    : undefined,
                scrollableAreaContainerWrapperSx: {
                    width: '335px',
                    maxWidth: '335px',
                    borderRight: '1px solid #CCCCCC',
                    paddingRight: '12px',
                },
                scrollableAreaContainerSx: {
                    padding: '0px 12px',
                },
                setOuterContainerRef: setLeftOptionsOuterContainerRef,
            },
        });
    }, [
        inputValue,
        getLeftOptions,
        onClickOutsideCallback,
        showHideStrategy,
        titleNodes?.left,
        titles?.left,
        setLeftOptionsOuterContainerRef,
    ]);

    const rightOptions = useMemo(() => {
        return getRightOptions?.({
            outerContainerRef: rightOptionsOuterContainerRef,
            inputValue,
            onLinkClickCallback: () => {
                if (showHideStrategy === ShowHideStrategies.UseShowResultsContainer) {
                    setShowResultsContainer(false);
                }
            },
            setThirdAreaRef,
            titleWithScrollableAreaProps: {
                containerSx: {
                    flex: 1,
                },
                scrollableAreaContainerSx: {
                    padding: '0px 15px 0px 18px',
                },
                titleNode: titleNodes?.right,
                titleProps: titles?.right
                    ? {
                          text: titles.right,
                          sx: { paddingLeft: '35px' },
                      }
                    : undefined,
                setOuterContainerRef: setRightOptionsOuterContainerRef,
            },
        });
    }, [
        inputValue,
        getRightOptions,
        showHideStrategy,
        setThirdAreaRef,
        titleNodes?.right,
        titles?.right,
        setRightOptionsOuterContainerRef,
    ]);

    if (!showSearchInput && showHideStrategy === ShowHideStrategies.UseShowSearchInput) {
        // hide input and search results when ShowHideStrategies.UseShowSearchInput strategy and showSearchInput is false
        return null;
    }

    return (
        <>
            <SearchInputComponent
                clearButtonCallback={() => setInputValue('')}
                onChange={(event) => {
                    setShowResultsContainer(true);
                    debouncedSetInputValueHandler(event);
                }}
                onKeyDown={(event) => {
                    outerOnInputKeyDownCallback?.(event);

                    if (event.key !== 'Enter' || showHideStrategy !== ShowHideStrategies.UseShowResultsContainer) {
                        return;
                    }

                    setShowResultsContainer(false);
                }}
                onClick={() => setShowResultsContainer(true)}
                showInput
                textFieldRefCallback={(node) => setInputNode(node)}
                wrapperRefCallback={setFirstAreaRef}
                {...searchInputComponentProps}
            />
            {/* Search results block */}
            {inputNode && showResultsContainer && inputValue && (
                <Popper
                    ref={setSecondAreaRef}
                    open
                    anchorEl={inputNode}
                    style={{
                        zIndex: 3000,
                    }}
                    placement='bottom-start'
                    modifiers={[{ name: 'offset', options: { offset } }]}>
                    {({ TransitionProps }) => (
                        <Box
                            {...TransitionProps}
                            sx={{
                                display: 'block',
                                border: '1px solid #ddd',
                                backgroundColor: '#fff',
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: '4px',
                                padding: '22px 16px',
                                width: '1000px',
                                height: '713px',
                                maxHeight: 'calc(95vh - 75px)',
                                ...contextBoxStyles,
                            }}>
                            <TitleWithLink
                                title='Search Results'
                                linkProps={{
                                    linkText: topLinkParams?.text || '',
                                    onClick: () => onClickOutsideCallback?.(),
                                    state: { searchTerm: inputValue },
                                    to: {
                                        pathname: topLinkParams?.pathname || '',
                                    },
                                }}
                            />
                            <Box sx={{ display: 'flex', width: '100%', height: 'calc(100% - 45px)' }}>
                                {/* Left sub block */}
                                {leftOptions}
                                {/* Right sub block */}
                                {rightOptions}
                            </Box>
                        </Box>
                    )}
                </Popper>
            )}
        </>
    );
}
