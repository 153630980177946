import { InformationPopup } from '@cfra-nextgen-frontend/shared/src/components/InformationPopup/InformationPopup';
import { scrollbarThemeV3, tooltipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Box } from '@mui/material';

export function SearchSyntaxInfo(props: { buttonFontSize: number; analyticsOpenModalProps?: AnalyticsActionProps }) {
    const title = 'Search Syntax';
    const description = (
        <Box
            sx={{
                height: 'calc(85vh - 170px)',
                overflow: 'auto',
                paddingRight: '10px',
                ...scrollbarThemeV3,
            }}>
            <h2>Company Search</h2>
            <p>Search for a company by either company name, stock ticker or other identifier such as CUSIP or ISIN</p>
            <h2>Keyword/Phrase Searches</h2>
            <h3>Terms</h3>
            <p>
                A query is broken up into terms and operators. There are two types of terms: Single Terms and Phrases.
            </p>
            <p>A Single Term is a single word such as "volatility" or "ESG".</p>
            <p>A Phrase is a group of words surrounded by double quotes such as "market volatility".</p>
            <p>Multiple terms can be combined with Boolean operators to form a more complex query</p>
            <h3>Wildcard</h3>
            <p>
                Search single and multiple character wildcard searches within single terms (not within phrase queries).
            </p>
            <p>To perform a single character wildcard search, use the "?" symbol.</p>
            <p>To perform a multiple character wildcard search, use the "*" symbol.</p>
            <p>
                The single character wildcard search looks for terms that match that with the single character replaced.
                For example, to search for "text" or "test" you can use the search:
            </p>
            <p>te?t</p>
            <p>
                Multiple character wildcard searches looks for 0 or more characters. For example, to search for test,
                tests or tester, you can use the search: test*
            </p>
            <p>You can also use the wildcard searches in the middle of a term. te*t</p>
            <h3>Fuzzy</h3>
            <p>
                do a fuzzy search use the tilde, "~", symbol at the end of a Single word Term. For example, to search
                for a term similar in spelling to "roam" use the fuzzy search: <b>roam~</b>
            </p>
            <p>This search will find terms like foam and roams.</p>
            <h3>Proximity Searches</h3>
            <p>Find words that are within a specific distance away. To do a proximity search use the tilde, "~", symbol at the end of a Phrase. For example, to search for a " divestiture" and "guidance" within 10 words of each other in a document use the search: <b>"divestiture guidance"~10</b></p>
            <h3>Proximity Searches</h3>
            <h2>Boolean Operators</h2>
            <p>Boolean operators allow terms to be combined through logic operators. Search supports AND, "+", OR, NOT and "-" as Boolean operators (Note: Boolean operators must be ALL CAPS).</p>
            <p>The OR operator is the default conjunction operator. This means that if there is no Boolean operator between two terms, the OR operator is used. The OR operator links two terms and finds a matching document if either of the terms exist in a document. The symbol || can be used in place of the word OR.</p>
            <p>To search for documents that contain either " guidance divestiture" or just "guidance" use the query:</p>
            <p>"guidance divestiture" guidance</p>
            <p>or</p>
            <p>"guidance divestiture" OR guidance</p>
            <p><b>AND</b></p>
            <p>The AND operator matches documents where both terms exist anywhere in the text of a single document. The symbol && can be used in place of the word AND.</p>
            <p>To search for documents that contain "guidance divestiture" and "divestiture volatility " use the query:</p>
            <p>"guidance divestiture" AND "divestiture volatility"</p>
            <p><b>+</b></p>
            <p>The "+" or required operator requires that the term after the "+" symbol exist somewhere in the field of a single document.</p>
            <p>To search for documents that must contain " guidance " and may contain "volatility" use the query:</p>
            <p>+ guidance volatility</p>
            <p><b>NOT</b></p>
            <p>The NOT operator excludes documents that contain the term after NOT. This is equivalent to a difference using sets. The symbol ! can be used in place of the word NOT.</p>
            <p>To search for documents that contain " guidance divestiture" but not "divestiture volatility" use the query: "guidance divestiture" NOT "divestiture volatility"</p>
            <p>Note: The NOT operator cannot be used with just one term. For example, the following search will return no results:</p>
            <p>NOT "guidance divestiture"</p>
            <p><b>-</b></p>
            <p>The "-" or prohibit operator excludes documents that contain the term after the "-" symbol.</p>
            <p>To search for documents that contain "guidance divestiture" but not "divestiture volatility" use the query:</p>
            <p>"guidance divestiture" -"Divestiture volatility"</p>
            <h3>Grouping</h3>
            <p>Use parentheses to group clauses to form sub queries. This can be very useful if you want to control the boolean logic for a query.</p>
            <p>To search for either "guidance" or " divestiture" and "website" use the query:</p>
            <p>(guidance OR divestiture) AND website</p>
            <p>This eliminates any confusion and makes sure you that website must exist and either term guidance or divestiture may exist.</p>
        </Box>
    );

    return (
        <InformationPopup
            popupTitle={title}
            titleStyle={{ boxShadow: 'none' }}
            tooltipTheme={tooltipThemeV2}
            descriptionComponent={description}
            tooltipText={title}
            {...props}
        />
    );
}
