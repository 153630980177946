import {
    TitleWithScrollableArea,
    TitleWithScrollableAreaProps,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TitleWithScrollableArea';
import { forwardRef } from 'react';
import { GetOptionsBlankContainer, GetOptionsContainer } from './types';

export const getOptionsContainer: GetOptionsContainer = (props: Omit<TitleWithScrollableAreaProps, 'children'>) =>
    forwardRef<HTMLDivElement, { children: React.ReactNode }>(({ children }, ref) => {
        return (
            <TitleWithScrollableArea ref={ref} {...props}>
                {children}
            </TitleWithScrollableArea>
        );
    });

export const getOptionsBlankContainer: GetOptionsBlankContainer = () =>
    forwardRef<HTMLDivElement, { children: React.ReactNode }>(({ children }, ref) => {
        return (
            <>
                {children}
            </>
        );
    });
