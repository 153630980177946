import { Tab, styled } from '@mui/material';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';

interface StyledTabProps {
    label: string | JSX.Element;
    value: string | number;
    onClick?: React.MouseEventHandler;
}

export const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ theme }) => ({
    minHeight: 'unset',
    padding: '8px 15px',
    marginRight: '4px',
    fontSize: '13px',
    lineHeight: '20px',
    fontFamily: fontFamilies.GraphikMedium,
    fontWeight: fontWeights.Medium,
    textTransform: 'none',
    color: '#002B5A',
    backgroundColor: '#F6F7FB',
    borderRadius: '10px 10px 0 0',
    '&.Mui-selected': {
        color: '#3078B5',
        backgroundColor: '#FFF',
    },
}));

