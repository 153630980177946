import { RoundedTextButton } from "@cfra-nextgen-frontend/shared";
import { Grid } from "@mui/material";
import { CompanySearchCell } from "components/companySearch";
import { useCallback, useImperativeHandle, useRef, useState } from "react";
import { SearchInputVariant1 } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant1';
import { AutoSuggestCompany, Hit } from "@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch";
import React from "react";
import { CompanyChipList } from "components/CompanyChipList";
import { nextButtonTheme } from "../theme";


export type CompanySearchModalRef = {
    shouldShowExitForm: () => boolean
}

const CompanySearchModalContent = React.forwardRef<any, any>(({ setCreateWatchlistData }, ref) => {
    const [allSelectedSymbols, setAllSelectedSymbols] = useState<Hit<AutoSuggestCompany>[]>([]);

    const shouldShowExitForm = () => allSelectedSymbols.length > 0 ? true : false;

    useImperativeHandle(ref, () => ({
        shouldShowExitForm
    }));

    const createWatchlistData = useCallback(() => {
        if (allSelectedSymbols?.length) {
            setCreateWatchlistData(allSelectedSymbols.map((data) => ({
                "cfra_security_trading_id": data._source["company_security.security_trading.cfra_security_trading_id"]?.toString(),
                "cfra_company_id": data._source["company_security.company.cfra_company_id"]?.toString()
            })));
        }
    }, [allSelectedSymbols, setCreateWatchlistData]);

    const onChipDeleted = (chipItems: any[], key: string) => () => {
        setAllSelectedSymbols(allSelectedSymbols.filter(p => !(p._source["company_security.security_trading.cfra_security_trading_id"] === key 
                || p._source["company_security.company.cfra_company_id"] === key)));
    }

    return (<>
        <Grid container direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            rowSpacing={2}
            marginTop={1}>
            <Grid item width={'100%'}>
                <CompanySearchCell 
                    SearchInputComponent={SearchInputVariant1} 
                    searchInputComponentProps={{ placeholder: 'Search for Company' }}
                    onResultOptionClick={(rowIndex: string, rowData: Hit<AutoSuggestCompany>) => {
                        const isAlreadyPresent = allSelectedSymbols?.findIndex((sym) => {
                            return (
                                sym._source["company_security.security_trading.cfra_security_trading_id"] === rowData._source["company_security.security_trading.cfra_security_trading_id"] || 
                                (!rowData._source["company_security.security_trading.cfra_security_trading_id"] && sym._source["company_security.company.cfra_company_id"] === rowData._source["company_security.company.cfra_company_id"])
                            ) ? true : false
                        }) > -1;

                        if (!isAlreadyPresent)
                            setAllSelectedSymbols([...allSelectedSymbols, rowData])
                    }}
                    clearInputOnSelection={true}
                />
            </Grid>

            <Grid item container direction="row" minHeight={'50px'}>
                <CompanyChipList chipItems={allSelectedSymbols} onChipDeleteClick={onChipDeleted}></CompanyChipList>
            </Grid>
            <Grid item alignSelf={'center'}>
                <Grid sx={{
                    width: '200px'
                }}>
                    <RoundedTextButton buttonText="Create" theme={nextButtonTheme} sx={{ marginLeft: '10px' }} disabled={allSelectedSymbols?.length === 0} onClickCallback={createWatchlistData} />
                </Grid>
            </Grid>
        </Grid>
    </>)
})

export const NewWatchlistCompanySearchModal = React.forwardRef<CompanySearchModalRef, { setCreateWatchlistData: (data: { cfra_security_trading_id: string; cfra_company_id: string; }[]) => void }>(({ setCreateWatchlistData }, ref) => {
    const companySearchContentRef = useRef<any>(null);

    const shouldShowExitForm = () => companySearchContentRef?.current?.shouldShowExitForm ? companySearchContentRef?.current?.shouldShowExitForm() : true;

    useImperativeHandle(ref, () => ({
        shouldShowExitForm
    }));

    return (
        <CompanySearchModalContent setCreateWatchlistData={setCreateWatchlistData} ref={companySearchContentRef} />
    )
})