import { AgGirdCard } from "@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard";
import { AgGridSelectedRowsContextProvider } from "@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext";
import DividerStyle1 from "@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers"
import { ProjectSpecificResourcesContext } from "@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context";
import { SavedItemTypes } from "@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem";
import { CreateSavedItemResponseTypes } from "@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens";
import { ScreenerResearchCompanyData } from "@cfra-nextgen-frontend/shared/src/components/Screener/types/screener";
import { TypographyStyle13, TypographyStyle14 } from "@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography"
import { ApiNames, RequestTypes } from "@cfra-nextgen-frontend/shared/src/utils";
import { getFiltersReqBody } from "@cfra-nextgen-frontend/shared/src/utils/api";
import { getSavedItemsByIdConfig, updateSavedItemByIdConfig } from "@cfra-nextgen-frontend/shared/src/utils/userSavedItem";
import { Grid } from "@mui/material"
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UseQueryResult } from "react-query";
import { AgGridReact } from 'ag-grid-react';
import { extractFromScreenerData } from "@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs";
import { defaultMinWidth } from "@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid";
import { isEqual } from "lodash";
import { WatchListColumn } from "@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListUtils";
import { watchListColumnWidth } from "@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants";
import { ITooltipParams } from 'ag-grid-community';
import { DefaultCFRASnack, Layout } from "@cfra-nextgen-frontend/shared";
import { useSnackbar } from 'notistack';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/GridThemeV2.scss';
import { SnackMessageForm } from "@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm";
import { getCellRendererValueProcessor } from "components/AgGrid/renderers";

function StyledGridItem({ children }: { children: React.ReactNode }) {
    return (
        <Grid
            item
            container
            sx={{
                height: '46px',
                alignItems: 'center',
                paddingBottom: '8px',
            }}
            gap='8px'
            xs='auto'>
            {children}
        </Grid>
    );
}

function TextRowVariant1({ boldText, normalText }: {
    boldText?: string;
    normalText?: string;
}) {
    return (
        <Grid container sx={{ justifyContent: 'space-between' }}>
            <StyledGridItem>
                <TypographyStyle13>{boldText}</TypographyStyle13>
                <DividerStyle1 />
                <TypographyStyle14>{normalText}</TypographyStyle14>
            </StyledGridItem>
        </Grid>
    )
}

export type WatchlistCompaniesProps = {
    selectedWatchlist?: SavedItemTypes
    view?: string;
    addWatchListStarColumn?: boolean;
    getNumberOfResultsText?: (numberOfResults?: number) => string;
    gridRef?: React.RefObject<AgGridReact>
}

export function WatchlistCompanies({ selectedWatchlist, view, addWatchListStarColumn, getNumberOfResultsText, gridRef }: WatchlistCompaniesProps) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [secTradingIdsFilter, setSecTradingIdsFilter] = useState<any>();
    const [updatedWatchlistData, setUpdatedWatchlistData] = useState();
    const [removingCompanyData, setRemovingCompanyData] = useState<any>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);
    
    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getWatchlistValueQry = sendSingleRequest<CreateSavedItemResponseTypes>({
        savedItems: selectedWatchlist?.id || 0,
        includeValues: true,
        config: { enabled: (selectedWatchlist?.id && selectedWatchlist?.id > 0) ? true : false }
    }, getSavedItemsByIdConfig);

    useEffect(() => {
        if (
            getWatchlistValueQry &&
            !getWatchlistValueQry.isRefetching &&
            !getWatchlistValueQry.isError &&
            getWatchlistValueQry?.data?.data
        ) {
            const value = getWatchlistValueQry.data.data.value as any;
            if (value?.values?.length > 0) {

                const cfraSecTradingIds = value.values.filter((p: any) => p.cfra_security_trading_id).map(
                    (p: any) => `company_security.security_trading.cfra_security_trading_id_|_${p.cfra_security_trading_id}`
                );

                // If security trading id is not present then only fetch company id
                const cfraCompanyIds = value.values.filter((p: any) => p.cfra_company_id && !p.cfra_security_trading_id).map(
                    (p: any) => `company_security.company.cfra_company_id_|_${p.cfra_company_id}`
                );

                const filterObj = getFiltersReqBody({
                    'company_security.security_trading.cfra_security_trading_company_id': {
                        values: [...cfraSecTradingIds, ...cfraCompanyIds],
                    },
                })
                if ((cfraSecTradingIds.length > 0 || cfraCompanyIds.length > 0) && !isEqual(secTradingIdsFilter, filterObj)) {
                    setSecTradingIdsFilter(filterObj);
                }
            }
        }
    }, [getWatchlistValueQry, secTradingIdsFilter]);

    const getCompaniesBySecIdQuery = sendSingleRequest({
        securityType: 'research',
        view: view,
        requestBody: secTradingIdsFilter,
        config: {
            enabled: secTradingIdsFilter ? true : false
        }
    }, {
        path: 'company/screener',
        apiName: ApiNames.Research,
        requestType: RequestTypes.POST,
        queryKeyFirstElement: 'researchCompanyScreenerQuery'
    }) as UseQueryResult<ScreenerResearchCompanyData>;

    const { minWidths, customFlexibleColumns, columnDefs } = useMemo(() => {
        if (!getCompaniesBySecIdQuery.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }
        const result = extractFromScreenerData({
            screenerData: getCompaniesBySecIdQuery.data, 
            cardName: selectedWatchlist?.name || 'inst_watchlist',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
        });

        if (!addWatchListStarColumn) {
            return result;
        }

        result.columnDefs.unshift({...WatchListColumn, 
            pinned: false,
            cellClass: 'ag-grid-cfra-watchlist-icons-reverse',
            tooltipValueGetter: (params: ITooltipParams) => {
                const isSelected = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.isSelected();
                if (!isSelected) {
                    return `Remove ${params.data?.company_security?.security_trading?.ticker_symbol} from ${selectedWatchlist?.name || 'watchlist'}`;
                }
                return '';
            },
            onCellClicked(event: any) {
                let node = event.node;
                let isSelected = node.isSelected() === undefined ? false : node.isSelected();
                node.setSelected(!isSelected);

                if (!isSelected) {
                    const value = getWatchlistValueQry.data?.data.value as any;
                    if (value?.values?.length > 0 && node.data?.id) {
                        const updatedList = value.values.filter((val:any) => {
                            if (node.data?.company_security?.security_trading?.cfra_security_trading_id && 
                                val.cfra_security_trading_id && 
                                val.cfra_security_trading_id === node.data?.company_security?.security_trading?.cfra_security_trading_id
                            ) {
                                    return false;
                            } else if (node.data?.company_security?.company?.cfra_company_id &&
                                val.cfra_company_id && 
                                val.cfra_company_id === node.data?.company_security?.company?.cfra_company_id
                            ) {
                                return false;
                            } else {
                                return true;
                            }
                        });
                        setRemovingCompanyData(node.data)
                        setUpdatedWatchlistData(updatedList);
                    }
                }
            }
        });
        return result;
    }, [getCompaniesBySecIdQuery, selectedWatchlist, getWatchlistValueQry, addWatchListStarColumn]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) => headerName === 'undefined' ? watchListColumnWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );


    const updateWatchListQuery = sendSingleRequest<any>({
        savedItems: selectedWatchlist?.id || -1,
        requestBody: {
            name: selectedWatchlist?.name,
            value: {
                values: updatedWatchlistData,
            },
        },
        config: {
            enabled: selectedWatchlist && updatedWatchlistData ? true : false,
        },
    }, updateSavedItemByIdConfig);

    useEffect(() => {
        if (!updateWatchListQuery?.isLoading && updateWatchListQuery?.isSuccess && !updateWatchListQuery?.data?.isErroredResponse) {
            setUpdatedWatchlistData(undefined);
            getWatchlistValueQry.refetch().finally(() => {
                if (removingCompanyData) {
                    const snackbarKey = ShowSnack(
                        SnackMessageForm({
                            message: `${removingCompanyData?.company_security?.security_trading?.ticker_symbol || removingCompanyData?.company_security?.company?.company_name} was removed from ${selectedWatchlist?.name}`,
                            actionBtnText: 'Undo Deletion',
                            onActionBtnClick: () => {
                                const value = getWatchlistValueQry.data?.data.value as any;
                                // Push deleted company back to updatedWatchlist data to fire update query
                                value?.values.push({
                                    cfra_security_trading_id: removingCompanyData?.company_security?.security_trading?.cfra_security_trading_id,
                                    cfra_company_id: removingCompanyData?.company_security?.company?.cfra_company_id
                                });
                                setUpdatedWatchlistData(value?.values);
                                if (snackbarKey) {
                                    closeSnackbar(snackbarKey);
                                }
                            },
                        }),
                    );
                    setRemovingCompanyData(undefined);
                }
            });
        }
    }, [updateWatchListQuery, getWatchlistValueQry, ShowSnack, closeSnackbar, removingCompanyData, selectedWatchlist])


    if ((getWatchlistValueQry?.data?.data?.value as any)?.values?.length === 0) {
        return (
            <TextRowVariant1 boldText={selectedWatchlist?.name} normalText={getNumberOfResultsText?.()} />
        )
    }
    const columnKeys: string[] = columnDefs.filter(p => p.headerName && p.headerName !== '' && !p.ignoreOnExport)
        .map(p => p.field || '');

    return (selectedWatchlist && getCompaniesBySecIdQuery?.isSuccess ?
        <>
            <TextRowVariant1 boldText={selectedWatchlist?.name} normalText={getNumberOfResultsText?.(getCompaniesBySecIdQuery?.data?.results?.total)} />
            <AgGridSelectedRowsContextProvider isSSRMEnabled={false}>
                <AgGirdCard
                    labelProps={{ width: '100%' }}
                    showDefaultExportButton={false}
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowMultiSelectWithClick={true}
                    rowSelection='multiple'
                    suppressRowClickSelection={true}
                    customFlexibleColumns={customFlexibleColumns}
                    getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                    labelPanelContainerStyles={{ paddingTop: '36px' }}
                    rowsData={getCompaniesBySecIdQuery?.data?.results.company}
                    gridTheme='grid-theme-v2'
                    defaultCsvExportParams={{
                        columnKeys
                    }}
                />
            </AgGridSelectedRowsContextProvider>
        </> : (getCompaniesBySecIdQuery?.isLoading || updateWatchListQuery?.isLoading ? <div><Layout.Skeleton height='10px' /></div> : <></>)
    )
}