export type ReadMoreVariantLinkProps = {
    text: string;
    textLengthToShowReadMore: number;
    outerLink: JSX.Element;
};

export function ReadMoreVariantLink({ text, textLengthToShowReadMore, outerLink }: ReadMoreVariantLinkProps) {
    return (
        <>
            {text.length <= textLengthToShowReadMore ? (
                text
            ) : (
                <>
                    {text.substring(0, text.lastIndexOf(' ', textLengthToShowReadMore) + 1)}
                    {outerLink}
                </>
            )}
        </>
    );
}
