import { ClearButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ClearButton';
import { autocompleteThemeVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Form/themes/autocomplete';
import { dateRangePickerThemeVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Form/themes/dateRangePicker';
import { UseFiltersFormInputProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerFormDateRangePicker } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormDateRangePicker';
import {
    ScreenerFormPillsRow,
    ScreenerFormPillsRowProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormPillsRow';
import { ScreenerFormVirtualizeAutocomplete } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormVirtualizeAutocomplete';
import { Box } from '@mui/material';
import React from 'react';

export type GetFiltersJsxProps = {};

export const getFiltersJsxVariant1: (
    props: GetFiltersJsxProps & {
        pillsRowComponentProps: {
            filterMetadataKey: ScreenerFormPillsRowProps['filterMetadataKey'];
            secondaryStyleStartIndex?: ScreenerFormPillsRowProps['secondaryStyleStartIndex'];
            customSortOrder?: ScreenerFormPillsRowProps['customSortOrder'];
        };
        virtualizeAutocompleteComponentsProps: Array<{
            defaultInputLabel: string;
            placeholder?: string;
            filterMetadataKey: string;
        }>;
        dateRangePickerComponentProps: {
            filterMetadataKey: string;
        };
    },
) => UseFiltersFormInputProps['getFiltersJsx'] =
    ({ pillsRowComponentProps, virtualizeAutocompleteComponentsProps, dateRangePickerComponentProps }) =>
    ({ submitHandler, control, filtersData, getValues, setValue, validate, onChipClearAllClick }) => {
        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '23px' }}>
                    <ScreenerFormPillsRow
                        useFormLabelWithContainer={false}
                        control={control}
                        filtersData={filtersData}
                        submitHandler={submitHandler}
                        getValues={getValues}
                        setValue={setValue}
                        validate={validate}
                        parentSectionKey={'root'}
                        hide={false}
                        {...pillsRowComponentProps}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
                    {React.Children.toArray(
                        virtualizeAutocompleteComponentsProps.map((props, index) => {
                            return (
                                <ScreenerFormVirtualizeAutocomplete
                                    key={index}
                                    defaultInputLabel={props.defaultInputLabel}
                                    placeholder={props.placeholder || props.defaultInputLabel}
                                    useFormLabelWithContainer={false}
                                    control={control}
                                    filtersData={filtersData}
                                    filterMetadataKey={props.filterMetadataKey}
                                    submitHandler={submitHandler}
                                    getValues={getValues}
                                    setValue={setValue}
                                    validate={validate}
                                    parentSectionKey={'root'}
                                    hide={false}
                                    theme={autocompleteThemeVariant1}
                                    ignoreFocus={true}
                                />
                            );
                        }),
                    )}
                    <ScreenerFormDateRangePicker
                        useFormLabelWithContainer={false}
                        control={control}
                        filtersData={filtersData}
                        filterMetadataKey={dateRangePickerComponentProps.filterMetadataKey}
                        submitHandler={submitHandler}
                        getValues={getValues}
                        parentSectionKey={'root'}
                        setValue={setValue}
                        validate={validate}
                        hide={false}
                        theme={dateRangePickerThemeVariant1}
                        buttonsText={{ start: 'From', end: 'To' }}
                    />
                    <ClearButton onClick={onChipClearAllClick} />
                </Box>
            </>
        );
    };
