import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { CellRendererParamItemData } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import { LinkGeneratorModes, useLinkGetter } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { getUsageApiReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames, RequestTypes, UsageRequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export const getRequestParamsPropsVariant1 = (researchId: string) => ({
    searchByParams: {
        requestBody: {
            filters: {
                values: {
                    'insights.research_report._id': { values: [researchId] },
                },
            },
        },
        config: {
            enabled: true,
        },
    },
});

export const getRequestParamsPropsVariant2 = (
    filterNamesToValues?: Record<string, { values: Array<any> }>,
    requestParams?: CellRendererParamItemData['request_params'],
) => ({
    searchByParams: {
        ...(filterNamesToValues && {
            requestBody: {
                filters: {
                    values: filterNamesToValues,
                },
            },
        }),
        // explicitly point this values should be taken for the security purpose
        ...(requestParams?.view && { view: requestParams.view }),
        ...(requestParams?.includeData && { config: requestParams.includeData }),
        config: {
            enabled: true,
        },
    },
});

export const researchLinkGetterParams: Parameters<typeof useLinkGetter<ScreenerResearchData>> = [
    {
        mode: LinkGeneratorModes.OpenInNewTab,
        getRequestParams: ({ searchByParams, config }) => {
            return [
                {
                    from: 0,
                    size: 1,
                    view: 'pdf',
                    includeData: false,
                    includeMetadata: false,
                    securityType: 'research',
                    // assume request body is provided in the searchByParams parameter
                    // don't sent request until we manully turn it on via config parameter
                    config: { enabled: false },
                    ...searchByParams,
                },
                {
                    requestType: RequestTypes.POST,
                    path: 'research/screener',
                    queryKeyFirstElement: 'researchScreenerQuery',
                    apiName: ApiNames.Research,
                    ...config,
                },
            ];
        },
        getLink: (data) => {
            if (data?.results?.research?.length === 0) {
                return '';
            }
            return data?.results?.research?.[0]?.research_report?.s3_pdf_url;
        },
        enableUsageLog: true,
        usageLoggerProps: {
            invalidateUsage: true,
            actionType: UsageRequestTypes.ReportViewed,
        },
        getPostUsageParams: (data) => {
            if (data?.results?.research?.length === 0) {
                return;
            }

            const research = data?.results?.research?.[0];
            let researchTypeId = research['research_report']?.['research_type_id'];
            if (!researchTypeId) {
                researchTypeId = research['research_report.research_type_id'];
            }

            return getUsageApiReqBody(UsageRequestTypes.ReportViewed, {
                research_type_id: researchTypeId,
                id: research.id,
            });
        },
    },
];
