import { SnackbarProvider } from 'notistack';
import { ResultsContextProvider } from '../../components/Screener/filtersModal/ResultsContext';
import { WatchListContextProvider } from '../../components/Screener/screenerWatchListContext/Context';
import { WatchListModal, WatchListModalOpenButton } from './WatchListModals';
import { useContext } from 'react';
import { ProjectSpecificResourcesContext } from '../../components/ProjectSpecificResourcesContext/Context';
import { getSavedItemsByTypeConfig } from '../../utils/userSavedItem';
import { SavedItemListResponseTypes } from '../../components/Screener/types/savedScreens';
import { InstitutionalWatchListRow, WatchListRow } from '../../components/Screener/screenerWatchListContext/types';
import { UserSavedItemsLookupID } from '../../utils';
import { Theme } from '@mui/material';
import { useEffect } from 'react';

export function AddToWatchlistComponent({
    watchListData,
    savedItemType,
    buttonTheme,
    buttonText,
    customIconTheme,
    tooltipText,
    isWatchListSelected,
    setWatchListSelected,
}: {
    watchListData: Array<WatchListRow | InstitutionalWatchListRow>;
    savedItemType: UserSavedItemsLookupID;
    buttonTheme?: Theme;
    buttonText?: string;
    customIconTheme?: Theme;
    tooltipText?: string;
    isWatchListSelected?: boolean;
    setWatchListSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const watchListQuery = sendSingleRequest<SavedItemListResponseTypes>(
        { types: [savedItemType], includeValues: true },
        getSavedItemsByTypeConfig,
    );

    let outlineIcon = true;

    if (watchListQuery.isSuccess && watchListQuery && watchListQuery.data) {
        let someFn: any;
        switch (savedItemType) {
            case UserSavedItemsLookupID.Watchlist:
                someFn = (item: WatchListRow) =>
                    item.composite_ticker === (watchListData[0] as WatchListRow).composite_ticker;

                break;
            case UserSavedItemsLookupID.InstitutionalWatchlist:
                someFn = (item: InstitutionalWatchListRow) =>
                    item.id === (watchListData[0] as InstitutionalWatchListRow).id;
                break;
        }

        outlineIcon = !watchListQuery.data.data
            .map((item: any) => item.value.values || item.value.data)
            .flatMap((item: any) => item)
            .some(someFn);
    }

    useEffect(() => {
        if (isWatchListSelected !== outlineIcon && setWatchListSelected !== undefined) {
            setWatchListSelected(outlineIcon);
        }
    }, [isWatchListSelected, outlineIcon, setWatchListSelected]);

    return (
        <WatchListContextProvider>
            <ResultsContextProvider>
                <SnackbarProvider maxSnack={3}>
                    <WatchListModal
                        cardName='Watch List'
                        externalViewWatchlist
                        onFinishCallback={() => {
                            watchListQuery.refetch();
                        }}
                        watchListData={watchListData}
                        savedItemType={savedItemType}
                    />
                </SnackbarProvider>
            </ResultsContextProvider>
            <WatchListModalOpenButton
                outlineIcon={outlineIcon}
                customTheme={buttonTheme}
                buttonText={buttonText}
                customIconTheme={customIconTheme}
                tooltipText={tooltipText}
            />
        </WatchListContextProvider>
    );
}
