import { Layout } from '@cfra-nextgen-frontend/shared';
import { ETFPageContainer } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFPageContainer';
import { SxProps } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Outlet } from 'react-router-dom';

export function PageWithComponentInHeader({
    component,
    tabContainerSx,
    pageContainerSx,
    outletComponent,
    headerSx,
    headerContainerSx,
    maxSnack = 3
}: {
    component?: React.ReactNode;
    tabContainerSx?: SxProps;
    pageContainerSx?: SxProps;
    outletComponent?: React.ReactNode;
    headerSx?: SxProps;
    headerContainerSx?: SxProps;
    maxSnack?: number
}) {
    const isScreenDisplay = useMediaQuery('screen');

    return (
        <>
            {/* Header section */}
            <Layout.ETFHeaderContainer
                shadow
                sx={{
                    zIndex: 1100,
                    position: isScreenDisplay ? 'sticky' : 'static',
                    top: '72px',
                    ...headerSx
                }}
                containerSx={headerContainerSx}>
                {component}
            </Layout.ETFHeaderContainer>
            {/* Body section*/}
            <SnackbarProvider maxSnack={maxSnack}>
                <ETFPageContainer containerStyles={pageContainerSx}>
                    <Layout.ETFTabContainer
                        sx={{
                            display: 'flex',
                            flexFlow: 'row wrap',
                            justifyContent: 'center',
                            ...tabContainerSx,
                        }}>
                        {outletComponent || <Outlet />}
                    </Layout.ETFTabContainer>
                </ETFPageContainer>
            </SnackbarProvider>
        </>
    );
}
