import { useAuthenticator } from '@aws-amplify/ui-react';
import CFRAInstitutionalLogo from '@cfra-nextgen-frontend/cfra-institutional/src/assets/images/CFRAInstitutionalLogo.svg';
import { ETFMenu } from '@cfra-nextgen-frontend/cfra-institutional/src/components/layout/ETFMenu';
import { a11yProps } from '@cfra-nextgen-frontend/cfra-institutional/src/components/layout/ETFTabPanel';
import { ReactComponent as SearchIcon2 } from '@cfra-nextgen-frontend/shared/src/assets/images/SearchIcon_2.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { TypeSearch, TypeSearchProps } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { topNavigationHeight } from '@cfra-nextgen-frontend/shared/src/utils/lookAndFeel';
import {
    AppBar,
    Avatar,
    Box,
    Container,
    createTheme,
    Dialog,
    DialogContent,
    IconButton,
    styled,
    Tab,
    Tabs,
    ThemeProvider,
    Toolbar,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Popup } from '../../types/popupContent';
import PopupContent from '../Popup/PopupContent';
import { PasswordResetForm } from '@cfra-nextgen-frontend/shared/src/components/PasswordRestForm/PasswordResetForm';
import { SnackbarProvider } from 'notistack';
import { UserEntitlementsResponseData } from '../../types/userEntitlements';


const handleContactUsClick = () => {
    globalThis.analytics?.registerAction?.({
        action: 'footer : contact us',
    });
    window.open('https://www.cfraresearch.com/contact/', '_blank', 'noreferrer');
};

export interface MenuItem {
    name: string;
    href: string;
    type: 'link' | 'tab';
    popup?: Popup;
    shouldHideItem?: (entitlements?: UserEntitlementsResponseData) => boolean;
}

const LogoImage = styled('img')(() => ({
    height: '52px',
    paddingTop: 'unset',
    paddingBottom: 'unset',
    '@media (max-width: 420px)': {
        height: 24,
        paddingTop: '22px',
        paddingBottom: '22px',
    },
}));

const cfraLogoStyles = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
};

const initialThemeOptions = {
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#FFF',
                },
                root: {
                    borderBottom: 'solid #E4E5E9 1px',
                    borderTop: 'solid #3078B5 4px',
                    boxShadow: 'none',
                    zIndex: 1500,
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#002B5A',
                    fontSize: 18,
                    fontFamily: fontFamilies.GraphikLight,
                    height: 48,
                    textTransform: 'uppercase',
                    width: 48,
                },
            },
        },
        MuiContainer: {
            defaultProps: {
                disableGutters: true,
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    marginLeft: '18px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#007AB9',
                    },
                    color: '#0B2958',
                    fontFamily: fontFamilies.GraphikMedium,
                    fontSize: '15px',
                    lineHeight: 1,
                    marginRight: '28px',
                    minWidth: '0px',
                    padding: 0,
                    paddingBottom: '25px',
                    paddingTop: '25px',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#007AB9',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    background: '#007AB9',
                    borderRadius: '5px',
                    height: '4px',
                },
                root: {
                    [breakpointsTheme.breakpoints.down('lg')]: {
                        visibility: 'collapse',
                        width: 0,
                    },
                    paddingTop: '3px',
                },
            },
        },
        MuiToolbar: {
            defaultProps: {
                disableGutters: true,
            },
            styleOverrides: {
                root: {
                    display: 'flex',
                    height: topNavigationHeight,
                    justifyContent: 'space-between',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                mr: 1,
                noWrap: true,
                variant: 'h6',
            },
            styleOverrides: {
                root: {
                    color: '#3078B5',
                    fontSize: '28px',
                    fontFamily: fontFamilies.GraphikLight,
                    lineHeight: 1.2,
                    textDecoration: 'none',
                },
            },
        },
    },
};

type TopNavigationProps = {
    topNavItems: Record<string, MenuItem>;
    typeSearchProps: Omit<Omit<TypeSearchProps, 'showSearchInput'>, 'onClickOutsideCallback'>;
    maxWidth: string;
};

export function SharedTopNavigation({ topNavItems, typeSearchProps, maxWidth }: TopNavigationProps) {
    const [currentActiveTab, setCurrentActiveTab] = useState<number | boolean>(false);
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [popupContent, setPopupContent] = useState<Popup | undefined>(undefined);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isHoveringPopup, setIsHoveringPopup] = useState(false);
    const [hoveredTab, setHoveredTab] = useState<number | null>(null);
    const [isHoveringTab, setIsHoveringTab] = useState(false);

    const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const isScreenDisplay = useMediaQuery('screen');
    const { toResetPassword } = useAuthenticator();

    useEffect(() => {
        const activeTabIndex = Object.values(topNavItems)
            .map((value) => value.href)
            .indexOf(`/${pathname.slice(1).split('/')[0]}`);
        setCurrentActiveTab(activeTabIndex > -1 ? activeTabIndex : false);
    }, [pathname, topNavItems]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentActiveTab(newValue);
        const selectedItem = topNavItems[Object.keys(topNavItems)[newValue]];

        if (selectedItem.type === 'tab' && selectedItem.popup) {
            setPopupContent(selectedItem.popup);
            setAnchorEl(event.currentTarget as HTMLElement);
        } else {
            setPopupContent(undefined);
        }
    };

    useEffect(() => {
        const handleVisibility = () => {
            if (!isHoveringTab && !isHoveringPopup) {
                setPopupContent(undefined);
                setHoveredTab(null);
            }
        };

        const timeout = setTimeout(handleVisibility, 200);

        return () => {
            clearTimeout(timeout);
        };
    }, [isHoveringTab, isHoveringPopup]);

    const handleMouseEnterTab = (event: React.MouseEvent<HTMLDivElement>, popup: Popup | undefined, index: number) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        setPopupContent(popup);
        setIsHoveringTab(true);
        setHoveredTab(index);
    };

    const handleMouseLeaveTab = () => {
        setIsHoveringTab(false);
    };

    const handlePopupMouseEnter = () => {
        setIsHoveringPopup(true);
    };

    const handlePopupMouseLeave = () => {
        setIsHoveringPopup(false);
    };

    const handleSearchClick = useCallback(() => {
        setShowSearchInput(true);
        setPopupContent(undefined);
        setHoveredTab(null);
    }, []);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleResetPassword = useCallback(() => {
        setIsPopupOpen(true);
        toResetPassword();
    }, [toResetPassword]);

    const handleClosePopup = useCallback(() => {
        setIsPopupOpen(false);
    }, []);

    const handleSearchBlur = () => {
        setShowSearchInput(false);
    };

    const theme = createTheme(
        breakpointsTheme,
        deepmerge(initialThemeOptions, {
            components: {
                MuiAppBar: {
                    defaultProps: {
                        position: isScreenDisplay ? 'sticky' : 'static',
                    },
                },
                MuiContainer: {
                    defaultProps: {
                        maxWidth: maxWidth,
                    },
                    styleOverrides: {
                        root: {
                            maxWidth: maxWidth,
                        },
                    },
                },
            },
        }),
    );

    const typeSearch = useMemo(() => {
        return (
            <ThemeProvider theme={createTheme()}>
                <TypeSearch
                    showSearchInput={showSearchInput}
                    onClickOutsideCallback={handleSearchBlur}
                    {...typeSearchProps}
                />
            </ThemeProvider>
        );
    }, [showSearchInput, typeSearchProps]);

    return (
        <ThemeProvider theme={theme}>
            <AppBar>
                <Container>
                    <Toolbar>
                        {!showSearchInput && (
                            <>
                                <ETFMenu
                                    menuItems={Object.keys(topNavItems).map((key) => ({
                                        itemName: topNavItems[key].name,
                                        callback: () => {
                                            navigate(topNavItems[key].href);
                                        },
                                    }))}>
                                    <IconButton
                                        sx={{
                                            marginLeft: 0,
                                            paddingRight: '18px',
                                            [theme.breakpoints.up('lg')]: {
                                                display: 'none',
                                            },
                                        }}
                                    />
                                </ETFMenu>
                                <Box
                                    sx={cfraLogoStyles}
                                    onClick={() => {
                                        navigate(topNavItems.Home.href);
                                    }}>
                                    <LogoImage src={CFRAInstitutionalLogo} alt='CFRA Institutial Logo' />
                                </Box>
                                <div style={{ flexGrow: 1 }} />
                                <Tabs value={currentActiveTab} onChange={handleChange} aria-label='top navigation tabs'>
                                    {Object.keys(topNavItems).map((key, index) => {
                                        const topNavItem = topNavItems[key];
                                        return (
                                            <Tab
                                                label={topNavItem.name}
                                                key={key}
                                                {...a11yProps(index)}
                                                onClick={() => navigate(topNavItem.href)}
                                                onMouseEnter={(event) =>
                                                    handleMouseEnterTab(event, topNavItem.popup!, index)
                                                }
                                                onMouseLeave={() => handleMouseLeaveTab()}
                                                sx={{
                                                    color: hoveredTab === index ? '#3078B5' : '#0B2958',
                                                }}
                                            />
                                        );
                                    })}
                                </Tabs>
                                {popupContent && (
                                    <PopupContent
                                        popup={popupContent}
                                        anchorEl={anchorEl}
                                        open={true}
                                        onClose={() => {
                                            if (!isHoveringPopup) {
                                                setPopupContent(undefined);
                                            }
                                        }}
                                        onMouseEnter={handlePopupMouseEnter}
                                        onMouseLeave={handlePopupMouseLeave}
                                    />
                                )}
                                <div style={{ flexGrow: 1 }} />
                            </>
                        )}
                        <Box
                            sx={
                                showSearchInput
                                    ? {
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                      }
                                    : {
                                          width: '220px',
                                          display: 'block',
                                      }
                            }>
                            {typeSearch}
                            {!showSearchInput && (
                                <Box
                                    onClick={handleSearchClick}
                                    sx={{
                                        color: 'black',
                                        height: '44px',
                                        border: '1px solid #5792C3',
                                        borderRadius: '30px',
                                        backgroundColor: '#F3F5FC',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        paddingLeft: '20px',
                                        paddingRight: '18px',
                                        cursor: 'pointer',
                                    }}>
                                    <span
                                        style={{
                                            fontFamily: fontFamilies.GraphikRegular,
                                            fontSize: '14px',
                                            color: '#3C3C3C',
                                        }}>
                                        Search CFRA Content
                                    </span>
                                    <CustomSvgIcon
                                        component={SearchIcon2}
                                        viewBox='0 0 19 19'
                                        sx={{
                                            width: '18px',
                                            height: '18px',
                                            fill: '#002B5A',
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                        {!showSearchInput && (
                            <ETFMenu
                                menuItems={[
                                    {
                                        itemName: user?.username || '',
                                    },
                                    {
                                        itemName: 'Contact Us',
                                        callback: handleContactUsClick,
                                    },
                                    {
                                        itemName: 'Reset Password',
                                        callback: handleResetPassword,
                                    },
                                    {
                                        itemName: 'Logout',
                                        callback: signOut,
                                    },
                                ]}>
                                <Tooltip title={user?.username}>
                                    <IconButton>
                                        <Avatar>{user?.username?.charAt(0)}</Avatar>
                                    </IconButton>
                                </Tooltip>
                            </ETFMenu>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            <SnackbarProvider classes={{ containerRoot: 'cfra-snackbar-root' }}>
                <Dialog open={isPopupOpen} sx={{zIndex: '4000'}}>
                    <DialogContent>
                        <PasswordResetForm onCancel={handleClosePopup}/>
                    </DialogContent>
                </Dialog>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
