import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { DataItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { ApiNames, RequestTypes, UsageRequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { getUsageApiQueryKey, invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { useCallback, useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';

export type PostUsageData = {
    data: DataItem;
};

export type UsageLoggerProps = {
    onSuccess?: (data?: PostUsageData) => void;
    requestBody?: any;
    config?: any;
    invalidateUsage?: boolean;
    actionType?: UsageRequestTypes
};

type UsageLoggerReturnTypes = {
    setEnable: (enabled: boolean) => void;
    setRequestBody: (body: any, enabled?: boolean) => void;
};

export function useUsageLogger(props: UsageLoggerProps): UsageLoggerReturnTypes {
    const { actionType, onSuccess = () => { }, invalidateUsage = true } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [requestParams, setRequestParams] = useState({
        requestBody: props.requestBody || {},
        config: props?.config || { enabled: false },
        noErrorOnNoKeyValuePairs: true,
    })

    useEffect(() => {
        if (!sendSingleRequest) {
            throw new Error('useUsageLogger hook exception. sendSingleRequest is not provided.');
        }
    }, [sendSingleRequest]);

    const usageQry = sendSingleRequest?.({ ...requestParams }, {
        requestType: RequestTypes.POST,
        path: 'usage',
        queryKeyFirstElement: 'postUsageQuery',
        apiName: ApiNames.UserManagement,
    }) as UseQueryResult<PostUsageData>;

    const setRequestBody = useCallback((body: any, enabled = false) => {
        setRequestParams((prevState) => ({
            ...prevState,
            requestBody: { ...prevState.requestBody, ...body },
            config: { ...prevState.config, enabled: enabled }
        }))
    }, [setRequestParams]);

    const setEnable = useCallback((enabled: boolean) => {
        setRequestParams((prevState) => ({
            ...prevState,
            config: { ...prevState.config, enabled }
        }))
    }, []);

    const handleOnSuccess = useCallback((usageQry: UseQueryResult<PostUsageData>) => {
        setEnable(false)
        if (invalidateUsage && actionType) {
            invalidateQueriesByKey(getUsageApiQueryKey(actionType));
        }
        onSuccess(usageQry?.data)
    }, [actionType, invalidateUsage, onSuccess, setEnable]);

    useEffect(() => {
        if (requestParams?.config?.enabled && !usageQry?.isLoading && usageQry.isFetched) {
            handleOnSuccess(usageQry)
        }
    }, [handleOnSuccess, requestParams?.config?.enabled, usageQry]);

    return { setEnable, setRequestBody }
}