import { ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';

export enum RoutePaths {
    Bespoke = '/bespoke',
    AccountingLensBiggestConcern = '/accounting-lens/biggest-concern',
    AccountingLensCompanyResearch = '/accounting-lens/reports/company',
    AccountingLensIndustryResearch = '/accounting-lens/reports/industry',
    ResearchHub = '/research-hub',
    Scores = '/scores',
    BespokeEdge = '/bespoke/edge',
    LegalEdge = '/legal-edge',
}

export enum PageNames {
    Bespoke = 'Bespoke',
    AccountingLensBiggestConcern = 'Biggest Concern',
    AccountingLensCompanyResearch = 'Company Research',
    AccountingLensIndustryResearch = 'Industry & Education Reports, IRAPs',
    ResearchHub = 'Research Hub',
    LegalEdge = 'Legal Edge',
    BespokeEdge = 'Bespoke Edge',
    Scores = 'Scores',
}

export const PageNamesToRoutePaths: Record<PageNames, RoutePaths> = {
    [PageNames.Bespoke]: RoutePaths.Bespoke,
    [PageNames.AccountingLensBiggestConcern]: RoutePaths.AccountingLensBiggestConcern,
    [PageNames.AccountingLensCompanyResearch]: RoutePaths.AccountingLensCompanyResearch,
    [PageNames.AccountingLensIndustryResearch]: RoutePaths.AccountingLensIndustryResearch,
    [PageNames.ResearchHub]: RoutePaths.ResearchHub,
    [PageNames.Scores]: RoutePaths.Scores,
    [PageNames.LegalEdge]: RoutePaths.LegalEdge,
    [PageNames.BespokeEdge]: RoutePaths.BespokeEdge,
};

export const InstitutionalResearchReportTypeIds = [
    ResearchTypeId.FrsBanksOrFIReport,
    ResearchTypeId.FrsBespokeAdminViewer,
    ResearchTypeId.FrsBespokeEdge,
    ResearchTypeId.FrsCompanyReport,
    ResearchTypeId.FrsDailySnapshot,
    ResearchTypeId.FrsDiagnosticReport,
    ResearchTypeId.FrsEarningsAlert,
    ResearchTypeId.FrsEducationalReport,
    ResearchTypeId.FrsIndustryReport,
    ResearchTypeId.FrsIRAPS,
    ResearchTypeId.FrsNotebooks,
    ResearchTypeId.LegalAlert,
    ResearchTypeId.LegalEducational,
    ResearchTypeId.LegalPipeline,
    ResearchTypeId.LegalPipelineSpecialInterestList,
    ResearchTypeId.LegalReport,
    ResearchTypeId.PolicyNewsletterToWealth,
];

export const enum ResearchReportTypes {
    SpecialInterest = 28,
}
