import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { Navigate, Route, Routes } from 'react-router-dom';
import { BiggestConcernHome } from '../components/BiggestConcern/BiggestConcernHome';
import { ResearchCompanyHome } from '../components/ResearchCompany/ResearchCompanyHome';
import { ResearchIndustryHome } from '../components/ResearchIndustry/ResearchIndustryHome';

export function AccountingRoutes() {
    return (
        <Routes>
            <Route>
                <Route path='/*' element={<Navigate to='./biggest-concern' />} />
                <Route
                    path='/biggest-concern'
                    element={
                        <PageWrapper>
                            <BiggestConcernHome />
                        </PageWrapper>
                    }
                />
                <Route path='/reports/company' element={<ResearchCompanyHome />} />
                <Route path='/reports/industry' element={<ResearchIndustryHome />} />
            </Route>
        </Routes>
    );
}
