import PlusIcon from '@cfra-nextgen-frontend/shared/src/assets/images/PlusIcon.svg';
import { CFRARoundedSelect } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect';
import { LinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import CloseIconGrey from '../../../../assets/images/CloseIconGrey.svg';

const themeOptions = {
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: '0px !important',
                    padding: '0px !important',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    minWidth: '170px !important',
                    backgroundColor: '#FFF',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: {
                    padding: '0px',
                },
            },
        },
    },
};

const BorderedMuiOutlineInput = {
    styleOverrides: {
        notchedOutline: {
            borderColor: '#1976d2',
            borderWidth: '2px',
        },
    },
};

type WatchlistDropdownProps = {
    selectedWatchlist?: SavedItemTypes;
    userWatchlist: SavedItemTypes[];
    onSelectionChange: (d: SavedItemTypes) => void;
    onResetClick: () => void;
    onCreateClick: () => void;
};

export const MenuTextStyle = {
    fontFamily: fontFamilies.GraphikSemibold,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0.46px',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '7px',
    width: '100%',
    gap: '8px',
};

const ButtonMenuItem = styled(LinkButton)(({ theme }) => ({
    ...MenuTextStyle,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
    fontFamily: fontFamilies.GraphikMedium,
    fontWeight: fontWeights.Medium,
    fontSize: '14px',
}));

export function WatchlistDropdown(props: WatchlistDropdownProps) {
    const { selectedWatchlist, userWatchlist, onSelectionChange, onResetClick, onCreateClick } = props;

    const watchlistMenuItems = useMemo(() => {
        const menuItem = [];

        if (userWatchlist?.length === 0) {
            menuItem.push({
                menuItem: (
                    <ButtonMenuItem key='create_watchlist' onClick={onCreateClick}>
                        <Box component='img' src={PlusIcon} />
                        <Box sx={{ color: '#3078B5' }}>Create a Watchlist</Box>
                    </ButtonMenuItem>
                ),
            });
        } else {
            if (selectedWatchlist?.name) {
                menuItem.push({
                    menuItem: (
                        <ButtonMenuItem key='reset' onClick={onResetClick}>
                            <Box component='img' src={CloseIconGrey} />
                            <Box sx={{ color: '#3C3C3C' }}>Reset</Box>
                        </ButtonMenuItem>
                    ),
                });
            }

            menuItem.push(...userWatchlist.map(({ name }: SavedItemTypes) => name));
        }

        return menuItem;
    }, [onCreateClick, onResetClick, selectedWatchlist?.name, userWatchlist]);

    const handleSelectionChange = useCallback(
        (e: { target: { value: any } }) => {
            const selectedItem = userWatchlist.find(
                (p: any) => p.name.toLowerCase() === String(e.target?.value)?.toLowerCase(),
            );

            if (!selectedItem) {
                return;
            }

            onSelectionChange(selectedItem);
        },
        [onSelectionChange, userWatchlist],
    );

    const _themeOptions = useMemo(() => {
        if (selectedWatchlist?.name) {
            return {
                components: {
                    ...themeOptions.components,
                    MuiOutlinedInput: BorderedMuiOutlineInput,
                },
            };
        }
        return themeOptions;
    }, [selectedWatchlist?.name]);

    return (
        <CFRARoundedSelect
            themeOptions={_themeOptions}
            placeholder='Select Watchlist'
            selectItems={watchlistMenuItems}
            handleSelectionChange={handleSelectionChange}
            currentSelection={selectedWatchlist?.name || ''}
        />
    );
}
