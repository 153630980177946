import { determineSendSingleInfiniteRequest, determineSendSingleRequest, SendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import {
    determineGetData,
    determinePrefetchQuery,
    determineUseData,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiInstanceTypes, ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { API } from 'aws-amplify';

export const getData = determineGetData({
    apiNameToApiDetails: {
        [ApiNames.Research]: {
            instance: API,
            instanceType: ApiInstanceTypes.AwsAmplify,
        },
        [ApiNames.UserManagement]: {
            instance: API,
            instanceType: ApiInstanceTypes.AwsAmplify,
        },
    },
    useDataGetter: (data) => data,
});

export const UseData = determineUseData(getData);
export const prefetchQuery = determinePrefetchQuery(getData);
export const sendSingleRequest = determineSendSingleRequest({ UseData });
export const sendSingleInfiniteRequest = determineSendSingleInfiniteRequest({ getData });

export const researchFiltersRequestParams: Parameters<SendSingleRequest> = [
    {
        securityType: 'research',
        requestBody: {},
        config: {},
    },
    {
        requestType: RequestTypes.POST,
        path: 'research/screener-filters',
        queryKeyFirstElement: 'researchFiltersQuery',
        apiName: ApiNames.Research,
    },
];

export const companyFiltersRequestParams: Parameters<SendSingleRequest> = [
    {
        securityType: 'research',
        requestBody: {},
        config: {},
    },
    {
        requestType: RequestTypes.POST,
        path: 'company/screener-filters',
        queryKeyFirstElement: 'companyFiltersQuery',
        apiName: ApiNames.Research,
    },
];
