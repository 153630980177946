import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerFiltersChipPanel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultPanelRow';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ScreenerChipThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import {
    getDirtyData,
    getPostAndChipsData,
    RhFormData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { Box, Skeleton } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Control, FieldValues, useForm, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import { SendSingleRequest } from '../../api/screener';
import { FiltersModalContext } from '../../filtersModal/FiltersModalContext';

type UseFiltersFormOutputProps = {
    filtersFormJsx?: JSX.Element;
    filtersChipPanelJsx?: JSX.Element;
};

export type UseFiltersFormExternalInputProps = {
    externalChipItems?: Record<string, ChipItem>;
    onExternalChipDeleteClick?: (key: string) => void;
};

export type UseFiltersFormInputProps = {
    filtersRequestParams: Parameters<SendSingleRequest>;
    getFiltersJsx: (paarams: {
        submitHandler: () => void;
        control: Control;
        filtersData: FiltersData;
        getValues: UseFormGetValues<FieldValues>;
        setValue: UseFormSetValue<FieldValues>;
        validate: (fieldName: string) => Promise<boolean | undefined>;
        onChipClearAllClick: () => void;
    }) => JSX.Element;
} & UseFiltersFormExternalInputProps;

export function useFiltersForm({
    filtersRequestParams,
    externalChipItems,
    onExternalChipDeleteClick,
    getFiltersJsx,
}: UseFiltersFormInputProps): UseFiltersFormOutputProps {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { setFiltersPostData } = useContext(FiltersModalContext);
    const [filtersData, setFiltersData] = useState<FiltersData | undefined>(undefined);
    const [submittingData, setSubmittingData] = useState<{ [key: string]: any } | null>(null);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const filtersDataUseQueryResult = sendSingleRequest(...filtersRequestParams) as
        | UseQueryResult<FiltersData>
        | undefined;

    const {
        chipStateManager: { chipState, chipStateDispatcher },
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);

    const {
        control,
        formState: { dirtyFields, errors, isValidating },
        getValues,
        setValue,
        handleSubmit,
        trigger,
        reset,
        resetField,
    } = useForm({
        reValidateMode: 'onSubmit',
    });

    const validate = useCallback(
        async (fieldName: string) => {
            return await trigger?.(fieldName);
        },
        [trigger],
    );

    useEffect(() => {
        if (filtersDataUseQueryResult?.data && !filtersData) {
            setFiltersData(filtersDataUseQueryResult.data);
        }
    }, [filtersDataUseQueryResult, filtersData]);

    useEffect(() => {
        const onSubmit = (data: RhFormData) => {
            const dirtyData = Object.keys(dirtyFields).reduce((result, current) => {
                return dirtyFields[current as keyof typeof dirtyFields]
                    ? { ...result, [current]: data[current as keyof typeof data] }
                    : result;
            }, {});

            if (!filtersData) {
                return;
            }
            const { postData } = getPostAndChipsData(dirtyData, filtersData);

            setFiltersPostData(postData);
        };
        if (!isValidating && Object.keys(errors).length === 0 && submittingData) {
            onSubmit(submittingData);
            setSubmittingData(null);
        }
    }, [isValidating, errors, submittingData, dirtyFields, filtersData, setFiltersPostData]);

    useEffect(() => {
        if (chipState.action === 'Dirty' && filtersData) {
            let dirtyData = chipState.field.dirtyData;
            let controlID = chipState.field.controlID;

            if (dirtyData[controlID] === undefined) {
                resetField(controlID);
            } else {
                setValue(controlID, dirtyData[controlID]);
            }

            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);
            setFiltersPostData(postData);
            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        }
    }, [resetField, setFiltersPostData, filtersData, setValue, chipState.field, chipStateDispatcher, chipState.action]);

    useEffect(() => {
        if (chipState.action === 'Clear') {
            reset();
            setFiltersPostData(undefined);
            chipStateDispatcher({ type: 'ClearAction' });
        }
    }, [reset, setFiltersPostData, chipState.action, chipStateDispatcher]);

    useEffect(() => {
        const onSubmit = (data: RhFormData) => {
            const dirtyData = getDirtyData(dirtyFields, data);
            if (!filtersData) {
                return;
            }
            const { postData, chipItems } = getPostAndChipsData(dirtyData, filtersData);

            setFiltersPostData(postData);

            chipStateDispatcher({ type: 'SetChipsData', newState: { chipItems: chipItems } });
            chipStateDispatcher({ type: 'SetFiltersDirtyData', newState: dirtyData });
        };

        if (!isValidating && Object.keys(errors).length === 0 && submittingData) {
            onSubmit(submittingData);
            setSubmittingData(null);
        }
    }, [isValidating, errors, submittingData, dirtyFields, filtersData, setFiltersPostData, chipStateDispatcher]);

    const submitHandler = useCallback(() => {
        handleSubmit((data) => {
            setSubmittingData(data);
        })();
    }, [handleSubmit]);

    const reactNodes = useMemo(() => {
        if (!filtersData) {
            return null;
        }

        return getFiltersJsx({
            submitHandler,
            control,
            filtersData,
            getValues,
            setValue,
            validate,
            onChipClearAllClick,
        });
    }, [control, filtersData, getValues, validate, submitHandler, setValue, onChipClearAllClick, getFiltersJsx]);

    if (filtersDataUseQueryResult?.isLoading && !filtersData) {
        return {
            filtersFormJsx: (
                <Box sx={{ width: '100%' }}>
                    <Skeleton height='10px' />
                </Box>
            ),
        };
    }

    if (filtersDataUseQueryResult && !filtersDataUseQueryResult.data?.filter_metadata) {
        return { filtersFormJsx: <NoInformationAvailable emptyCardText='No filters information available.' /> };
    }

    if (!filtersData) {
        return {};
    }

    return {
        filtersFormJsx: <form style={{ marginBottom: '23px' }}>{reactNodes}</form>,
        filtersChipPanelJsx: (
            <ScreenerFiltersChipPanel
                key='ScreenerFilterChips'
                showClearButton={false}
                passedTheme={ScreenerChipThemeV2}
                externalChipItems={externalChipItems}
                externalOnChipDeleteClick={onExternalChipDeleteClick}
            />
        ),
    };
}
