import { SxProps } from '@mui/material';
import { horizontalPaddingInSu, maxPageWidthInPx } from 'utils/lookAndFeel';

export const pageWithComponentInHeaderStyleProps: Record<string, SxProps> = {
    pageContainerSx: {
        maxWidth: '100%',
        paddingLeft: horizontalPaddingInSu,
        paddingRight: horizontalPaddingInSu,
        display: 'flex',
        justifyContent: 'center',
    },
    tabContainerSx: {
        paddingTop: horizontalPaddingInSu,
        paddingBottom: horizontalPaddingInSu,
        paddingLeft: 0,
        paddingRight: 0,
        maxWidth: maxPageWidthInPx,
        boxSizing: 'content-box',
        backgroundColor: 'unset',
        width: '100%',
    },
    headerSx: {
        position: 'static',
    },
    headerContainerSx: {
        padding: '0px',
        maxWidth: maxPageWidthInPx,
        height: '90px',
        alignContent: 'center',
    },
};
