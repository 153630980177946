import { createTheme, Shadows, ThemeOptions } from '@mui/material/styles';
import { roundedTextButtonThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils/fonts';

const theme = createTheme({
    typography: {
        fontFamily: fontFamilies.GraphikRegular,
        fontSize: 15,
        allVariants: {
            color: 'black',
        },
    },
    shadows: Array(25).fill('none') as Shadows,
});

export const appTheme = createTheme(theme, {
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: 'black',
                },
            },
        },
    },
});

export const breakpointsTheme = createTheme(appTheme, {
    breakpoints: {
        values: {
            lg: 1200,
            md: 900,
            sm: 700,
            xl: 1536,
            xs: 0,
        },
    },
});

export const tooltipTheme = createTheme(breakpointsTheme, {
    components: {
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: 'top',
            },
            styleOverrides: {
                popper: {
                    zIndex: 2000,
                },
                tooltip: {
                    padding: '12px 24px 12px 24px',
                    fontFamily: fontFamilies.GraphikRegular,
                    color: '#57626a',
                    fontSize: '14px',
                    backgroundColor: '#FFF',
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                },
                arrow: {
                    '&::before': {
                        color: '#FFF',
                    },
                },
            },
        },
    },
});

export const tooltipThemeV2 = createTheme(breakpointsTheme, {
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    popper: {
                        zIndex: 2000,
                    },
                    width: 'auto',
                    fontFamily: 'GraphikRegular',
                    backgroundColor: '#fff',
                    padding: '12px 24px 12px 24px',
                    color: '#57626a',
                    radius: '5px',
                    fontSize: '14px',
                    boxShadow: '0 3px 5px 2px rgba(38, 42, 46, 0.12)',
                    '& .MuiTooltip-arrow': {
                        '&::before': {
                            backgroundColor: '#fff',
                        },
                    },
                },
            },
        },
    },
});

export const tableTheme = createTheme(tooltipTheme, {
    components: {
        MuiCustomBox: {
            styleOverrides: {
                root: {
                    padding: '28px 0px 28px 0px',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    lineHeight: 1,
                    paddingTop: '0px',
                    fontFamily: fontFamilies.GraphikMedium,
                    color: '#57626a',
                    borderBottom: '1px solid #74828D',
                },
                body: {
                    paddingTop: '16px',
                    verticalAlign: 'center',
                    fontFamily: fontFamilies.GraphikRegular,
                    color: '#002B5A',
                    lineHeight: '22px',
                    borderBottom: '1px dashed #74828D',
                    borderRight: '2px solid white',
                },
                root: {
                    '&:not(:first-of-type)': {
                        paddingLeft: '0px',
                    },
                    fontSize: '15px',
                    paddingRight: '28px',
                    paddingBottom: '16px',
                    paddingLeft: '28px',
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                item: {
                    justifyContent: 'space-between',
                    display: 'flex',
                },
            },
        },
        MuiTotalRowBox: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    paddingTop: '16px',
                },
            },
        },
    },
});

const fontSlateBlueMedium = {
    fontFamily: fontFamilies.GraphikMedium,
    color: '#002B5A',
    fontWeight: 400,
};

const fontDimGrayMedium = {
    fontFamily: fontFamilies.GraphikMedium,
    color: '#5A5A5A',
    fontWeight: 400,
};

const fontDarkGrayRegular = {
    fontFamily: fontFamilies.GraphikRegular,
    color: '#3C3C3C',
    fontWeight: 400,
};

const fontSteelBlueRegular = {
    fontFamily: fontFamilies.GraphikRegular,
    color: '#007AB9',
    fontWeight: 400,
};

const lineHeightSpacing24 = {
    lineHeight: '24px',
    letterSpacing: '0.1%',
};

const lineHeightSpacing16 = {
    lineHeight: '16px',
    letterSpacing: '0.1%',
};

const lineHeightSpacing20 = {
    lineHeight: '20px',
    letterSpacing: '0%',
};

const lineHeightSpacing36 = {
    lineHeight: '36px',
    letterSpacing: '0%',
};

const lineHeightSpacing22 = {
    lineHeight: '22px',
    letterSpacing: '0%',
};

export const BespokeTheme = createTheme(roundedTextButtonThemeV3, {
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 400,
                    fontSize: '13px',
                    fontFamily: fontFamilies.GraphikRegular,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '139px',
                    height: '32px',
                    fontWeight: 400,
                },
            },
        },
    },
});

export const WatchListIconOutline = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: '#CCC',
                    fontSize: '20px',
                    width: '20px',
                    height: '20px',
                },
            },
        },
    },
});

export const WatchListIconSelected = createTheme(WatchListIconOutline, {
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: '#002B5A',
                },
            },
        },
    },
});

export const WatchListButtonV12Theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    ...fontSteelBlueRegular,
                    fontSize: '13.5px',
                    fontWeight: 400,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: 'auto',
                    borderRadius: '0px',
                    backgroundColor: '#FFF',
                    padding: '0px 0px',
                    verticalAlign: 'top',
                    height: 'auto',
                },
            },
        },
    },
});

export const companyHeadlineItemTheme = createTheme({
    typography: {
        researchType: {
            fontFamily: fontFamilies.GraphikRegular,
            fontSize: '12px',
            color: '#609EA0',
            fontWeight: 600,
            lineHeight: '16px',
            textAlign: 'left',
        },
        caption: {
            fontFamily: fontFamilies.GraphikRegular,
            fontSize: '12px',
            color: '#808080',
            fontWeight: 400,
            lineHeight: '16px',
            textAlign: 'left',
        },
        headlineTitle: {
            fontFamily: fontFamilies.GraphikSemibold,
            fontSize: '13.5px',
            color: '#002B5A',
            fontWeight: 600,
            lineHeight: '22px',
            textAlign: 'left',
        },
    },
    components: {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    width: '100%',
                    padding: '15px 18px',
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: '1px solid #E4E4E4',
                    '&:hover': {
                        backgroundColor: '#F3F5FC',
                    },
                },
            },
        },
    },
});

export const PageTheme = createTheme({
    typography: {
        captionSep: {
            fontFamily: fontFamilies.GraphikMedium,
            color: '#CCCCCC',
            lineHeight: '24px',
            fontSize: '20px',
            fontWeight: 500,
        },
        cardCaption: {
            ...fontSlateBlueMedium,
            ...lineHeightSpacing24,
            fontSize: '20px',
            fontWeight: 500,
        },
        scoreKeyStyle: {
            ...fontDimGrayMedium,
            ...lineHeightSpacing16,
            fontSize: '12px',
            whiteSpace: 'pre-line',
            fontWeight: 500,
        },
        scoreValueStyle: {
            fontFamily: fontFamilies.GraphikSemibold,
            fontSize: '30px',
            fontWeight: 500,
        },
        cardSubCaption: {
            ...fontSlateBlueMedium,
            fontSize: '18px',
        },
        cardRegular: {
            ...fontDarkGrayRegular,
            ...lineHeightSpacing36,
            fontSize: '13.5px',
        },
        cardLink: {
            ...fontDarkGrayRegular,
            ...lineHeightSpacing36,
            fontSize: '13.5px',
            color: '#007AB',
            cursor: 'pointer',
        },
        cardDescriptionTitle: {
            ...fontDimGrayMedium,
            ...lineHeightSpacing20,
            fontSize: '13.5px',
        },
        cardDescriptionText: {
            ...fontDarkGrayRegular,
            ...lineHeightSpacing22,
            fontSize: '13.5px',
            fontWeight: 500,
        },
        scoreText: {
            ...fontSlateBlueMedium,
            fontSize: '11px',
            fontWeight: 400,
            textTransform: 'uppercase',
            whiteSpace: 'pre-line',
        },
        scoreValue: {
            fontFamily: fontFamilies.GraphikSemibold,
            padding: '10px 0px',
            fontSize: '48px',
            lineHeight: '36px',
        },
        scoreDate: {
            fontFamily: fontFamilies.GraphikSemibold,
            fontSize: '10px',
            lineHeight: '15px',
            letterSpacing: '0.5%',
            color: '#002B5A',
            fontWeight: 600,
        },
    },
    components: {
        MuiTypography: { styleOverrides: { root: { textTransform: 'none' } } },
        MuiPaper: { styleOverrides: { root: { boxShadow: 'none' } } },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'concerns' },
                    style: {
                        backgroundColor: '#F66C61',
                        fontFamily: fontFamilies.GraphikSemibold,
                        fontSize: '10px',
                        lineHeight: '28px',
                        color: '#FFF',
                        fontWeight: 600,
                        height: '24px',
                        width: 'auto',
                        '&:hover': {
                            backgroundColor: '#F66C61',
                        },
                    },
                },
            ],
        },
    },
});

export const BadgeMenuItemStyle = {
    ...fontSteelBlueRegular,
    color: '#222',
    lineHeight: '32px',
    fontSize: '13px',
    padding: '6px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    border: 0,
    width: '100%',
    '&:hover': {
        border: 1,
        backgroundColor: '#F3F5FC',
        color: '#007AB9',
        fontWeight: 500,
    },
    ':first-of-type': {
        backgroundColor: '#F3F5FC',
    },
};

export const BadgeThemeWithMenu = createTheme({
    components: {
        MuiBadge: {
            styleOverrides: {
                standard: {
                    backgroundColor: '#68C038',
                },
            },
        },
    },
});

export const homePageTheme = createTheme({
    typography: {
        h1: {
            fontSize: '60px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#002B5A',
        },
        h2: {
            fontSize: '48px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#002B5A',
        },
        h4: {
            fontSize: '36px',
            lineHeight: '48px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#002B5A',
        },
        h5: {
            fontSize: '24px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#007BB8',
        },
        h6: {
            fontSize: '18px',
            fontWeight: fontWeights.Black,
            fontFamily: fontFamilies.GraphikBlack,
            color: '#002B5A',
        },
        subtitle1: {
            fontSize: '32px',
            fontWeight: fontWeights.SemiBold,
            fontFamily: fontFamilies.GraphikSemibold,
            color: '#002B5A',
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: fontWeights.Medium,
            fontFamily: fontFamilies.GraphikMedium,
            color: '#57626A',
        },
        body1: {
            fontSize: '24px',
            fontWeight: fontWeights.SemiBold,
            fontFamily: fontFamilies.GraphikSemibold,
            color: '#002B5A',
        },
        body2: {
            fontSize: '20px',
            lineHeight: '32px',
            fontWeight: fontWeights.Regular,
            fontFamily: fontFamilies.GraphikRegular,
            color: '#57626A',
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h1: {
                    '@media (max-width:1200px)': {
                        fontSize: '52px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '44px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '36px',
                    },
                },
                h2: {
                    '@media (max-width:1200px)': {
                        fontSize: '40px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '32px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '28px',
                    },
                },
                h4: {
                    '@media (max-width:1200px)': {
                        fontSize: '32px',
                        lineHeight: '36px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '28px',
                        lineHeight: '32px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '26px',
                        lineHeight: '28px',
                    },
                },
                h5: {
                    '@media (max-width:900px)': {
                        fontSize: '22px',
                    },
                },
                h6: {
                    '@media (max-width:900px)': {
                        fontSize: '16px',
                    },
                },
                subtitle1: {
                    '@media (max-width:1200px)': {
                        fontSize: '27px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '23px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '18px',
                    },
                },
                body1: {
                    '@media (max-width:1200px)': {
                        fontSize: '22px',
                    },
                    '@media (max-width:900px)': {
                        fontSize: '18px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '16px',
                    },
                },
                body2: {
                    '@media (max-width:1200px)': {
                        fontSize: '18px',
                    },
                    '@media (max-width:600px)': {
                        fontSize: '16px',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
    },
});

export const overlayModalTheme = createTheme(appTheme, {
    components: {
        MuiModalBox: {
            styleOverrides: {
                root: {
                    padding: '0px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
    },
} as ThemeOptions);

export const barChartTheme = {
    seriesStyle: {
        color: '#609EA0',
        negativeColor: '#002B5A',
        borderRadiusTopLeft: '20%',
        borderRadiusTopRight: '20%',
    },
    plotStyling: {},
    chartMargins: {
        spacingLeft: 0,
        spacingRight: 10,
        spacingBottom: 10,
        marginTop: 5,
    },
    plotLabelsOptions: {
        enabled: false,
    },
    xAxisGridStyle: {
        gridLineColor: '#E4E4E4',
        gridLineDashStyle: 'line',
        gridLineWidth: 0.7,
    },
    XAxisOffet: { offset: 170 },
};

export const earningsChartTheme = {
    ...barChartTheme,
    plotStyling: { groupPadding: 0, pointWidth: 10, pointPadding: 0 },
    XAxisOffet: { offset: 140 },
};

export const cashFlowChartTheme = {
    ...barChartTheme,
    plotStyling: { groupPadding: 0, pointWidth: 25, pointPadding: 0 },
    XAxisOffet: { offset: 90 },
};
