import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { TypographyStyle12 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, styled, SxProps } from '@mui/material';

type ItemVariant4Props = {
    onClick?: () => void;
    value: string;
    showQuotes?: boolean;
    buttonSx?: SxProps;
    containerSx?: SxProps;
    textSx?: SxProps;
};

const ItalicFlexText = styled(TypographyStyle12)(() => ({
    fontStyle: 'italic',
    display: 'flex',
}));

function Quotes({ value }: { value: string }) {
    return (
        <>
            <ItalicFlexText key='leftQuote' component='span' width='10px'>
                "
            </ItalicFlexText>
            {value}
            <ItalicFlexText key='rightQuote' component='span'>
                "
            </ItalicFlexText>
        </>
    );
}

export function ItemVariant4({ onClick, value, showQuotes = true, buttonSx, containerSx, textSx }: ItemVariant4Props) {
    return (
        <Box
            sx={{
                paddingLeft: '15px',
                paddingTop: '7px',
                ...containerSx,
            }}>
            <ETFLinkButton
                onClick={onClick}
                content={
                    <TypographyStyle12 sx={{ display: 'flex', ...textSx }}>
                        {showQuotes ? <Quotes value={value} /> : value}
                    </TypographyStyle12>
                }
                sx={{
                    minWidth: '0px',
                    height: '19px',
                    ...buttonSx,
                }}
            />
        </Box>
    );
}
