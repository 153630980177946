import { useEffect, useRef } from 'react';

type UseClickableAreaInputProps = {
    clickOutsideCallback: () => void;
    numberOfAreas: number;
};

type ClickableAreasRefs<T> = Array<T | null>;

type UseClickableAreaOutputProps<T extends HTMLElement> = {
    clickableAreasRefs: React.MutableRefObject<ClickableAreasRefs<T>>;
};

export function useClickableAreas<T extends HTMLElement>({
    clickOutsideCallback,
    numberOfAreas,
}: UseClickableAreaInputProps): UseClickableAreaOutputProps<T> {
    const clickableAreasRefs = useRef<ClickableAreasRefs<T>>(Array.from({ length: numberOfAreas }, () => null));

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            let isClickInsideAnyArea = false;

            // Check if the click is inside any of the areas
            for (const ref of clickableAreasRefs.current) {
                if (ref && ref?.contains(event.target as Node)) {
                    isClickInsideAnyArea = true;
                    break;
                }
            }

            // If the click is outside all areas, trigger the callback
            if (!isClickInsideAnyArea) {
                clickOutsideCallback();
            }
        };

        // Add event listener to document
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [clickableAreasRefs, clickOutsideCallback]);

    return { clickableAreasRefs };
}
