import { createTheme } from "@mui/material";
import { inputFontStyle } from "../shared/StyledFormLabel";
import { fontFamilies } from "@cfra-nextgen-frontend/shared/src/utils/fonts";

// used on Institutional
export const autocompleteThemeVariant1 = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#4A90E2',
                    width: '19px',
                    height: '19px',
                    display: 'block',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&:not(.Mui-focused)': {
                        top: '0',
                    },
                    ...inputFontStyle,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    maxWidth: '200px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    height: '48px',
                    width: '171px',
                },
                popper: {
                    width: '320px !important',
                },
                input: {
                    '&::placeholder': {
                        ...inputFontStyle,
                        color: '#57626A',
                        opacity: '1',
                    },
                    flexBasis: '100%',
                    minWidth: '32px !important',
                    padding: '11px !important',
                    fontSize: '14px',
                    lineHeight: '1.64em',
                },
                paper: {
                    filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    padding: '10px 0 18px',
                    marginTop: '10px',
                },
                listbox: {
                    '::-webkit-scrollbar': {
                        width: '6px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#E4E8F4',
                        backgroundClip: 'padding-box',
                        borderRadius: '25px',
                    },
                    '::-webkit-scrollbar-track-piece': {
                        backgroundColor: '#FFF',
                    },
                },
                option: {
                    display: 'flex',
                    alignItems: 'center',
                    ':hover': {
                        backgroundColor: 'transparent',
                    },
                    padding: '0px 10px !important',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: '14px !important',
                    ':focused': {
                        backgroundColor: 'transparent !important',
                    },
                },
                popupIndicator: {
                    width: '26px',
                    height: '26px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: '10px !important',
                    width: '100%',
                    paddingRight: '0 !important',
                    padding: '0 !important',
                    height: '48px',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    width: '19px !important',
                    height: '19px !important',
                    '& .MuiSvgIcon-root': {
                        width: '19px !important',
                        height: '19px !important',
                        color: '#4A90E2 !important',
                    },
                    marginRight: '9px',
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    color: '#3C3C3C !important',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    label: {
                        fontSize: '14px !important',
                        lineHeight: '1.5em !important',
                    },
                },
            },
        },
    },
});
