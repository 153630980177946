export function getEllipsisStyles(maxNumberOfRowsToShow: number) {
    return {
        display: '-webkit-box',
        WebkitLineClamp: `${maxNumberOfRowsToShow}`,
        wordWrap: 'break-word',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: maxNumberOfRowsToShow,
    };
}
