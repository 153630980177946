import {
    determineGetFiltersData,
    determineSendSingleInfiniteRequest,
    determineSendSingleRequest,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { getData, UseData } from 'utils/api';

const apiName = ApiNames.Research;

export const getFiltersData = determineGetFiltersData({ UseData, apiName, filtersPath: 'screener-filters' });
export const sendSingleRequest = determineSendSingleRequest({ UseData });
export const sendSingleInfiniteRequest = determineSendSingleInfiniteRequest({ getData });
