import { Heading, Image, View, Text } from '@aws-amplify/ui-react';
import CFRALogo from '../../../assets/images/cfra_logo.svg';

interface InstitutionalLogoProps {
    heading?: string;
    description?: string;
}

export const InstitutionalLogo = ({ heading = 'Institutional', description='' }: InstitutionalLogoProps) => {
    return (
        <View textAlign='center'>
            <Image alt='Company logo' src={CFRALogo} />
            <Heading 
                level={1} 
                style={{ marginBottom: '5px', fontFamily: 'GraphikBold', color: '#3B99A7', fontSize: '52px', marginTop: '10px', letterSpacing: '-0.035em' }}
            >
                {heading}
            </Heading>
            <Text 
                style={{ marginBottom: '1rem', fontFamily: 'GraphikRegular', fontSize: '15px', lineHeight: '1.7em' }}
            >
               {description}
            </Text>
        </View>
    );
};
