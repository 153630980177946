import { Auth } from '@aws-amplify/auth';
import {
    AUTH_REGION,
    AUTH_USER_POOL_ID,
    AUTH_USER_POOL_WEB_CLIENT_ID,
    BASE_API_URL,
    USER_MANAGEMENT_URL,
} from '@cfra-nextgen-frontend/shared/src/config';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { Amplify } from 'aws-amplify';

const awsConfigAuth = {
    region: AUTH_REGION,
    userPoolId: AUTH_USER_POOL_ID,
    userPoolWebClientId: AUTH_USER_POOL_WEB_CLIENT_ID,
};

const endpointsConfig = [
    {
        name: ApiNames.Research,
        endpoint: BASE_API_URL,
        custom_header: async () => {
            return {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Content-Type': 'application/json',
            };
        },
    },
    {
        name: ApiNames.UserManagement,
        endpoint: USER_MANAGEMENT_URL,
        custom_header: async () => {
            return {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                'Content-Type': 'application/json',
            };
        },
    }
];

export const configureAmplify = () => {
    Amplify.configure({
        Auth: awsConfigAuth,
        API: { endpoints: endpointsConfig },
    });
};
