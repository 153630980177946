import { Layout } from '@cfra-nextgen-frontend/shared';
import { CFRARoundedSelect } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { SavedItemListResponseTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { PreferenceType } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApplicationType, UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { getSavedItemsByTypeConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import React, { useImperativeHandle } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type WatchlistRef = {
    refetchAllWatchlist: (id?: number) => void;
};

type WatchlistSelectProps = {
    selectedWatchlist: SavedItemTypes | undefined;
    setSelectedWatchlist: React.Dispatch<React.SetStateAction<SavedItemTypes | undefined>>;
};

export const WatchlistSelect = React.forwardRef<WatchlistRef, WatchlistSelectProps>(
    ({ selectedWatchlist, setSelectedWatchlist }, ref) => {
        const { state } = useLocation();
        const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
        const [userWatchlist, setUserWatchlist] = useState<SavedItemTypes[]>();
        const { userPreferences, setPreferences } = useUserPreferences({ applicationType: ApplicationType.InstitutionalPortal });

        if (!sendSingleRequest) {
            throw new Error('sendSingleRequest is not defined');
        }
        const getAllWatchlistQry = sendSingleRequest<SavedItemListResponseTypes>(
            { types: [UserSavedItemsLookupID.InstitutionalWatchlist] },
            getSavedItemsByTypeConfig,
        );

        const refetchAllWatchlist = async (id?: number) => {
            const { data, isFetching, isError } = await getAllWatchlistQry.refetch();

            if (isFetching || isError) {
                return;
            }

            const currentSelection = data?.data?.find((company) => company.id === id);

            if (currentSelection) {
                setSelectedWatchlist(currentSelection);
                return;
            }

            setSelectedWatchlist(
                data?.data?.sort((a: SavedItemTypes, b: SavedItemTypes) => {
                    return a.name.localeCompare(b.name);
                })?.[0],
            );
        };

        useImperativeHandle(ref, () => ({
            refetchAllWatchlist,
        }));

        useEffect(() => {
            if (
                !getAllWatchlistQry ||
                getAllWatchlistQry.isRefetching ||
                getAllWatchlistQry.isError ||
                !getAllWatchlistQry?.data?.data
            ) {
                return;
            }

            const allUserWatchlists = getAllWatchlistQry?.data?.data?.sort((a: SavedItemTypes, b: SavedItemTypes) => {
                return a.name.localeCompare(b.name);
            });

            setUserWatchlist(allUserWatchlists);

            if (selectedWatchlist || !userPreferences) {
                return;
            }

            if(state?.homepageViewedWatchlistId){
                const homepageViewedWatchlistId = getAllWatchlistQry?.data?.data?.find(
                    (p: any) => p.id === state?.homepageViewedWatchlistId,
                );
                if(homepageViewedWatchlistId){
                    setSelectedWatchlist(homepageViewedWatchlistId);
                    window.history.replaceState({}, '')
                    return;
                }
            }

            if (!userPreferences?.preferences?.watchlist?.last_viewed_id) {
                // if no user preferences saved, get first in list as selected watchlist
                setSelectedWatchlist(allUserWatchlists[0]);
                return;
            }

            const lastViewedWatchlist = getAllWatchlistQry?.data?.data?.find(
                (p: any) => p.id === userPreferences?.preferences?.watchlist?.last_viewed_id,
            );

            // get last viewed watchlist
            if (lastViewedWatchlist) {
                setSelectedWatchlist(lastViewedWatchlist);
                return;
            }

            // Default to first element in list
            setSelectedWatchlist(allUserWatchlists[0]);
        }, [getAllWatchlistQry, selectedWatchlist, userPreferences, setSelectedWatchlist, state?.homepageViewedWatchlistId]);

        if (userWatchlist === undefined && selectedWatchlist === undefined) {
            return (
                <div style={{ width: '200px', marginLeft: '10px', marginTop: '10px' }}>
                    <Layout.Skeleton height='40px' />
                </div>
            );
        }

        if (!userWatchlist || userWatchlist.length === 0 || !selectedWatchlist) {
            return <></>;
        }

        return (
            <CFRARoundedSelect
                selectItems={userWatchlist.map((watchlistItem: SavedItemTypes) => watchlistItem.name)}
                handleSelectionChange={(e) => {
                    const _selectedWatchlist = userWatchlist.find(
                        (p: any) => p.name.toLowerCase() === String(e.target?.value)?.toLowerCase(),
                    );

                    if (!_selectedWatchlist) {
                        return;
                    }

                    setSelectedWatchlist(_selectedWatchlist);
                    setPreferences(PreferenceType.WatchlistLastVisited, _selectedWatchlist.id);
                }}
                currentSelection={selectedWatchlist?.name}
            />
        );
    },
);
