import { Layout } from '@cfra-nextgen-frontend/shared';
import { defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGirdCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { noResultText } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { CardHeaderVariant1 } from '@cfra-nextgen-frontend/shared/src/components/CardHeaders/CardHeaderVariant1';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { useSsrDataSource } from '@cfra-nextgen-frontend/shared/src/hooks/useSsrDataSource';
import { SetUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { PreferenceType, UserPreferences } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApiNames, RequestTypes, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { Box, createTheme, Stack, ThemeProvider } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { getFilterComponent, getMenuItems } from 'features/companyProfile/components/CompanyHeadlines';
import { MenuItemState } from 'features/companyProfile/types/company';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { CompanyReportsContainerStyles, gridLoadingContainerStyles } from './CompanyReports';
import { getScreenerInfiniteRequestParamsConfig } from './InfiniteOptions/screener';

const size = 25;

const screenerInfiniteRequestParamsConfig = {
    queryKeyFirstElement: 'industryResearchScreenerQuery',
    ...getScreenerInfiniteRequestParamsConfig<'research'>(size, 'research'),
};

type IndustryResearchProps = {
    userPreferences: UserPreferences;
    setPreferences: SetUserPreferences;
    gicsCode: string[];
};

export function IndustryResearch(props: IndustryResearchProps) {
    const { userPreferences, setPreferences, gicsCode } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [selectedGicsSector, setSelectedGicsSector] = useState<string[] | undefined>();

    useEffect(() => {
        setSelectedGicsSector([]);
    }, [gicsCode]);

    useEffect(() => {
        if (selectedGicsSector === undefined && userPreferences) {
            setSelectedGicsSector(userPreferences?.preferences?.homepage?.industry_research_gics_sector || []);
        }
    }, [selectedGicsSector, userPreferences, userPreferences?.preferences?.homepage?.industry_research_gics_sector]);

    const industryResearchQryProps: SearchByParams = useMemo(() => {
        let gicsCodeFilter = {};
        if (selectedGicsSector?.length || gicsCode.length) {
            gicsCodeFilter = {
                'research_report_industry.gics_code': {
                    values: [...(selectedGicsSector?.map((v) => `${v}*`) || []), ...gicsCode],
                },
            };
        }

        return {
            securityType: 'research',
            view: 'industry_research',
            orderBy: 'research_report.publish_timestamp',
            sortDirection: 'desc',
            requestBody: {
                filters: {
                    values: {
                        'insights.research_report.research_type_id': {
                            values: [ResearchTypeId.FrsIndustryReport],
                        },
                        ...gicsCodeFilter,
                    },
                },
            },
        };
    }, [gicsCode, selectedGicsSector]);

    const industryResearchQry = sendSingleRequest?.(
        { size, ...industryResearchQryProps },
        {
            path: 'research/screener',
            ...screenerInfiniteRequestParamsConfig,
        },
    ) as UseQueryResult<ScreenerResearchData>;

    const Header = useMemo(() => {
        return (
            <CardHeaderVariant1
                title='Industry Research'
                containerStyles={{ paddingX: '10px' }}
                slot1={
                    selectedGicsSector && (
                        <IndustrySectorFilter
                            selectedGicsSector={selectedGicsSector || []}
                            onChange={(v: string[]) => {
                                setPreferences(PreferenceType.HomepageIndustryResearchGicsSector, v);
                                setSelectedGicsSector(v);
                            }}
                            gicsCode={gicsCode}
                        />
                    )
                }
                slot1Divider={false}
            />
        );
    }, [gicsCode, selectedGicsSector, setPreferences]);

    return (
        <ThemeProvider theme={createTheme()}>
            <Stack sx={CompanyReportsContainerStyles}>
                <Grid container>{Header}</Grid>

                {!industryResearchQry?.isFetched && (
                    <Box sx={gridLoadingContainerStyles}>
                        <Layout.Skeleton height='10px' />
                    </Box>
                )}

                {industryResearchQry?.isFetched &&
                    ((industryResearchQry?.data?.results?.research || []).length === 0 ? (
                        <Box sx={gridLoadingContainerStyles}>
                            <ResearchDescriptionText>{noResultText}</ResearchDescriptionText>
                        </Box>
                    ) : (
                        <IndustryResearchTable
                            industryResearchQryProps={industryResearchQryProps}
                            industryResearchQry={industryResearchQry}
                        />
                    ))}
            </Stack>
        </ThemeProvider>
    );
}

export function IndustryResearchTable(props: { industryResearchQry: any; industryResearchQryProps: any }) {
    const { industryResearchQry, industryResearchQryProps } = props;
    const gridRef = useRef<AgGridReact>(null);

    const { getRows } = useSsrDataSource({
        pageSize: size,
        resultsKey: 'research',
        reqParams: industryResearchQryProps,
        metadataFields: industryResearchQry?.data?._metadata.fields || [],
        reqConfig: { path: 'research/screener', ...screenerInfiniteRequestParamsConfig },
        rowDataInterceptor,
    });

    const { minWidths, customFlexibleColumns, columnDefs }: ReturnType<typeof extractFromScreenerData> = useMemo(() => {
        if (!industryResearchQry.data) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        return extractFromScreenerData({
            screenerData: industryResearchQry.data,
            cardName: 'Industry Research',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
        });
    }, [industryResearchQry?.data]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const TableView = useMemo(() => {
        return (
            <AgGirdCard
                ref={gridRef}
                useSSRMode={true}
                getRowID={getRowID}
                SSRrowsToFetch={size}
                embedFullWidthRows={true}
                columnDefs={columnDefs}
                gridTheme='cp-grid-theme'
                unlimitedCalculatedHeight
                SSRDataSource={{
                    getRows: (params) => getRows(params, industryResearchQryProps),
                }}
                maxNumberOfRowsToDisplay={5}
                showDefaultExportButton={false}
                suppressRowClickSelection={true}
                customFlexibleColumns={customFlexibleColumns}
                containerStyles={{ marginTop: 0, paddingLeft: '10px' }}
                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
            />
        );
    }, [columnDefs, customFlexibleColumns, getResizableMinWidthForColumn, getRows, industryResearchQryProps]);

    return TableView;
}

function rowDataInterceptor(reports: any) {
    if (reports) {
        for (let i = 0; i < reports.length; i++) {
            for (let j = 0; j < reports[i].research_report_industry.length; j++) {
                let lookup = reports[i].research_report_industry[j].lookups.lookup_gics;

                lookup.value__gics_industry =
                    lookup.value__gics_sub_industry ||
                    lookup.value__gics_industry ||
                    lookup.value__gics_industry_group ||
                    lookup.value__gics_sector;
            }
        }
    }
    return reports || [];
}

type IndustrySectionFilterProps = {
    gicsCode: string[];
    selectedGicsSector: string[];
    onChange: (v: string[]) => void;
};

export function IndustrySectorFilter(props: IndustrySectionFilterProps) {
    const { selectedGicsSector, onChange, gicsCode = [] } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [menuItems, setMenuItems] = useState<Array<MenuItemState>>([]);
    const maxDisplayItemsCount = 6;

    const filter = 'insights.research_report_industry.lookups.lookup_gics.value__gics_sector';

    useEffect(() => {
        setMenuItems([]);
    }, [gicsCode]);

    const industrySectorListQry = sendSingleRequest?.(
        {
            path: 'research',
            securityType: 'research',
            includeData: true,
            includeMetadata: false,
            filters: filter,
            requestBody: {
                filters: {
                    values: {
                        'insights.research_report.research_type_id': {
                            values: [ResearchTypeId.FrsIndustryReport],
                        },
                        ...(gicsCode.length > 0
                            ? {
                                  'research_report_industry.gics_code': {
                                      values: gicsCode,
                                  },
                              }
                            : {}),
                    },
                },
            },
            config: {},
        },
        {
            apiName: ApiNames.Research,
            requestType: RequestTypes.POST,
            path: 'research/screener-filters',
            queryKeyFirstElement: 'getIndustrySectorList',
        },
    ) as UseQueryResult<FiltersData>;

    useEffect(() => {
        if (
            !industrySectorListQry?.isLoading &&
            industrySectorListQry?.isSuccess &&
            industrySectorListQry?.data?.data
        ) {
            if (menuItems.length === 0) {
                let items: any[] = [];
                (industrySectorListQry?.data?.data?.[filter]?.items || []).forEach(function (item) {
                    const existing = items.filter((v, i) => v.value === item.value);
                    if (existing.length) {
                        const existingIdx = items.indexOf(existing[0]);
                        items[existingIdx].count += item.count;
                    } else {
                        items.push({ ...item, key: item.key.toString().substring(0, 2) });
                    }
                });

                items = _.orderBy(items, 'value', 'asc');
                setMenuItems(items.map((d) => ({ key: d.key, name: d.value, count: d.count || 0, isChecked: false })));
            }
        }
    }, [
        selectedGicsSector,
        industrySectorListQry?.data,
        industrySectorListQry?.isSuccess,
        menuItems.length,
        industrySectorListQry?.isLoading,
    ]);

    useEffect(() => {
        if (menuItems.length) {
            const updatedMenu = menuItems.map((item) => ({
                ...item,
                isChecked: selectedGicsSector.includes(item.key.toString()),
            }));
            if (!_.isEqual(menuItems, updatedMenu)) {
                setMenuItems(updatedMenu);
            }
        }
    }, [menuItems, selectedGicsSector]);

    const handleOnChange = useCallback(
        (_e: any, selectedItem: { key: string }) => {
            const updatedMenu = menuItems.map((item) => {
                if (item.key === selectedItem.key) {
                    item.isChecked = !item.isChecked;
                }
                return item;
            });

            setMenuItems(updatedMenu);
            onChange(updatedMenu.filter((d) => d.isChecked).map((d) => d.key?.toString()));
        },
        [menuItems, onChange],
    );

    return (
        <ThemeProvider theme={createTheme()}>
            <Box pr='5px'>
                {getFilterComponent(
                    getMenuItems(menuItems, handleOnChange),
                    menuItems.filter((item) => item.isChecked).length,
                    maxDisplayItemsCount,
                ) || <></>}
            </Box>
        </ThemeProvider>
    );
}
