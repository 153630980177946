import { TypographyStyle5 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { tooltipThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { createTheme, Grid, IconButton, SxProps, ThemeProvider, Tooltip } from '@mui/material';

export const pillsTooltipTheme = createTheme(tooltipThemeV3, {
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    padding: '14px 16px 14px 16px',
                    marginTop: '0px !important',
                },
            },
        },
    },
});

export function PillVariant1({
    containerSx,
    buttonSx,
    content,
    onClick,
    tooltipContent,
}: {
    containerSx?: SxProps;
    buttonSx?: SxProps;
    content: string | React.ReactNode;
    onClick?: () => void;
    tooltipContent?: React.ReactNode;
}) {
    return (
        <Grid
            item
            sx={{
                backgroundColor: '#F3F5FC',
                borderRadius: '20px',
                ...containerSx,
            }}>
            <ThemeProvider theme={pillsTooltipTheme}>
                <Tooltip
                    title={tooltipContent}
                    placement={'bottom-start'}
                    sx={{
                        tooltip: {
                            padding: '14px 16px',
                        },
                    }}>
                    <IconButton
                        onClick={onClick}
                        sx={{
                            padding: '8px 12px',
                            borderRadius: '20px',
                            minWidth: '55px',
                            ...buttonSx,
                        }}>
                        {typeof content === 'string' ? (
                            <TypographyStyle5
                                sx={{
                                    lineHeight: 1,
                                }}>
                                {content}
                            </TypographyStyle5>
                        ) : (
                            content
                        )}
                    </IconButton>
                </Tooltip>
            </ThemeProvider>
        </Grid>
    );
}
