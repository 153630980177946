import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { DataItem, IMetadataFields } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { IServerSideGetRowsParams } from 'ag-grid-community';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { SendSingleRequestConfig } from '../components/Screener/api/screener';
import { getSortFields } from '../components/Screener/utils/ssr';
import { SearchByParams } from '../utils/api';

const DEFAULT_PAGE_SIZE = 25;

export type SsrDataSourceProps = {
    reqParams?: SearchByParams;
    reqConfig: SendSingleRequestConfig;
    metadataFields: IMetadataFields[];
    resultsKey: string;
    pageSize?: number;
    rowDataInterceptor?: (data: any) => any
};

type SsrDataSourceReturnTypes = {
    getRows: (params: IServerSideGetRowsParams, queryParams: SearchByParams) => void;
    setRequestParams: (params: SearchByParams) => void;
};

export function useSsrDataSource(props: SsrDataSourceProps): SsrDataSourceReturnTypes {
    const { reqParams = {}, reqConfig, metadataFields, resultsKey, pageSize = DEFAULT_PAGE_SIZE, rowDataInterceptor } = props;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const getRowsParamsRef = useRef<IServerSideGetRowsParams>();
    const [_metadataFields] = useState<IMetadataFields[]>(metadataFields);
    const [_requestParams, _setRequestParams] = useState<SearchByParams>({ ...reqParams, config: { enabled: false } })

    useEffect(() => {
        if (!sendSingleRequest) {
            throw new Error('useSsrDataSource hook exception. sendSingleRequest is not provided.');
        }
    }, [sendSingleRequest]);


    const ssrQuery = sendSingleRequest?.({ ..._requestParams }, reqConfig) as UseQueryResult<DataItem>;

    const getRows = useCallback((params: IServerSideGetRowsParams<any, any>, queryParams: SearchByParams) => {
        getRowsParamsRef.current = params

        const sortParams = getSortFields(params.request.sortModel, _metadataFields)

        const from = params.request.startRow
        const size = (params.request.endRow || 0) - (params.request.startRow || 0) || pageSize;

        _setRequestParams((prevState) => ({
            ...prevState,
            ...queryParams,
            from,
            size,
            ...sortParams,
            config: {
                ...prevState.config,
                enabled: true
            }
        }))
    }, [_metadataFields, pageSize]);


    const setRequestParams = useCallback((params: SearchByParams) => {
        if (JSON.stringify(_requestParams) !== JSON.stringify({ ..._requestParams, ...params })) {
            _setRequestParams((prevState) => ({ ...prevState, ...params }))
        }
    }, [_requestParams]);


    useEffect(() => {
        if (getRowsParamsRef.current?.success && !ssrQuery?.isLoading && ssrQuery.isFetched && ssrQuery?.data) {
            let rowData = ssrQuery.data?.results?.[resultsKey] || []
            if (rowDataInterceptor) {
                rowData = rowDataInterceptor(rowData)
            }
            getRowsParamsRef.current.success({ rowData })
        }
    }, [resultsKey, ssrQuery.data, ssrQuery.isFetched, ssrQuery?.isLoading, _requestParams, rowDataInterceptor]);

    return { getRows, setRequestParams }
}