import { Popover, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { PopupContentProps } from '../../types/popupContent';
import { useNavigate } from 'react-router-dom';

const theme = createTheme(breakpointsTheme, {
    components: {
        MuiTypography: {
            styleOverrides: {
                body2: {
                    fontSize: '12px',
                    fontFamily: 'GraphikRegular',
                    color: '#3C3C3C',
                    lineHeight: '1.8',
                    paddingTop: '15px',
                },
                h6: {
                    fontSize: '15px',
                    fontFamily: fontFamilies.GraphikSemibold,
                    color: '#5A5A5A',
                    lineHeight: '2',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    background: 'transparent',
                    zIndex: 1500,
                    boxShadow: 'none',
                },
            },
        },
    },
});

const Spacer = styled(Box)({
    height: '17px',
    backgroundColor: 'transparent',
    marginTop: '-20px',
});

const PopupContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    gap: '18px',
    padding: '27px 34px 36px',
    width: '553px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    '&:hover': {
        cursor: 'pointer',
    },
}));

const PopupLeftSection = styled(Box)(({ theme }) => ({
    flex: 1,
    minWidth: '245px',
}));

const PopupRightSection = styled(Box)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
}));

const StyledList = styled(List)({
    padding: '8px 0 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '4px',
});
const StyledListItem = styled(ListItem)({
    gap: '12px',
    padding: '9px 19px 10px 19px;',
    borderRadius: '5px',
    '&:hover': {
        background: '#F3F5FC',
    },
    '&:hover .MuiTypography-root': {
        color: '#007AB9',
    },
});

const StyledListItemText = styled(ListItemText)({
    margin: 0,
    '& .MuiTypography-root': {
        color: '#002B5A',
        fontFamily: 'GraphikMedium',
        fontSize: '13.5px',
    },
});

const Icon = styled('img')(() => ({
    width: '20px',
    height: '20px',
}));

const PopupContent: React.FC<PopupContentProps> = ({ popup, anchorEl, open, onClose, onMouseEnter, onMouseLeave }) => {
    const navigate = useNavigate();

    return (
        <ThemeProvider theme={theme}>
            {open && (
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                        },
                    }}>
                    <Box
                        sx={{
                            position: 'relative',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}>
                        {<Spacer />}
                        <Box
                            sx={{
                                padding: '10px',
                            }}>
                            <PopupContainer>
                                <PopupLeftSection sx={{}}>
                                    <Typography variant='h6' component='h6'>
                                        {popup.title}
                                    </Typography>
                                    <Typography variant='body2' component='p'>
                                        {popup.description}
                                    </Typography>
                                </PopupLeftSection>
                                <PopupRightSection>
                                    <Typography variant='h6' component='h6' sx={{ marginLeft: '16px' }}>
                                        {popup.links_title}
                                    </Typography>
                                    <StyledList>
                                        {popup.links.map((link, index) => (
                                            <StyledListItem
                                                key={index}
                                                onClick={() => (navigate(link.href))}>
                                                <Icon src={link.icon} alt={link.name} />
                                                <StyledListItemText primary={link.name}>{link.name}</StyledListItemText>
                                            </StyledListItem>
                                        ))}
                                    </StyledList>
                                </PopupRightSection>
                            </PopupContainer>
                        </Box>
                    </Box>
                </Popover>
            )}
        </ThemeProvider>
    );
};

export default PopupContent;
