import { useCallback, useState } from 'react';

type UseTextCutterInputProps = {
    text: string;
    maxTextLength: number;
};

type UseTextCutterOutputProps = {
    lastIndex: number;
    cutNextWord: () => void;
};

const getLastIndexOfSpace = (text: string, maxTextLength: number): number => {
    return text.lastIndexOf(' ', maxTextLength) + 1;
};

export function useTextCutter({ text, maxTextLength }: UseTextCutterInputProps): UseTextCutterOutputProps {
    const [lastIndex, setLastIndex] = useState<number>(getLastIndexOfSpace(text, maxTextLength));

    const cutNextWord = useCallback(() => {
        setLastIndex((prevIndex) => getLastIndexOfSpace(text, prevIndex - 2));
    }, [text]);

    return { cutNextWord, lastIndex };
}
