import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { useLinkGetter, UseLinkGetterOutputProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { Box, CssBaseline, Theme, ThemeProvider, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';

export function ItemVariant7<T>({
    firstRowItems,
    innerRef,
    handleOuterSetRequestParamsProps,
    secondRowText,
    useLinkGetterParams,
    itemTheme,
    hasModalFeature,
}: {
    firstRowItems: Array<string | undefined>;
    innerRef?: React.Ref<HTMLButtonElement>;
    handleOuterSetRequestParamsProps: (
        setRequestParamsProps: UseLinkGetterOutputProps<T>['setRequestParamsProps'],
    ) => void;
    secondRowText: string;
    useLinkGetterParams: Parameters<typeof useLinkGetter<T>>;
    itemTheme: Theme;
    hasModalFeature?: boolean;
}) {
    const { setRequestParamsProps, extractedLink } = useLinkGetter<T>(...useLinkGetterParams);
    const [modalOpen, setModalOpen] = useState(false);

    const modalRef = useRef(null);

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleOpenModal = useCallback(() => {
        setModalOpen(true);
    }, []);

    const onClick = useMemo(() => {
        return () => {
            handleOuterSetRequestParamsProps(setRequestParamsProps);
            if (hasModalFeature) {
                handleOpenModal();
            }
        };
    }, [setRequestParamsProps, hasModalFeature, handleOpenModal, handleOuterSetRequestParamsProps]);

    return (
        <>
            <ThemeProvider theme={itemTheme}>
                <CssBaseline />
                <ButtonBase ref={innerRef} onClick={onClick}>
                    {/* First Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            paddingBottom: '3px',
                        }}>
                        {firstRowItems.map(
                            (item, index) =>
                                item && (
                                    <Fragment key={item}>
                                        <Typography variant={index === 0 ? 'researchType' : 'caption'} key={item}>
                                            {item}
                                        </Typography>
                                        {index < firstRowItems.length - 1 && <DividerStyle1 />}
                                    </Fragment>
                                ),
                        )}
                    </Box>
                    {/* Second Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-start',
                        }}>
                        <Typography variant='headlineTitle'>{secondRowText}</Typography>
                    </Box>
                </ButtonBase>
            </ThemeProvider>
            {hasModalFeature && extractedLink && (
                <ETFModal
                    date={firstRowItems[0]}
                    getOpenComponent={() => <></>}
                    title={secondRowText}
                    modalBoxStyles={{
                        display: 'block',
                        maxWidth: '1400px',
                        maxHeight: 'none',
                        overflowY: 'hidden',
                    }}
                    descriptionStyle={{
                        boxShadow: 'none',
                    }}
                    titleStyle={{
                        boxShadow: 'none',
                        fontSize: '18px',
                        color: '#002B5A',
                        lineHeight: '1.56em',
                    }}
                    dateStyle={{
                        boxShadow: 'none',
                        color: '#A09F9F',
                        fontSize: '12px',
                        paddingBottom: '0',
                        lineHeight: '1.5em',
                    }}
                    zIndex={5000}
                    externalOpenModal={modalOpen}
                    keepContentOnClose={false}
                    callbackOnClose={handleCloseModal}
                    ref={modalRef}
                    htmlContentUrl={extractedLink}
                />
            )}
        </>
    );
}
