import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { TopNavigation } from 'features/topNavigation';
import { Outlet } from 'react-router-dom';

export function AuthenticatedRoutesWrapper() {
    const { userEntitlements } = useUserEntitlements();
    return (
        <>
            <TopNavigation userEntitlements={userEntitlements}/>
            <Outlet />
        </>
    );
}
