import { Box, styled, Tabs, TabsProps } from '@mui/material';

export const StyledTabsContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    top: '-3px',
    backgroundColor: '#FFF',
    padding: '18px 22px',
    paddingRight: '8px',
    borderRadius: '0 0 10px 10px',
}));

export const StyledTabs = styled(({ value, onChange, children, ...rest }: TabsProps) => (
    <Tabs value={value} onChange={onChange} {...rest}>
        {children}
    </Tabs>
))(({ theme }) => ({
    backgroundColor: '#E4E8F4',
    padding: '10px 10px 0 10px',
    width: '100%',
}));
