import { InfiniteOptions } from '@cfra-nextgen-frontend/shared/src/components/InfiniteScroll/InfiniteOptions';
import { ItemVariant1 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant1';
import { GetOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types';
import {
    AutoSuggestCompany,
    Hit,
    SearchResult,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch';
import { GetOptionsComponentProps } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import {
    getSuggesterInfiniteOptionsProps,
    getSuggesterInfiniteRequestParamsConfig,
} from 'features/home/components/InfiniteOptions/suggester';

type Props = {
    size: number;
    externalOnClickCallback?: (data: Hit<AutoSuggestCompany>) => void;
    passNavigateUrl?: boolean;
    getOptionsContainer: GetOptionsContainer;
    queryKeyFirstElementPostfix?: string;
};

export const determineGetCompanySuggesterOptions = ({
    size,
    externalOnClickCallback,
    passNavigateUrl = true,
    getOptionsContainer,
    queryKeyFirstElementPostfix,
}: Props) => {
    const scrollThresholdPx = Math.round((62 * size) / 3); // take 33% of rendered size, assume each item height is 62px

    return function ({
        inputValue,
        resetOptionsRef,
        onLinkClickCallback,
        titleWithScrollableAreaProps,
        outerContainerRef,
    }: GetOptionsComponentProps) {
        return (
            <InfiniteOptions<SearchResult<AutoSuggestCompany>, Hit<AutoSuggestCompany>>
                outerContainerRef={outerContainerRef}
                OptionsContainer={getOptionsContainer(titleWithScrollableAreaProps)}
                ref={resetOptionsRef}
                infiniteRequestParams={[
                    {
                        q: inputValue,
                        path: 'company/suggester',
                        config: {
                            enabled: Boolean(inputValue),
                        },
                        size,
                    },
                    {
                        queryKeyFirstElement: `companySuggesterQuery${queryKeyFirstElementPostfix}`,
                        ...getSuggesterInfiniteRequestParamsConfig<AutoSuggestCompany>(size),
                    },
                ]}
                outerSetOptionsCondition={Boolean(inputValue)}
                RowComponent={({ rowData }) => (
                    <ItemVariant1
                        leftPartLeftText={rowData._source['company_security.security_trading.ticker_symbol']}
                        leftPartRightText={
                            rowData._source['company_security.security_trading.exchange_lid.exchange_code']
                        }
                        navigateUrl={
                            passNavigateUrl
                                ? `/company-profile/company/${rowData._source['company_security.company.cfra_company_id']}`
                                : undefined
                        }
                        onLinkClickCallback={() => {
                            onLinkClickCallback?.();
                            externalOnClickCallback?.(rowData);
                        }}
                        rightPartText={rowData._source['company_security.company.company_name']}
                    />
                )}
                rowsKeyPrefix={inputValue}
                noResultsFoundBoxSxProps={{ paddingLeft: '8px' }}
                {...getSuggesterInfiniteOptionsProps<AutoSuggestCompany>()}
                scrollThresholdPx={scrollThresholdPx}
            />
        );
    };
};
