import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { SendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import {
    useFiltersForm,
    UseFiltersFormExternalInputProps,
    UseFiltersFormInputProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { homePageTheme } from 'components/themes/theme';

export function FiltersSection(
    props: UseFiltersFormExternalInputProps & {
        getFiltersJsx: UseFiltersFormInputProps['getFiltersJsx'];
        filtersRequestParams: Parameters<SendSingleRequest>;
    },
) { 
    const { filtersFormJsx, filtersChipPanelJsx } = useFiltersForm(props);

    return (
        <Box
            component='section'
            sx={{
                backgroundColor: '#FFFFFF',
                maxWidth: '100%',
                width: '100%',
                margin: '0 auto',
                padding: '30px 24px',
                marginBottom: '48px',
                borderRadius: '10px',
            }}>
            <ThemeProvider theme={homePageTheme}>
                <Grid sx={{ width: '100%', height: '100%' }}>
                    {filtersFormJsx}
                    {filtersChipPanelJsx}
                </Grid>
            </ThemeProvider>
        </Box>
    );
}
