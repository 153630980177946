import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { createTheme } from '@mui/material';

const fontStyle = {
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '26px',
    color: '#0B2958',
};

export const themeVariant1 = createTheme(breakpointsTheme, {
    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    paddingRight: '0 !important',
                    '&:before': {
                        borderBottom: 'none !important',
                    },
                    '&:after': {
                        borderBottom: 'none',
                    },
                },
                MuiFocused: {
                    styleOverrides: {
                        borderBottom: 'none !important',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    ...fontStyle,
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: '14px',
                    borderBottom: 'none',
                    paddingTop: '5px !important',
                    '&:before': {
                        borderBottom: 'none',
                    },
                    '&:after': {
                        borderBottom: 'none',
                    },
                    '&:focus': {
                        borderBottom: 'none',
                    },
                    '&:focus:before': {
                        borderBottom: 'none',
                    },
                    '&:focus:after': {
                        borderBottom: 'none',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: '#3C3C3C',
                    width: '100%',
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    width: '100%',
                    height: '44px',
                    borderRadius: '35px',
                    background: '#F3F5FC',
                    maxWidth: '1003px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    border: '1px solid #5792C3',
                    paddingLeft: '26px',
                    paddingRight: '22px',
                    position: 'relative',
                },
            },
        }
    },
});

export const themeVariant2 = createTheme(breakpointsTheme, {
    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottom: '1px dotted #E4E4E4 !important',
                    },
                    '&:after': {
                        borderBottom: '1px dotted #007AB9 !important',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '46px',
                    fontFamily: fontFamilies.GraphikMedium,
                    fontSize: '23px',
                    color: '#9CA1B0',
                    padding: '0px',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
    },
});

export const themeVariant3 = createTheme(breakpointsTheme, {
    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottom: 'none !important',
                    },
                    '&:after': {
                        borderBottom: 'none !important',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '36px',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: '13.5px',
                    color: '#3C3C3C',
                    padding: '0px',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
    },
});