import { ETFDivider } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFDivider';
import { PopupVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Popup/PopupVariant1';
import { TypographyStyle20 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';

const TEXT_TO_PDF_NAME: Record<string, string> = {
    'CFRA Earnings Score and Cash Flow Score Study': 'CFRA Earnings Score and Cash Flow Score Study.pdf',
    'Analysis of CFRA Earnings Score Model Performance': 'Analysis of CFRA Earnings Score Model Performance.pdf',
    'How Does the Corporate Bond Market Value Capital Investments and Accruals?':
        'How Does the Corporate Bond Market Value Capital Investments and Accruals.pdf',
    'Accrual Reliability, Earnings Persistence and Stock Prices':
        'Accrual Reliability, Earnings Persistence and Stock Prices.pdf',
    'Criterion Research Group High Yield Study': 'Criterion Research Group High Yield Study.pdf',
    'Low Accrual Companies Likely to Substantially Out-Perform High Accrual Companies':
        'Low Accrual Companies Likely to Substantially Out-Perform High Accrual Companies.pdf',
    'CFRA Score Model - Backtest Results': 'CFRA Score Model - Backtest Results.pdf',
};

const TEXT_TO_PDF_URL = Object.keys(TEXT_TO_PDF_NAME).reduce((acc, key) => {
    acc[key] = `pdfs/${TEXT_TO_PDF_NAME[key]}`;
    return acc;
}, {} as Record<string, string>);

const commonPopupProps = {
    title: 'About CFRA Scores',
    titleRight: 'Background Material & Whitepapers',
    textToFilePath: TEXT_TO_PDF_URL,
};

export function ScoresInfoBanner() {
    return (
        <ETFDivider
            header='Understanding CFRA Scores'
            headerStyle={{ fontSize: '14px', lineHeight: '24px', paddingTop: '0px', paddingBottom: '5px' }}
            containerStyles={{
                padding: '18px 24px',
            }}
            descriptionComponent={
                <TypographyStyle20
                    component='div'
                    sx={{
                        lineHeight: '22px',
                    }}>
                    Access quantitative assessments for over 30,000 companies, featuring detailed insights into Cash
                    Flow and Earnings Scores.
                    <br />
                    <PopupVariant1
                        {...commonPopupProps}
                        linkText='Cash Flow Scores'
                        description='The Cash Flow Score is intended to identify companies with strong core cash flow relative to revenues. Core cash flow represents cash flow generated by the core operations of the firm as opposed to reported cash flow from operations, which can included one-time or unsustainable cash inflows and outflows.  The idea of core cash flow is to capture cash receipts from product sales, cash payments for inventory and operating expenses, and interest costs. Core cash flow is calculated by CFRA using two methods: Cash Flow Method and Balance Sheet Method. The data is again from Reuters Fundamentals. The Cash Flow Method uses changes in Accounts Receivable, Inventory, Accounts Payable, and Accrued Liabilities as reported in the  Cash Flow Statement to adjust sales and operating expenses as reported in the income statement. The Balance Sheet Method calculates those changes using the items from the balance sheet. All other items are the same between the two methods.'
                    />
                    : Evaluate cash flow dynamics for nearly 4,000 North American companies. These scores provide a
                    comprehensive view, including collections from customers, payments to suppliers, and interest
                    payments to creditors.
                    <br />
                    <PopupVariant1
                        {...commonPopupProps}
                        linkText='Earnings Scores'
                        description='The Earnings Score is designed to differentiate companies based on their level of earnings quality, or their amount of accruals. Companies with high accruals, high levels of non-cash flow components in their earnings results are at greater risk of disappointing on their earnings results. Those companies with low accruals tend to understate their earnings and are at less risk of disappointing in their reported earnings results. The Earnings Score is generated by CFRA using a multi-step process with financial statement data from Reuters Fundamentals. First, the Raw Score is calculated for each company. In addition to the raw score, Return on Assets is also calculated.  A proprietary process is then incorporated to adjust the Raw Score based on Return on Assets.  Finally, the Adjusted Scores are ranked on a decile and percentile basis to produce the final score, with the lowest Adjusted Scores receiving the lowest rank and the highest Adjusted Scores receiving the highest rank. Percentile ranks of 1 are the most preferred and percentile ranks of 100 are the least preferred.'
                    />
                    : Assess the quality of earnings by measuring discretionary, non-cash components of earnings growth.
                    These scores help identify companies that may report disappointing results, restate earnings, or
                    face regulatory or legal action.
                </TypographyStyle20>
            }
        />
    );
}
