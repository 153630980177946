import { ReactComponent as CloseIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/CloseIcon_2.svg';
import { ReactComponent as SearchIcon2 } from '@cfra-nextgen-frontend/shared/src/assets/images/SearchIcon_2.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { Box, createTheme, IconButton, InputAdornment, Stack, ThemeProvider } from '@mui/material';
import TextField from '@mui/material/TextField';
import { themeVariant1 } from './theme';
import { SearchInputProps } from './types';

export function SearchInputVariant1({
    clearButtonCallback,
    onChange,
    showInput,
    textFieldRefCallback,
    wrapperRefCallback,
    onKeyDown,
    onClick,
    placeholder,
    hideClearButton,
    customTheme
}: SearchInputProps) {
    const theme = createTheme(themeVariant1, customTheme || {});
    return (
        <ThemeProvider theme={theme}>
            <Stack
                ref={wrapperRefCallback}
                direction='row'
                sx={{
                    display: showInput ? 'flex' : 'none',
                }}>
                <TextField
                    inputRef={textFieldRefCallback}
                    placeholder={placeholder || 'Search CFRA content'}
                    variant='standard'
                    autoComplete='off'
                    InputProps={{
                        endAdornment: (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}>
                                <IconButton sx={{ marginLeft: '8px', padding: '0' }}>
                                    <CustomSvgIcon
                                        component={SearchIcon2}
                                        viewBox='0 0 18 18'
                                        sx={{
                                            width: '18px',
                                            fill: '#007AB9',
                                        }}
                                    />
                                </IconButton>
                                <InputAdornment position='end'>
                                    {!hideClearButton && <IconButton sx={{ padding: 0 }} onClick={clearButtonCallback}>
                                        <CustomSvgIcon
                                            component={CloseIcon}
                                            viewBox='0 0 18 18'
                                            sx={{
                                                width: '18px',
                                                fill: '#AAAAAA',
                                                '&:hover': {
                                                    fill: '#007AB9',
                                                },
                                            }}
                                        />
                                    </IconButton>}
                                </InputAdornment>
                            </Box>
                        ),
                    }}
                    onClick={onClick}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
            </Stack>
        </ThemeProvider>
    );
}
